'use strict';

/**
 * @ngInject
 */
function DataContractViewController($scope, $http, $stateParams, DataContracts, $modal, $location) {
  $scope.contract = DataContracts.get({ id: $stateParams.id });
  $scope.obj = $scope.contract;

  $scope.fields = [
    {
      'name': 'FIELDS_IDENTIFICATION',
      'value': [
        {
          'value': 'contractId',
          'name': 'CONTRACT_ID',
          'type': 'text'
        },
        {
          'value': 'contractName',
          'name': 'NAME',
          'type': 'text'
        },
        {
          'value': 'description',
          'name': 'DESCRIPTION',
          'type': 'text'
        },
        {
          'value': 'operatorName',
          'name': 'OPERATOR',
          'type': 'text'
        },
        {
          'value': 'evseDataRequestName',
          'name': 'EVSE_DATA_REQUEST',
          'type': 'text'
        },
        {
          'value': 'administrativeStatus',
          'name': 'STATUS',
          'type': 'text'
        },
        {
          'value': 'validFrom',
          'name': 'VALIDITY_FROM',
          'type': 'datetime'
        },
        {
          'value': 'validUntil',
          'name': 'VALIDITY_UNTIL',
          'type': 'datetime'
        }
      ]
    },
    {
      'name': 'FIELDS_AUTHORIZED_SERVICES',
      'value': [
        {
          'value': 'endUserServiceDataNameList',
          'name': 'AUTHORIZED_SERVICES_DATA',
          'type': 'list'
        },
        {
          'value': 'endUserServiceDDDNameList',
          'name': 'AUTHORIZED_SERVICES_DDD',
          'type': 'list'
        },
        {
          'value': 'endUserServiceSDDNameList',
          'name': 'AUTHORIZED_SERVICES_SDD',
          'type': 'list'
        }
      ]
    },
    {
      'name': 'FIELDS_HISTORY',
      'value': [
        {
          'value': 'createdBy',
          'name': 'CREATED_BY',
          'type': 'text'
        },
        {
          'value': 'createdOn',
          'name': 'CREATED_ON',
          'type': 'datetime'
        },
        {
          'value': 'updatedBy',
          'name': 'UPDATED_BY',
          'type': 'text'
        },
        {
          'value': 'updatedOn',
          'name': 'UPDATED_ON',
          'type': 'datetime'
        }
      ]
    }
  ];

  $scope.deleteContract = function () {
    $scope.contract.$delete(
      function onDeleteSuccess() {
        $location.path('/admin/dataContracts');
      },
      function onDeleteError(error) {
        $modal.open({
          templateUrl: 'modalError.html',
          controller: 'ModalErrorInstanceController',
          resolve: {
            errorData: function () {
              return error;
            }
          }
        });
      }
    );
  };

  $scope.delete = function () {
    var modalInstance = $modal.open({
      templateUrl: 'modalConfirmDelete.html',
      controller: 'ModalConfirmInstanceController'
    });

    modalInstance.result.then(
      function onModalConfirm() { $scope.deleteContract(); },
      function onModalCancel() { }
    );
  };

}
DataContractViewController.$inject = ['$scope', '$http', '$stateParams', 'DataContracts', '$modal', '$location'];

module.exports = DataContractViewController;
