'use strict';

/**
 * @ngInject
 */
function ModalPartnerErrorsInstanceController($scope, $modalInstance, ApiConstants, $http, $modal, partnerEmi3Id, partnerGireveId, urlMethod) {
  $scope.isLoading = true;

  $scope.ok = function () {
    $modalInstance.close();
  };

  $scope.cancel = function () {
    $modalInstance.dismiss('cancel');
  };

  $http.post(ApiConstants.backendApiUrl + '/partnererrors', { 'emi3id': partnerEmi3Id, 'gireveid': partnerGireveId, 'method': urlMethod }).then(
    function onPostSuccess(success) {
      var data = success.data;
      console.log(data);
      $scope.errors = data.errorList;
    },
    function onPostError(error) {
      // called asynchronously ilf an error occurs
      // or server returns response with an error status.
      $modal.open({
        templateUrl: 'modalError.html',
        controller: 'ModalErrorInstanceController',
        resolve: {
          errorData: function () {
            return 'An error occurred. Please contact administrator.';
          }
        }
      });
    }
  ).finally(function onPostFinished() {
    $scope.isLoading = false;
  });
}
ModalPartnerErrorsInstanceController.$inject = ['$scope', '$modalInstance', 'ApiConstants', '$http', '$modal', 'partnerEmi3Id', 'partnerGireveId', 'urlMethod'];

module.exports = ModalPartnerErrorsInstanceController;
