'use strict';

/**
 * @ngInject
 */
function FilteringDduEvciController($scope, ApiConstants, $http, Identity, $modal) {

  $scope.searchData = {
    'order' : {
      'field' : 'evciType',
      'type' : 'asc'
    }
  };

  $scope.searchEvci = '';

  $scope.fields = [
    {
      'value': 'evciType',
      'name': 'EVCITYPE'
    },
    {
      'value': 'cpoId',
      'name': 'CPO'
    },
    {
      'value': 'evciId',
      'name': 'EVCI'
    },
    {
      'value': 'filterStartDate',
      'name': 'START_DATE'
    },
    {
      'value': 'filterEndDate',
      'name': 'END_DATE'
    },
    {
      'value': 'countFilteredCalls',
      'name': 'COUNTFILTEREDCALLS'
    }
  ];

  $scope.search = function() {
    $scope.searchEvci = $scope.searchEvci === null ? '' : $scope.searchEvci;

    $http.get(ApiConstants.backendApiUrl + '/filtering_ddu_evci?search=' + $scope.searchEvci)
      .then(
      function onGetSuccess(success) {
        $scope.filteredEvciList = success.data;
      },
      function onGetError(error) {
        console.log('Error: ' + error.status + ' : ' + error.data);
      }
    );
  };

  // Now load the data from server
  $scope.search();

  // Modal detail
  $scope.display = function(filteredEvci) {
    var modalInstance = $modal.open({
      templateUrl: 'modalFilteringDduEvciDetail.html',
      controller: 'ModalFilteringDduEvciDetailInstanceController',
      resolve: {
        filteredEvci: function () { return filteredEvci; }
      }
    });
  };

  // Delete
  $scope.deleteEntry = function(key) {
    $http.delete(ApiConstants.backendApiUrl + '/filtering_ddu_evci/' + key)
      .then(
        function onDeleteSuccess() {
          $scope.search();
        },
        function onDeleteError(error) {
          console.log('Error: ' + error.status + ' : ' + error.data);
        }
      );
  };

  var keyDelete;
  $scope.delete = function (key) {
    keyDelete = key;
    var modalInstance = $modal.open({
      templateUrl: 'modalConfirmDelete.html',
      controller: 'ModalConfirmInstanceController'
    });

    modalInstance.result.then(
      function onModalConfirm() { $scope.deleteEntry(keyDelete); },
      function onModalCancel() { }
    );
  };

  $scope.sort = function (field) {
    if (($scope.searchData.order.field === field) && ($scope.searchData.order.type === 'asc')) {
      $scope.searchData.order.type = 'desc';
    } else {
      $scope.searchData.order.field = field;
      $scope.searchData.order.type = 'asc';
    }

    $scope.search();
  };
}
FilteringDduEvciController.$inject = ['$scope', 'ApiConstants', '$http', 'Identity', '$modal'];

module.exports = FilteringDduEvciController;

