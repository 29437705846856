'use strict';

module.exports = angular
  .module('bo-web.data', [
    'bo-web.common',
    'ngResource'
  ])
  .constant('ApiConstants', require('../../config'))
  .factory('TransformJson', require('./utils/transformJson'))
  .factory('ApiAuthenticate', require('./services/api-authenticate'))
  .factory('Partners', require('./services/partners'))
  .factory('QueueMgt', require('./services/queue-mgt'))
  .factory('Operators', require('./services/operators'))
  .factory('Contracts', require('./services/contracts'))
  .factory('DataContracts', require('./services/datacontracts'))
  .factory('Events', require('./services/events'))
  .factory('Alarms', require('./services/alarms'))
  .factory('Records', require('./services/records'))
  .factory('Traces', require('./services/traces'))
  .factory('Extracts', require('./services/extracts'))
  .factory('ActivityAreas', require('./services/activity-area'))
  .factory('EndUserServices', require('./services/end-user-services'))
  .factory('EvseCaches', require('./services/evsecaches'))
  .factory('EvseDataRequests', require('./services/evsedatarequests'))
  .factory('UserCaches', require('./services/usercaches'))
  .factory('WhiteLists', require('./services/whitelists'))
  .factory('ChargingPoolCaches', require('./services/chargingpoolcaches'))
  .factory('FilteringDduEvcis', require('./services/filteringdduevcis'))
;
