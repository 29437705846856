'use strict';

/**
 * @ngInject
 */
function RecordListController($scope, $location, Records, Identity) {
  $scope.records = Records.query();

  $scope.token = Identity.getToken();

  $scope.fields = [
    {
      'value': 'Date',
      'name': 'DATE',
      'size': ''
    },
    {
      'value': 'Severity',
      'name': 'SEVERITY',
      'size': ''
    },
    {
      'value': 'FuncFlowId',
      'name': 'FUNCTIONAL_FLOW_ID',
      'size': ''
    },
    {
      'value': 'PartnerIDType',
      'name': 'PARTNER_ID_TYPE',
      'size': ''
    },
    {
      'value': 'PartnerID',
      'name': 'PARTNER_ID',
      'size': ''
    },
    {
      'value': 'OperatorIDType',
      'name': 'OPERATOR_ID_TYPE',
      'size': ''
    },
    {
      'value': 'OperatorID',
      'name': 'OPERATOR_ID',
      'size': ''
    },
    {
      'value': 'EndUserTokenID',
      'name': 'ENDUSER_TOKEN_ID',
      'size': ''
    },
    {
      'value': 'CPTID',
      'name': 'CPTID',
      'size': 'small'
    },
    {
      'value': 'TID',
      'name': 'TID',
      'size': 'medium'
    },
    {
      'value': 'Content',
      'name': 'CONTENT',
      'size': 'large'
    }
  ];

  $scope.isLarge = function(size) {
    return size === 'large';
  };

  $scope.prettyXml = function(content) {
    return vkbeautify.xml(content, 2);
  };

  $scope.golink = function(value){
    $location.path('/admin/diagnose').search({ 'cptId':value });
  };
}
RecordListController.$inject = ['$scope', '$location', 'Records', 'Identity'];

module.exports = RecordListController;
