'use strict';

/**
 * @ngInject
 */
function ModalConfirmAlreadyInContractInstanceController($scope, $modalInstance, contractIds) {
  $scope.contractIdsList = contractIds.join(', ');

  $scope.ok = function () {
    $modalInstance.close();
  };

  $scope.cancel = function () {
    $modalInstance.dismiss('cancel');
  };
}
ModalConfirmAlreadyInContractInstanceController.$inject = ['$scope', '$modalInstance', 'contractIds'];

module.exports = ModalConfirmAlreadyInContractInstanceController;
