'use strict';

/**
 * @ngInject
 */
function RecordsService($resource, ApiConstants, TransformJson) {
  return $resource(ApiConstants.authUrl + ApiConstants.endpointLogsUrl + '/record', {}, {
    query: {
      method: 'GET',
      transformResponse: function (data) { return TransformJson.transformResponse(data,'Record'); },
      isArray: true
    }
  });
}
RecordsService.$inject = ['$resource', 'ApiConstants', 'TransformJson'];

module.exports = RecordsService;
