'use strict';

/**
 * @ngInject
 */
function MainController($scope,$translate,Identity,$location,tmhDynamicLocale) {
  $scope.username = Identity.getUsername();
  $scope.changeLanguage = function (langKey) {
    $translate.use(langKey);
    tmhDynamicLocale.set(langKey);
  };

  $scope.getCurrentLanguage = function () {
    return (null === $translate.use()) ? $translate.preferredLanguage() : $translate.use();
  };

  $scope.isLanguageActive = function(langKey) {
    return $scope.getCurrentLanguage() === langKey;
  };

  $scope.logout = function () {
    Identity.destroy();
    $location.path('/login');
  };

  $scope.getCurrentYear = function () {
      return new Date().getFullYear();
  };
}
MainController.$inject = ['$scope', '$translate', 'Identity', '$location', 'tmhDynamicLocale'];

module.exports = MainController;
