'use strict';

/**
 * @ngInject
 */
function TransformJsonService($resource, ApiConstants) {

  function cleanNil(data){
    for(var elem in data){
      if(data[elem] !== null && typeof data[elem] === 'object'){
        if('@nil' in data[elem]){
          data[elem] = null;
        }
        cleanNil(data[elem]);
      }
    }
    return data;
  }

  function cleanDateNull(data){
    var suffix = 'Date';
    for(var elem in data){
      if(data[elem] === null && elem.indexOf(suffix, elem.length - suffix.length) !== -1){
        delete data[elem];
      }
    }
    return data;
  }

  function skipRootObjects(rootName, data){
    var forceArray = false;
    if(data !== null && typeof data === 'object' && (rootName + 's') in data){
      data = data[(rootName + 's')];
      forceArray = true;
    }
    if(data !== null && typeof data === 'object' && rootName in data){
      data = data[rootName];
    }
    if(forceArray && data !== null && !Array.isArray(data)){
      var dataArray = [];
      dataArray.push(data);
      data = dataArray;
    }
    return data;
  }

  function serializeArray(data){
    var copy = angular.copy(data);
    var suffixObjects = 'Objects';
    for(var elem in copy){
      if(copy[elem] !== null && elem.indexOf(suffixObjects, elem.length - suffixObjects.length) !== -1){
        copy[elem] = serializeSubobjects(copy[elem]);
      }else if(copy[elem] !== null && typeof copy[elem] === 'object' && !(copy[elem] instanceof Date) ){
          copy[elem] = copy[elem].toString();
      }
    }

    return copy;
  }

function objectToArray(obj){
  var arr = Object.keys(obj).map(function(k) { return obj[k]; });
  return arr;
}

function serializeSubobjects(data){
  var array = [];
  for(var elem in data){
    array.push(objectToArray(data[elem]).join('^'));
  }
  var serializedObject = array.join('|');

  return serializedObject;
}


/**
 * Construit un array javascript en :
 *    - parsant des string concaténés avec des virgules
 *    - évitant les objets intermédiaires
 *    - repositionnant des objets uniques dans un vrai tableau avec un seul élément
 **/
  function deserializeArray(data, separator){
    var listSuffix = 'List';
    var idListSuffix = 'IdList';

    for(var elem in data){
      if(data[elem] !== null && elem.indexOf(listSuffix, elem.length - listSuffix.length) !== -1){
        // on a trouvé un attribut terminant par "List"
        if(data[elem] !== null && typeof data[elem] === 'object' && !Array.isArray(data[elem])){
          // cas 1 : C'est un attibut de type objet, qui n'est pas un tableau
          // SUBELEMENTS
          var prefixPos = elem.indexOf(listSuffix, elem.length - listSuffix.length);
          var prefix = elem.substring(0,prefixPos);
          var skippedRoot = data[elem][prefix];
          if ( typeof skippedRoot !== 'undefined' && skippedRoot !== null ) {
            // cas 1a : on a trouvé un fils nommé comme le père,
            if(skippedRoot !== null && typeof skippedRoot === 'object' && !Array.isArray(skippedRoot)){
              // le fils est un objet, on le pousse dans un tableau
            var dataArr = [];
            dataArr.push(skippedRoot);
            data[elem] = dataArr;
            } else{
              // le fils est déjà un tableau, on le remonte directement d'un niveau
              data[elem] = skippedRoot;
            }
          } else {
            // cas 1b : pas de fils nommé comme le père, on cherche le premier fils de type objet
            var dataArray;
            for(var sub_elem in data[elem]){
              if (typeof data[elem][sub_elem] === 'object'){
                if (Array.isArray(data[elem][sub_elem])) {
                  // cas 1b-1 : le fils est un tableau, on le remonte directement d'un niveau
                  dataArray = data[elem][sub_elem];
                } else {
                  // cas 1b-2 : le fils est un objet, on le pousse dans un tableau
                  dataArray = [];
                  dataArray.push(data[elem][sub_elem]);
                }
                break;
              }
            }
            if (typeof dataArray !== 'undefined' && dataArray !== null) {
              data[elem] = dataArray;
            }
          }
        }else{
          // cas 2 : c'est une chaine de caractère à splitter
          if(typeof data[elem] !== 'string'){
              data[elem] = data[elem].toString();
          }

          var objList = separator ? data[elem].split(separator) : data[elem].split(',');

          // Si le nom de l'élément se termine par IdList on essaye de convertir tous les ids
          // en entiers pour que cela corresponde aux vrais IDs des objets.
          if (elem.indexOf(idListSuffix, elem.length - idListSuffix.length) !== -1) {
            objList = objList.map(function(elt) {
              return elt.match(/^\d+$/) ? parseInt(elt, 10) : elt;
            });
          }

          data[elem] = objList;
        }
      }
    }
    return data;
  }

  function hackBigInteger(data){
    return data.replace (/:(\d{13}\d*)([,\}])/g, ':"$1"$2');
  }

  return {
    transformRequest: function (data){
      return angular.toJson(serializeArray(cleanDateNull(data)));
    },
    transformResponse: function (data,rootName){
      return deserializeArray(cleanNil(skipRootObjects(rootName,angular.fromJson(hackBigInteger(data)))));
    },
    transformResponsePartnerView: function(data,rootName){
      return deserializeArray(cleanNil(skipRootObjects(rootName,angular.fromJson(hackBigInteger(data)))), '~#~');
    },
    transformResponsePartners: function (data){
      var json = angular.fromJson(hackBigInteger(data));
      var sondeEmi3 = json.iopSondePartnerEmi3Id;
      var sondeEmi3List = sondeEmi3.split(',');

      for(var i=0;i<json.partners.partner.length;i++){
        json.partners.partner[i].issonde = sondeEmi3List.indexOf(json.partners.partner[i].emi3id) !== -1;
      }

      //return deserializeArray(cleanNil(skipRootObjects('partner', json)));
      return {
        partners :deserializeArray(cleanNil(skipRootObjects('partner', json))),
        checkAndBillCount : json.checkAndBillCount,
        checkAndBillQueues :deserializeArray(cleanNil(skipRootObjects('checkAndBillQueues', json))),
        certificateExpirationMonthsTimeLimit : json.certificateExpirationMonthsTimeLimit
      };
    },
    transformResponsePartnersArray: function (data){
      var json = angular.fromJson(hackBigInteger(data));
      var sondeEmi3 = json.iopSondePartnerEmi3Id;
      var sondeEmi3List = sondeEmi3.split(',');
      for(var i=0;i<json.partners.partner.length;i++){
        json.partners.partner[i].issonde = sondeEmi3List.indexOf(json.partners.partner[i].emi3id) !== -1;
      }
      return deserializeArray(cleanNil(skipRootObjects('partner', json)));
    }
  };

}
TransformJsonService.$inject = ['$resource', 'ApiConstants'];

module.exports = TransformJsonService;
