'use strict';

/**
 * @ngInject
 */
function DataContractEditController($scope, $http, $stateParams, ApiConstants, DataContracts, EvseDataRequests, Operators, EndUserServices, $modal, $location, Identity, $timeout) {
  $scope.contract = DataContracts.get({ id: $stateParams.id });

  $scope.rebuilddatepicker = true;
  $scope.$on('$localeChangeSuccess', function () {
    $scope.rebuilddatepicker = false;
    $timeout(function () {
      $scope.rebuilddatepicker = true;
    }, 0);
  });

  $scope.operators = Operators.query();
  $scope.evseDataRequests = EvseDataRequests.query();
  $scope.endUserServicesData = EndUserServices.queryByServiceplatform({ id: 'Data' });
  $scope.endUserServicesDDD = EndUserServices.queryByServiceplatform({ id: 'DDD' });
  $scope.endUserServicesSDD = EndUserServices.queryByServiceplatform({ id: 'SDD' });

  $scope.fields = [{
    'name': 'FIELDS_IDENTIFICATION',
    'value': [
      {
        'value': 'contractId',
        'name': 'CONTRACT_ID',
        'type': 'text',
        'required': true
      },
      {
        'value': 'contractName',
        'name': 'NAME',
        'type': 'text'
      },
      {
        'value': 'description',
        'name': 'DESCRIPTION',
        'type': 'text'
      },
      {
        'value': 'operatorId',
        'name': 'ID_OPERATOR',
        'type': 'object_autocomplete',
        'required': true,
        'options': {
          'list': 'operators',
          'id': 'id',
          'label': 'name'
        }
      },
      {
        'value': 'evseDataRequestId',
        'name': 'ID_EVSE_DATA_REQUEST',
        'type': 'object_autocomplete',
        'required': true,
        'options': {
          'list': 'evseDataRequests',
          'id': 'gireveId',
          'label': 'name'
        }
      },
      {
        'value': 'administrativeStatus',
        'name': 'STATUS',
        'type': 'checkbox',
        'options': {
          'truevalue': 'ACTIVE',
          'falsevalue': 'INACTIVE',
          'defaultvalue': 'INACTIVE'
        }
      },
      {
        'value': 'validFrom',
        'name': 'VALIDITY_FROM',
        'type': 'datetime',
        'required': false,
        'options': {
          'placement': 'top'
        }
      },
      {
        'value': 'validUntil',
        'name': 'VALIDITY_UNTIL',
        'type': 'datetime',
        'required': false,
        'options': {
          'placement': 'top'
        }
      }
    ]
  },
  {
    'name': 'FIELDS_AUTHORIZED_SERVICES',
    'value': [
      {
        'value': 'endUserServiceDataIdList',
        'name': 'AUTHORIZED_SERVICES_DATA',
        'type': 'object_multi_autocomplete',
        'options': {
          'list': 'endUserServicesData',
          'id': 'serviceid',
          'label': 'name'
        }
      },
      {
        'value': 'endUserServiceDDDIdList',
        'name': 'AUTHORIZED_SERVICES_DDD',
        'type': 'object_multi_autocomplete',
        'options': {
          'list': 'endUserServicesDDD',
          'id': 'serviceid',
          'label': 'name'
        }
      },
      {
        'value': 'endUserServiceSDDIdList',
        'name': 'AUTHORIZED_SERVICES_SDD',
        'type': 'object_multi_autocomplete',
        'options': {
          'list': 'endUserServicesSDD',
          'id': 'serviceid',
          'label': 'name'
        }
      }
    ]
  }
  ];

  $scope.updateContract = function () {
    $scope.contract.updatedBy = Identity.getUsername();
    $scope.contract.$update(
      function onUpdateSuccess() {
        $location.path('/admin/dataContracts/' + $stateParams.id + '/view');
      },
      function onUpdateError(error) {
        $modal.open({
          templateUrl: 'modalError.html',
          controller: 'ModalErrorInstanceController',
          resolve: {
            errorData: function () {
              return error;
            }
          }
        });
      }
    );
  };

  $scope.update = function (size) {
    if (!$scope.iopForm.$valid) {
      $scope.submittedForm = true;
      return;
    }

    var modalInstance = $modal.open({
      templateUrl: 'modalConfirmUpdate.html',
      controller: 'ModalConfirmInstanceController',
      size: size
    });

    modalInstance.result.then(
      function onModalConfirm() {
        $scope.updateContract();  
      },
      function onModalCancel() { }
    );
  };
}
DataContractEditController.$inject = ['$scope', '$http', '$stateParams', 'ApiConstants', 'DataContracts', 'EvseDataRequests', 'Operators', 'EndUserServices', '$modal', '$location', 'Identity', '$timeout'];

module.exports = DataContractEditController;
