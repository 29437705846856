'use strict';

/**
 * @ngInject
 */
function EvseCacheListController($scope, $location, EvseCaches, $http, ApiConstants, $modal, Identity) {
  $scope.pageSizeList = [
    { value: 50 },
    { value: 100 },
    { value: 500 }
  ];

  $scope.searchData = {
    'page': 1,
    'page_size': {
      'value': 50
    },
    'order': {
      'field': 'cpoId',
      'type': 'asc'
    }
  };

  $scope.fields = [
    {
      'value': 'evseGireveId',
      'name': 'EVSEGIREVEID'
    },
    {
      'value': 'evseEmi3Id',
      'name': 'EVSEEMI3ID'
    },
    {
      'value': 'cpoIdType',
      'name': 'CPOIDTYPE'
    },
    {
      'value': 'cpoId',
      'name': 'CPOID'
    },
    {
      'value': 'evseGroups',
      'name': 'EVSE_GROUP_LIST'
    },
    {
      'value': 'updateDate',
      'name': 'UPDATE_DATE'
    }
  ];

  $scope.doSearch = function(query) {
    //When a request starts, isSomethingLoading is incremented
    $scope.isLoading++;

    $http.post(ApiConstants.backendApiUrl + '/evsecache', query).then(
      function onPostSuccess(success) {
        var data = success.data;

        //When a request is done, isSomethingLoading is decremented
        $scope.isLoading--;
        $scope.evsecaches = data.evses;
        $scope.currentPage = data.current_page;
        $scope.maxPage = data.max_page;

        // $scope.moreTraces = data.length > 0;
      },
      function onPostError(error) {
        // called asynchronously if an error occurs
        // or server returns response with an error status.
        $scope.isLoading--;
        $modal.open({
          templateUrl: 'modalError.html',
          controller: 'ModalErrorInstanceController',
          resolve: {
            errorData: function () {
              return 'An error occurs. Please contact administrator.';
            }
          }
        });
      }
    );
  };

  $scope.doSearch($scope.searchData);

  $scope.setPage = function (page) {
    if ((page >= 1) && (page <= $scope.maxPage)) {
      $scope.searchData.page = page;
      $scope.search();
    }
  };

  $scope.pageUp = function () {
    $scope.setPage($scope.currentPage + 1);
  };

  $scope.pageDown = function () {
    $scope.setPage($scope.currentPage - 1);
  };

  $scope.sort = function (field) {
    if (($scope.searchData.order.field === field) && ($scope.searchData.order.type === 'asc')) {
      $scope.searchData.order.type = 'desc';
    } else {
      $scope.searchData.order.field = field;
      $scope.searchData.order.type = 'asc';
    }

    $scope.search();
  };

  $scope.search = function () {
    $scope.doSearch($scope.searchData);
  };

  $scope.deleteEntry = function(id) {
    EvseCaches.delete({ id: id }, function onDeleteSuccess() {
      $scope.search();
    });
  };

  var idDelete;
  $scope.delete = function (id) {
    idDelete = id;
    var modalInstance = $modal.open({
      templateUrl: 'modalConfirmDelete.html',
      controller: 'ModalConfirmInstanceController'
    });

    modalInstance.result.then(
      function onModalConfirm() { $scope.deleteEntry(idDelete); },
      function onModalCancel() { }
    );
  };

  $scope.export = function(){
    var url = ApiConstants.backendApiUrl + '/evsecache/download';
    var firstParam = true;
    var sep;

    if (Identity.hasToken() && Identity.getUsername()) {
      sep = firstParam ? '?':'&';
      firstParam = false;
      url += sep + 'JSESSIONID=' + Identity.getToken();
    }

    if ((typeof $scope.searchData.cpoId !== 'undefined') && ($scope.searchData.cpoId !== null)) {
      sep = firstParam ? '?':'&';
      firstParam = false;
      url += sep + 'cpoId=' + $scope.searchData.cpoId;
    }

    if ((typeof $scope.searchData.evseId !== 'undefined') && ($scope.searchData.evseId !== null)) {
      sep = firstParam ? '?':'&';
      firstParam = false;
      url += sep + 'evseId=' + $scope.searchData.evseId;
    }

    window.open(url);
  };

  $scope.display = function(evseCache){
    var modalInstance = $modal.open({
      templateUrl: 'modalEvseCacheDetail.html',
      controller: 'ModalEvseCacheDetailInstanceController',
      resolve: {
        evseCache: function () { return evseCache; }
      }
    });
  };
}
EvseCacheListController.$inject = ['$scope', '$location', 'EvseCaches', '$http', 'ApiConstants', '$modal', 'Identity'];

module.exports = EvseCacheListController;
