'use strict';

/**
 * @ngInject
 */
function RoamingMatrixController($scope, $http, $modal, $timeout, ApiConstants, Identity) {
	$scope.doSearch = function () {
    $http.get(ApiConstants.backendApiUrl + '/roaming/matrix').then(
      function onGetSuccess(success) {
        var data = success.data;

      	$scope.emsps = data.emspsList;
      	$scope.cpos = data.cposList;

        var unbindWatch = $scope.$watch(isTableReady,
          function (newValue, oldValue) {
            //if (newValue === true) {
            // wrap in $timeout to give table a chance to finish rendering
            $timeout(function () {
              var topColumns = document.querySelector('#MatrixTableTop').querySelector('tr').querySelectorAll('th');
              var leftRows = document.querySelector('#MatrixTableLeft').querySelectorAll('tr');
              var leftColumns = document.querySelector('#MatrixTableLeft').querySelector('tr').querySelectorAll('td');
              var centerRows = document.querySelector('#MatrixTableCenter').querySelectorAll('tr');
              var centerColumns = document.querySelector('#MatrixTableCenter').querySelector('tr').querySelectorAll('td');

              console.log('Top: ' + topColumns.length + ' rows');
              console.log('Left: ' + leftColumns.length + ' rows');
              console.log('Center: ' + centerRows.length + ' rows');

              for (var indexColumn=0; indexColumn<topColumns.length; indexColumn++) {
                var widthTop = topColumns[indexColumn].clientWidth;
                var widthCenter = centerColumns[indexColumn].clientWidth;
                var maxWidth = Math.max(widthTop, widthCenter);

                angular.element(topColumns[indexColumn]).css('min-width', maxWidth+'px');
                angular.element(centerColumns[indexColumn]).css('min-width', maxWidth+'px');
              }

              /*for (var indexLeft=0; indexLeft<2; indexLeft++) {
                var widthTop2 = topColumns[indexLeft].clientWidth;
                var widthLeft2 = leftColumns[indexLeft].clientWidth;
                var maxWidth2 = Math.max(widthTop2, widthLeft2);

                angular.element(topColumns[indexLeft]).css('min-width', maxWidth2+'px');
                angular.element(leftColumns[indexLeft]).css('min-width', maxWidth2+'px');
              }*/

              for (var indexRow=0; indexRow<leftRows.length; indexRow++) {
                var heightLeft = leftRows[indexRow].clientHeight;
                var heightCenter = centerRows[indexRow].clientHeight;
                var maxHeight = Math.max(heightLeft, heightCenter);

                angular.element(leftRows[indexRow].querySelector('td')).css('height', (maxHeight)+'px');
                angular.element(centerRows[indexRow].querySelector('td')).css('height', (maxHeight)+'px');
              }

              angular.element(document.querySelector('div.table-matrix-center')).bind('scroll', function (e) {
                angular.element(document.querySelector('div.table-matrix-left'))[0].scrollTop = e.target.scrollTop;
                angular.element(document.querySelector('div.table-matrix-top'))[0].scrollLeft = e.target.scrollLeft;
              });
            });

            //we only need to watch once
            unbindWatch();
            //}
          }
        );
      },
      function onGetError(error) {
        // called asynchronously if an error occurs
        // or server returns response with an error status.
        $modal.open({
          templateUrl: 'modalError.html',
          controller: 'ModalErrorInstanceController',
          resolve: {
            errorData: function () {
              return 'An error occurs. Please contact administrator.';
            }
          }
        });
      }
    );
  };

  $scope.export = function(){
    var url = ApiConstants.backendApiUrl + '/roaming/matrix/download';

    if (Identity.hasToken() && Identity.getUsername()) {
      url += '?JSESSIONID=' + Identity.getToken();
    }

    window.open(url);
  };

  function isVisible(el) {
    var style = window.getComputedStyle(el);
    return (style.display !== 'none' && el.offsetWidth !== 0 );
  }

  function isTableReady() {
    return document.querySelector('#MatrixTableCenter').querySelector('a') !== null && isVisible(document.querySelector('#MatrixTableCenter').querySelector('a'));
  }

  $scope.doSearch();
}
RoamingMatrixController.$inject = ['$scope', '$http', '$modal', '$timeout', 'ApiConstants', 'Identity'];

module.exports = RoamingMatrixController;
