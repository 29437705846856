'use strict';

/**
 * @ngInject
 */
function WhiteListsService($resource, ApiConstants, TransformJson) {
  return $resource(ApiConstants.backendApiUrl + '/whitelist', {}, {
    query: {
      method: 'GET',
      transformResponse: function (data) {return data;},
      data: false,
      headers: { 'Content-Type': 'application/json' }
    },
    search: {
      method: 'POST'
    },
    delete: {
      url: ApiConstants.backendApiUrl + '/whitelist/:id',
      method: 'DELETE'
    }
  });
}
WhiteListsService.$inject = ['$resource', 'ApiConstants', 'TransformJson'];

module.exports = WhiteListsService;
