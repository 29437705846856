'use strict';

/**
 * @ngInject
 */
function ExportBookingController($scope, $location, $translate, Extracts, $http, ApiConstants, Identity) {
  $scope.bookingquery = {};
  $scope.bookingquery.startDate = new moment().subtract(1, 'months');
  $scope.bookingquery.startDate.startOf('day');
  $scope.bookingquery.onlyValidated = false;

  $scope.exportperiod = function(){
    var url = ApiConstants.backendApiUrl + '/extracts/bookingdownload';
    var firstParam = true;
    var sep;

    if (!$scope.bookingquery.startDate) {
      $translate('START_DATE_REQUIRED').then(function(translation) {
        window.alert(translation);
      });
      return;
    }

    if (Identity.hasToken() && Identity.getUsername()) {
      sep = firstParam ? '?':'&';
      firstParam = false;
      url += sep + 'JSESSIONID=' + Identity.getToken();
    }

    if ((typeof $scope.bookingquery.endDate !== 'undefined') && ($scope.bookingquery.endDate !== null)) {
      sep = firstParam ? '?':'&';
      firstParam = false;
      url += sep + 'endDate=' + $scope.bookingquery.endDate.toJSON();
    }

    if ((typeof $scope.bookingquery.startDate !== 'undefined') && ($scope.bookingquery.startDate !== null)) {
      sep = firstParam ? '?':'&';
      firstParam = false;
      url += sep + 'startDate=' + $scope.bookingquery.startDate.toJSON();
    }

    if (typeof $scope.bookingquery.onlyValidated !== 'undefined') {
      sep = firstParam ? '?':'&';
      firstParam = false;
      url += sep + 'onlyValidated=' + $scope.bookingquery.onlyValidated;
    }

    window.open(url);
  };
}
ExportBookingController.$inject = ['$scope', '$location', '$translate', 'Extracts', '$http', 'ApiConstants', 'Identity'];

module.exports = ExportBookingController;
