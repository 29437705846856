'use strict';

/**
 * @ngInject
 */
function ExportCdrController($scope, $location, Traces, Extracts, $http, ApiConstants, Identity) {
  $scope.cdrquery = {};

  $scope.cdrquery.startDate = new moment().subtract(1, 'months');
  $scope.cdrquery.startDate.startOf('day');

  $scope.extracts_scd = Extracts.listScd();
  $scope.selected_file = $scope.extracts_scd[0];

  $scope.exportperiod = function(){
    var url = ApiConstants.backendApiUrl + '/extracts/scddownload';
    var firstParam = true;
    var sep;

    if (Identity.hasToken() && Identity.getUsername()) {
      sep = firstParam ? '?':'&';
      firstParam = false;
      url += sep + 'JSESSIONID=' + Identity.getToken();
    }

    if ((typeof $scope.cdrquery.endDate !== 'undefined') && ($scope.cdrquery.endDate !== null)) {
      sep = firstParam ? '?':'&';
      firstParam = false;
      url += sep + 'endDate=' + $scope.cdrquery.endDate.toJSON();
    }

    if ((typeof $scope.cdrquery.startDate !== 'undefined') && ($scope.cdrquery.startDate !== null)) {
      sep = firstParam ? '?':'&';
      firstParam = false;
      url += sep + 'startDate=' + $scope.cdrquery.startDate.toJSON();
    }

    window.open(url);
  };

  $scope.export = function(){
    if ($scope.selected_file) {
      var url = ApiConstants.backendApiUrl + '/extracts/scddownload';
      var firstParam = true;
      var sep;

      if (Identity.hasToken() && Identity.getUsername()) {
        sep = firstParam ? '?':'&';
        firstParam = false;
        url += sep + 'JSESSIONID=' + Identity.getToken();
      }

      sep = firstParam ? '?':'&';
      firstParam = false;
      url += sep + 'filename=' + $scope.selected_file;

      window.open(url);
    }
  };
}
ExportCdrController.$inject = ['$scope', '$location', 'Traces', 'Extracts', '$http', 'ApiConstants', 'Identity'];

module.exports = ExportCdrController;
