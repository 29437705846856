'use strict';

/**
 * @ngInject
 */
function applyHighlight() {
  return {
    link: function(scope, element, attrs) {
      //element.querySelectorAll('.expandRow').on('click',
      //  function() {
      //    hljs.highlightBlock(element.querySelectorAll('.logsPrettyColorXml code')[0]);
      //  }
      //);
    }
  };
}

module.exports = applyHighlight;
