'use strict';

/**
 * @ngInject
 */
function EvseCachesService($resource, ApiConstants, TransformJson) {
  return $resource(ApiConstants.backendApiUrl + '/evsecache', {}, {
    query: {
      method: 'GET',
      transformResponse: function (data) { return data; },
      data: false,
      headers: { 'Content-Type': 'application/json' }
    },
    search: {
      method: 'POST'
    },
    delete: {
      url: ApiConstants.backendApiUrl + '/evsecache/:id',
      method: 'DELETE'
    }
  });
}
EvseCachesService.$inject = ['$resource', 'ApiConstants', 'TransformJson'];

module.exports = EvseCachesService;
