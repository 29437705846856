'use strict';

/**
 * @ngInject
 */
function RequestGeneratorController($scope, $http, $modal, ApiConstants, Identity) {
    $scope.services = [
        { 'id': 1, 'label': 'GetSmartChargingData', 'fields' : ['vin', 'fleetId', 'contractId']},
        { 'id': 2, 'label': 'RemoteBatteryStart', 'fields' : ['vin', 'fleetId', 'contractId']},
        { 'id': 3, 'label': 'GetSchedulerInformationByVIN', 'fields' : ['vin', 'contractId', 'fleetId', 'schedulerType']},
        { 'id': 4, 'label': 'SetSchedulerOnBoardStatus', 'fields' : ['vin', 'contractId', 'fleetId', 'activationFlag']},
        { 'id': 5, 'label': 'SendActivateOffboardScheduler', 'fields' : ['vin', 'fleetId', 'contractId']},
        {
            'id': 6,
            'label': 'ModifyOffBoardScheduler',
            'fields' : [
                'vin',
                'fleetId',
                'contractId',
                'optimized',
                'monStartTime',
                'monDuration',
                'tueStartTime',
                'tueDuration',
                'wedStartTime',
                'wedDuration',
                'thuStartTime',
                'thuDuration',
                'friStartTime',
                'friDuration',
                'satStartTime',
                'satDuration',
                'sunStartTime',
                'sunDuration'
            ]},
        { 'id': 7, 'label': 'GetSVTCommandStatus', 'fields' : ['vin', 'fleetId', 'contractId']},
        { 'id': 8, 'label': 'AddVehiclesForFleet', 'fields' : ['vin', 'fleetId', 'contractId']},
        { 'id': 9, 'label': 'DeleteVehiclesForFleet', 'fields' : ['vin', 'fleetId', 'contractId']},
        { 'id': 10, 'label': 'SVTRequestFunction', 'fields' : ['vin', 'fleetId', 'contractId', 'requestType', 'requestId']}
    ];

    $scope.service = $scope.services[0];
    $scope.isLoading = 0;

    // init values
    $scope.request = {
        'vin' : 'VF12R401E50253565',
        'contractId' : 'GIREVE',
        'fleetId': 'SCHEDULERMANAGERTEST',
        'schedulerType': 'ON_BOARD',
        'activationFlag': true,
        'optimized': true,
        'monStartTime': '0000',
        'monDuration': '0000',
        'tueStartTime': '0000',
        'tueDuration': '0000',
        'wedStartTime': '0000',
        'wedDuration': '0000',
        'thuStartTime': '0000',
        'thuDuration': '0000',
        'friStartTime': '0000',
        'friDuration': '0000',
        'satStartTime': '0000',
        'satDuration': '0000',
        'sunStartTime': '0000',
        'sunDuration': '0000',
        'requestType': 'ENABLE_SVT',
        'requestId': ''
    };

    // functions

    $scope.execute = function() {
        $scope.isLoading++;
        $scope.onError = false;

        var query = $scope.createQuery($scope.service);

        $http.post(ApiConstants.baseHref + ApiConstants.backendApiUrl + '/requestGenerator', query).then(
            function onGetSuccess(success) {
                var data = success.data;

                //When a request is done, isLoading is decremented
                $scope.isLoading--;

                $scope.result = data;
            },
            function onGetError(error) {
                //When a request is done, isLoading is decremented
                $scope.isLoading--;
                $scope.result = error.data.data;
                $scope.errorMessage = error.data.message;
                $scope.onError = true;
            }
        );
    };

    $scope.createQuery = function (service) {
        var query = { };
        query['method'] = service.label;
        query['requestValues'] = {};
        for (var i=0; i<service.fields.length; i++) {
            var field = service.fields[i];
            var value = $scope.request[field];
            if (value !== null) {
                query['requestValues'][field] = value;
            }
        }

        return query;
    };

    $scope.displayError = function (error) {
        // called asynchronously if an error occurs
        // or server returns response with an error status.
        $modal.open({
            templateUrl: 'modalError.html',
            controller: 'ModalErrorInstanceController',
            resolve: {
                errorData: function () {
                    return error;
                }
            }
        });
    };


    $scope.reinit = function() {
      $scope.onError = false;
      $scope.errorMessage='';
      delete $scope.result;
    };

}
RequestGeneratorController.$inject = ['$scope', '$http', '$modal', 'ApiConstants', 'Identity'];

module.exports = RequestGeneratorController;
