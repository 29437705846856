'use strict';

/**
 * @ngInject
 */
function ActivityAreaService($resource, ApiConstants, TransformJson) {
  return $resource( ApiConstants.backendApiUrl + '/activityarea/', { id: '@id' }, {
    query: {
      method: 'GET',
      transformResponse: function (data) { return JSON.parse(data); },
      isArray: true
    },
  });
}
ActivityAreaService.$inject = ['$resource', 'ApiConstants', 'TransformJson'];

module.exports = ActivityAreaService;
