'use strict';

module.exports = {
  endpointUrl: '',
  endpointLogsUrl: '',
  authUrl: '/services/adminV1',
  baseHref: '/admin/',
  backendApiUrl: 'api',
  logsDirectory: '/services/adminV1/logs/'
};
