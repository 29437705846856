'use strict';

/**
 * @ngInject
 */
function UserCacheListController($scope, $location, UserCaches, $http, ApiConstants, $modal, Identity) {
  $scope.pageSizeList = [
    { value: 50 },
    { value: 100 },
    { value: 500 }
  ];

  $scope.searchData = {
    'page': 1,
    'page_size': {
      'value': 50
    },
    'order': {
      'field': 'userId',
      'type': 'asc'
    }
  };

  $scope.fields = [
    {
      'value': 'userIdType',
      'name': 'USERIDTYPE'
    },
    {
      'value': 'userId',
      'name': 'USERID'
    },
    {
      'value': 'emspIdType',
      'name': 'EMSPIDTYPE'
    },
    {
      'value': 'emspId',
      'name': 'EMSPID'
    }
  ];

  $scope.doSearch = function (query) {
    //When a request starts, isSomethingLoading is incremented
    $scope.isLoading++;

    $http.post(ApiConstants.backendApiUrl + '/usercache', query).then(
      function onPostSuccess(success) {
        var data = success.data;

        //When a request is done, isSomethingLoading is decremented
        $scope.isLoading--;
        $scope.usercaches = data.users;
        $scope.currentPage = data.current_page;
        $scope.maxPage = data.max_page;

        // $scope.moreTraces = data.length > 0;
      },
      function onPostError(error) {
        // called asynchronously if an error occurs
        // or server returns response with an error status.
        $scope.isLoading--;
        $modal.open({
          templateUrl: 'modalError.html',
          controller: 'ModalErrorInstanceController',
          resolve: {
            errorData: function () {
              return 'An error occurs. Please contact administrator.';
            }
          }
        });
      }
    );
  };

  $scope.doSearch($scope.searchData);

  $scope.setPage = function (page) {
    if ((page >= 1) && (page <= $scope.maxPage)) {
      $scope.searchData.page = page;
      $scope.search();
    }
  };

  $scope.pageUp = function () {
    $scope.setPage($scope.currentPage + 1);
  };

  $scope.pageDown = function () {
    $scope.setPage($scope.currentPage - 1);
  };

  $scope.sort = function (field) {
    // field.value == searchData.order.field && searchData.order.type == 'asc'

    if (($scope.searchData.order.field === field) && ($scope.searchData.order.type === 'asc')) {
      $scope.searchData.order.type = 'desc';
    } else {
      $scope.searchData.order.field = field;
      $scope.searchData.order.type = 'asc';
    }

    $scope.search();
  };

  $scope.search = function () {
    $scope.doSearch($scope.searchData);
  };

  $scope.deleteEntry = function(id) {
    UserCaches.delete({ id: id }, function onDeleteSuccess() {
      $scope.search();
    });
  };

  var idDelete;
  $scope.delete = function (id) {
    idDelete = id;
    var modalInstance = $modal.open({
      templateUrl: 'modalConfirmDelete.html',
      controller: 'ModalConfirmInstanceController'
    });

    modalInstance.result.then(
      function onModalConfirm() { $scope.deleteEntry(idDelete); },
      function onModalCancel() { }
    );
  };

  $scope.export = function(){
    var url = ApiConstants.backendApiUrl + '/usercache/download';
    var firstParam = true;
    var sep;

    if (Identity.hasToken() && Identity.getUsername()) {
      sep = firstParam ? '?':'&';
      firstParam = false;
      url += sep + 'JSESSIONID=' + Identity.getToken();
    }

    if ((typeof $scope.searchData.emspId !== 'undefined') && ($scope.searchData.emspId !== null)) {
      sep = firstParam ? '?':'&';
      firstParam = false;
      url += sep + 'emspId=' + $scope.searchData.emspId;
    }

    if ((typeof $scope.searchData.userId !== 'undefined') && ($scope.searchData.userId !== null)) {
      sep = firstParam ? '?':'&';
      firstParam = false;
      url += sep + 'userId=' + $scope.searchData.userId;
    }

    window.open(url);
  };

  $scope.display = function(usercache){
    var modalInstance = $modal.open({
      templateUrl: 'modalUserCacheDetail.html',
      controller: 'ModalUserCacheDetailInstanceController',
      resolve: {
        userCache: function () { return usercache; }
      }
    });
  };
}
UserCacheListController.$inject = ['$scope', '$location', 'UserCaches', '$http', 'ApiConstants', '$modal', 'Identity'];

module.exports = UserCacheListController;
