'use strict';

/**
 * @ngInject
 */
function ModalConfirmActivatePushLocationsInstanceController($scope, $filter, $modalInstance, $http, ApiConstants, pushLocationsActivated, nature, partnerId, emspOperatorNameList, emspOperatorIdList, callbackUrl) {
  $scope.callbackUrl = callbackUrl;
  $scope.confirmButtonDisplay = true;
  $scope.partnerId = partnerId;
  $scope.nature = nature;
  $scope.pushLocationsActivated = pushLocationsActivated;
  $scope.emspOperatorNameList = emspOperatorNameList;
  $scope.emspOperatorIdList = emspOperatorIdList;
  $scope.operators = {};
  $scope.selectedOperator = [];
  if ($scope.emspOperatorIdList !== null){
    if ($scope.emspOperatorIdList.length > 1) {
    $scope.emspOperatorIdList.map(function (x, i) {
      $scope.operators[x] = $scope.emspOperatorNameList[i];
    });
  }else {
      if ($scope.emspOperatorIdList.length === 1) {
        $scope.selectedOperator[0] = $scope.emspOperatorIdList[0];
      }
    }
  }
    else {
      $scope.noAssociatedOperatorsMessage = 'No associated emsp operators';
      $scope.confirmButtonDisplay = false;
  }

  if ($scope.callbackUrl === ''){
    $scope.noRegistrationmessage = 'No Ocpi Registration';
    $scope.confirmButtonDisplay = false;
  }

  $scope.isLoading = false;
  $scope.processTerminated = false;
  $scope.onError = false;

  $scope.ok = function () {
    $scope.isLoading = false;
    $modalInstance.close();
  };

  $scope.cancel = function () {
    $scope.isLoading = false;
    $modalInstance.dismiss('cancel');
  };

  $scope.activate = function (pushLocationsActivated, selectedOperator) {
    $scope.isLoading = true;
    if (pushLocationsActivated === true) {
        if (typeof selectedOperator === 'undefined' || selectedOperator.length < 1) {
          $scope.noSelectMessage = 'No Selected Operator';
          $scope.confirmButtonDisplay = false;
          $scope.isLoading = false;
        } else {
          $scope.doActivate(selectedOperator);
        }
    } else {
      $scope.doDeactivate();
    }
  };

  $scope.doActivate = function (selectedOperator) {
    var url = ApiConstants.backendApiUrl + '/partner/' + $scope.partnerId + '/datadownloadflow/activate';
    var data = { 'operatorIdsArray': $scope.selectedOperator, 'callbackUrl': $scope.callbackUrl, 'nature': $scope.nature};
    $http.post(url, data).then(
      function onPostSuccess(success) {
        $scope.isLoading = false;
        $scope.processTerminated = true;
        $scope.message = 'Success';
      },
      function onPostError(error) {
        $scope.isLoading = false;
        $scope.processTerminated = true;
        $scope.errorMessage = error.data.message;
        $scope.onError = true;
      }
    );
  };

  $scope.doDeactivate = function () {
    var url = ApiConstants.backendApiUrl + '/partner/' + $scope.partnerId + '/datadownloadflow/deactivate';
    var data = { 'operatorIdsArray': $scope.emspOperatorIdList, 'callbackUrl': $scope.callbackUrl, 'nature': $scope.nature};
    $http.post(url, data).then(
      function onPostSuccess(success) {
        $scope.isLoading = false;
        $scope.processTerminated = true;
        $scope.message = 'Success';
      },
      function onPostError(error) {
        $scope.isLoading = false;
        $scope.processTerminated = true;
        $scope.errorMessage = error.data.message;
        $scope.onError = true;
      }
    );
  };
}
ModalConfirmActivatePushLocationsInstanceController.$inject = ['$scope', '$filter', '$modalInstance', '$http', 'ApiConstants', 'pushLocationsActivated', 'nature', 'partnerId', 'emspOperatorNameList', 'emspOperatorIdList', 'callbackUrl'];

module.exports = ModalConfirmActivatePushLocationsInstanceController;
