'use strict';

/**
 * @ngInject
 */
function PartnerViewController($scope, $http, $stateParams, ApiConstants, Partners, $modal, $location) {

    $scope.refreshPartner = function () {
        $scope.partner = Partners.newGet({id: $stateParams.id}, function () {
            if (typeof $scope.partner.ochpPassword !== 'undefined' && $scope.partner.ochpPassword) {
                $scope.partner.maskedochppassword = $scope.partner.ochpPassword.replace(/./g, '*');
            } else {
                $scope.partner.maskedochppassword = '';
            }
            $scope.obj = $scope.partner;
        });
    };

    $scope.refreshPartner();

    $scope.groupFields1 = [
        {
            'name': 'PARTNERS_URLS_EMIP',
            'value': [{
                'value': 'partnerurlEmipList',
                'name': 'method',
                'type': 'subelements',
                'subfields': [
                    {
                        'value': 'url',
                        'name': 'URL',
                        'type': 'text'
                    },
                    {
                        'value': 'timeout',
                        'name': 'TIMEOUT',
                        'type': 'text'
                    },
                    {
                        'value': 'description',
                        'name': 'DESCRIPTION',
                        'type': 'text'
                    }
                ]
            }]
        },
        {
            'name': 'PARTNERS_URLS_OCHP',
            'value': [
                {
                    'value': 'ochpUsername',
                    'name': 'OCHP_USERNAME',
                    'type': 'text'
                },
                {
                    'value': 'ochpPassword',
                    'name': 'OCHP_PASSWORD',
                    'type': 'text'
                },
                {
                    'value': 'partnerurlOchpList',
                    'name': 'method',
                    'type': 'subelements',
                    'subfields': [
                        {
                            'value': 'url',
                            'name': 'URL',
                            'type': 'text'
                        },
                        {
                            'value': 'timeout',
                            'name': 'TIMEOUT',
                            'type': 'text'
                        },
                        {
                            'value': 'description',
                            'name': 'DESCRIPTION',
                            'type': 'text'
                        },
                        {
                            'value': 'periodiccallperiod',
                            'name': 'PERIODIC_CALL_PERIOD',
                            'type': 'text',
                            'condition': function (element, field) {
                                return ['GetCDRs', 'GetRoamingAuthorisationList'].indexOf(element[field.name]) >= 0;
                            }
                        },
                        {
                            'value': 'lastsuccessfulresponsetimestamp',
                            'name': 'LAST_SUCCESSFUL_RESPONSE_TIMESTAMP',
                            'type': 'datetime',
                            'condition': function (element, field) {
                                return ['GetCDRs', 'GetRoamingAuthorisationList'].indexOf(element[field.name]) >= 0;
                            }
                        }
                    ]
                }]
        },
        {
            'name': 'PARTNERS_URLS_OICP',
            'value': [{
                'value': 'partnerurlOicpList',
                'name': 'method',
                'type': 'subelements',
                'subfields': [
                    {
                        'value': 'url',
                        'name': 'URL',
                        'type': 'text'
                    },
                    {
                        'value': 'timeout',
                        'name': 'TIMEOUT',
                        'type': 'text'
                    },
                    {
                        'value': 'description',
                        'name': 'DESCRIPTION',
                        'type': 'text'
                    },
                    {
                        'value': 'periodiccallperiod',
                        'name': 'PERIODIC_CALL_PERIOD',
                        'type': 'text',
                        'condition': function (element, field) {
                            return ['eRoamingGetChargeDetailRecords', 'eRoamingPullAuthenticationData'].indexOf(element[field.name]) >= 0;
                        }
                    },
                    {
                        'value': 'lastsuccessfulresponsetimestamp',
                        'name': 'LAST_SUCCESSFUL_RESPONSE_TIMESTAMP',
                        'type': 'datetime',
                        'condition': function (element, field) {
                            return ['eRoamingGetChargeDetailRecords', 'eRoamingPullAuthenticationData'].indexOf(element[field.name]) >= 0;
                        }
                    }
                ]
            }]
        }
    ];

    $scope.groupFields2 = [
        {
            'name': 'MUST_BE_MONITORED',
            'value': [
                {
                    'value': 'mustBeMonitored',
                    'name': 'MUST_BE_MONITORED_STATE',
                    'type': 'boolean',
                    'options': {
                        'truevalue': 'ACTIF_F',
                        'falsevalue': 'INACTIF_F',
                        'defaultvalue': 'INACTIF_F'
                    }
                },
                {
                    'value': 'clientHeartbeatPeriod',
                    'name': 'MONITORING_PERIOD',
                    'type': 'text'
                },
                {
                    'value': 'clientLastHeartbeat',
                    'name': 'LAST_HEARTBEAT_CLIENT',
                    'type': 'text'
                },
                {
                    'value': 'serverLastHeartbeat',
                    'name': 'LAST_SENT_REQUEST',
                    'type': 'text'
                }
            ]
        },
        {
            'name': 'FIELDS_HEARTBEAT',
            'value': [
                {
                    'value': 'serverMustBeConnected',
                    'name': 'MUST_BE_CONNECTED',
                    'type': 'boolean',
                    'options': {
                        'truevalue': 'ACTIF_M',
                        'falsevalue': 'INACTIF_M',
                        'defaultvalue': 'INACTIF_M'
                    }
                },
                {
                    'value': 'serverHeartbeatPeriod',
                    'name': 'HEARTBEAT_PERIOD',
                    'type': 'text'
                }
            ]
        },
        {
            'name': 'FIELDS_HISTORY',
            'value': [
                {
                    'value': 'createdBy',
                    'name': 'CREATED_BY',
                    'type': 'text'
                },
                {
                    'value': 'createdOn',
                    'name': 'CREATED_ON',
                    'type': 'datetime'
                },
                {
                    'value': 'updatedBy',
                    'name': 'UPDATED_BY',
                    'type': 'text'
                },
                {
                    'value': 'updatedOn',
                    'name': 'UPDATED_ON',
                    'type': 'datetime'
                }
            ]
        }
    ];

    $scope.deletePartner = function () {
        $scope.partner.$delete(
            function onDeleteSuccess() {
                $location.path('/admin/partners');
            },
            function onDeleteError(error) {
                $modal.open({
                    templateUrl: 'modalError.html',
                    controller: 'ModalErrorInstanceController',
                    resolve: {
                        errorData: function () {
                            return error;
                        },
                    }
                });
            }
        );
    };

    $scope.delete = function () {
        var modalInstance = $modal.open({
            templateUrl: 'modalConfirmDelete.html',
            controller: 'ModalConfirmInstanceController'
        });

        modalInstance.result.then(
            function onModalConfirm() {
                $scope.deletePartner();
            },
            function onModalCancel() {
            }
        );
    };

    $scope.registerOCPI = function (type) {
        console.log('doRegisterOCPI :');

        var ocpiRegistered;
        if (type === '2.2.1') {
            ocpiRegistered = $scope.partner.ocpi221PartnerConfiguration.ocpiRegistered;
        } else if (type === 'CPO') {
            ocpiRegistered = $scope.partner.cpoOcpiPartnerConfiguration.ocpiRegistered;
        } else {
            ocpiRegistered = $scope.partner.emspOcpiPartnerConfiguration.ocpiRegistered;
        }

        var modalInstance = $modal.open({
            templateUrl: 'modalConfirmRegisterOcpi.html',
            controller: 'ModalConfirmRegisterOcpiInstanceController',
            resolve: {
                ocpiRegistered: function () {
                    return ocpiRegistered;
                },
                partnerId: function () {
                    return $scope.partner.id;
                },
                interfaceType: function () {
                    return type;
                }
            }
        });

        modalInstance.result.then(
            function onModalConfirm() {
                $scope.refreshPartner();
            },
            function onModalCancel() {
                /*if (type === 'CPO') {
                  $scope.partner.cpoOcpiRegistered = !$scope.partner.cpoOcpiRegistered;
                } else {
                  $scope.partner.emspOcpiRegistered = !$scope.partner.emspOcpiRegistered;
                }*/
                $scope.refreshPartner();
            }
        );
    };

    $scope.hasEmspOcpiEndpoint = function () {
        var partnerurlOcpiEmspList = $scope.partner.partnerurlOcpiEmspList;
        return partnerurlOcpiEmspList.findIndex(function (value) {
            return value.method === 'locations';
        }) >= 0;
    };

    $scope.activatePushLocations = function (nature) {
        console.log('doActivatePushLocations');
        var staticPushLocationsActivated = $scope.partner.staticPushLocationsActivated;
        var dynamicPushLocationsActivated = $scope.partner.dynamicPushLocationsActivated;
        var modalInstance = $modal.open({
            templateUrl: 'modalConfirmPushLocationsActivation.html',
            controller: 'ModalConfirmActivatePushLocationsInstanceController',
            resolve: {
                pushLocationsActivated: function () {
                    if (nature === 'static') {
                        return staticPushLocationsActivated;
                    } else {
                        return dynamicPushLocationsActivated;
                    }
                },

                nature: function () {
                    return nature;
                },
                partnerId: function () {
                    return $scope.partner.id;
                },
                emspOperatorNameList: function () {
                    return $scope.partner.emspOperatorNameList;
                },
                emspOperatorIdList: function () {
                    return $scope.partner.emspOperatorIdList;
                },
                callbackUrl: function () {
                    var partnerurlOcpiEmspList = $scope.partner.partnerurlOcpiEmspList;
                    var locationUrlObj = partnerurlOcpiEmspList.filter(function (obj) {
                        return obj.method === 'locations';
                    })[0];
                    var locationUrl = '';
                    if (typeof locationUrlObj !== 'undefined') {
                        locationUrl = locationUrlObj.url;
                    }
                    return removeSlashAtTheEnd(locationUrl);
                }
            }
        });

        modalInstance.result.then(
            function onModalConfirm() {
                $scope.refreshPartner();
            },
            function onModalCancel() {
                if (nature === 'static') {
                    $scope.partner.staticPushLocationsActivated = !$scope.partner.staticPushLocationsActivated;
                } else {
                    $scope.partner.dynamicPushLocationsActivated = !$scope.partner.dynamicPushLocationsActivated;
                }
            }
        );
    };

    $scope.checkProtocol = function (protocol) {
        var partner = $scope.partner;
        return typeof (partner.preferredProtocolList) !== 'undefined' && partner.preferredProtocolList.indexOf(protocol) !== -1;
    };

    function removeSlashAtTheEnd(site) {
        // if the site has an end slash, then remove it and return the site url without the end slash
        return site.replace(/\/$/, '');
    }

    $scope.updateCredentials = function () {
        console.log('updateCredentials OCPI 2.1.1');
    };

    $scope.encodeBase64 = function (value) {
        return btoa(value);
    };
}
PartnerViewController.$inject = ['$scope', '$http', '$stateParams', 'ApiConstants', 'Partners', '$modal', '$location'];

module.exports = PartnerViewController;
