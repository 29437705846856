'use strict';

/**
 * @ngInject
 */
function DataContractCreateController($scope, $http, ApiConstants, DataContracts, EvseDataRequests, Operators, EndUserServices, $modal, $location, Identity, $timeout) {
  $scope.contract = new DataContracts();

  $scope.rebuilddatepicker = true;
  $scope.$on('$localeChangeSuccess', function () {
    $scope.rebuilddatepicker = false;
    $timeout(function () {
      $scope.rebuilddatepicker = true;
    }, 0);
  });

  $scope.operators = Operators.query();
  $scope.evseDataRequests = EvseDataRequests.query();
  $scope.endUserServicesData = EndUserServices.queryByServiceplatform({ id: 'Data' });
  $scope.endUserServicesDDD = EndUserServices.queryByServiceplatform({ id: 'DDD' });
  $scope.endUserServicesSDD = EndUserServices.queryByServiceplatform({ id: 'SDD' });

  $scope.fields = [{
    'name': 'FIELDS_IDENTIFICATION',
    'value': [
      {
        'value': 'contractId',
        'name': 'CONTRACT_ID',
        'type': 'text'
      },
      {
        'value': 'contractName',
        'name': 'NAME',
        'type': 'text'
      },
      {
        'value': 'contractDescription',
        'name': 'DESCRIPTION',
        'type': 'text'
      },
      {
        'value': 'operatorId',
        'name': 'ID_OPERATOR',
        'type': 'object_autocomplete',
        'required': true,
        'options': {
          'list': 'operators',
          'id': 'id',
          'label': 'name'
        }
      },
      {
        'value': 'evseDataRequestId',
        'name': 'ID_EVSE_DATA_REQUEST',
        'type': 'object_autocomplete',
        'required': true,
        'options': {
          'list': 'evseDataRequests',
          'id': 'gireveId',
          'label': 'name'
        }
      },
      {
        'value': 'administrativeStatus',
        'name': 'STATUS',
        'type': 'checkbox',
        'options': {
          'truevalue': 'ACTIVE',
          'falsevalue': 'INACTIVE',
          'defaultvalue': 'INACTIVE'
        }
      },
      {
        'value': 'validFrom',
        'name': 'VALIDITY_FROM',
        'type': 'datetime',
        'required': false,
        'options': {
          'placement': 'top'
        }
      },
      {
        'value': 'validUntil',
        'name': 'VALIDITY_UNTIL',
        'type': 'datetime',
        'required': false,
        'options': {
          'placement': 'top'
        }
      }
    ]
  },
  {
    'name': 'FIELDS_AUTHORIZED_SERVICES',
    'value': [
      {
        'value': 'endUserServiceDataIdList',
        'name': 'AUTHORIZED_SERVICES_DATA',
        'type': 'object_multi_autocomplete',
        'options': {
          'list': 'endUserServicesData',
          'id': 'serviceid',
          'label': 'name'
        }
      },
      {
        'value': 'endUserServiceDDDIdList',
        'name': 'AUTHORIZED_SERVICES_DDD',
        'type': 'object_multi_autocomplete',
        'options': {
          'list': 'endUserServicesDDD',
          'id': 'serviceid',
          'label': 'name'
        }
      },
      {
        'value': 'endUserServiceSDDIdList',
        'name': 'AUTHORIZED_SERVICES_SDD',
        'type': 'object_multi_autocomplete',
        'options': {
          'list': 'endUserServicesSDD',
          'id': 'serviceid',
          'label': 'name'
        }
      }
    ]
  }];



  $scope.contract.administrativeStatus = 'ACTIVE';
  $scope.obj = $scope.contract;

  $scope.createContract = function () {
    $scope.contract.createdBy = Identity.getUsername();
    $scope.contract.$create(
      function onCreateSuccess() {
        $location.path('/admin/dataContracts');
      },
      function onCreateError(error) {
        $modal.open({
          templateUrl: 'modalError.html',
          controller: 'ModalErrorInstanceController',
          resolve: {
            errorData: function () { return error; }
          }
        });
      }
    );
  };

  $scope.create = function (size) {
    if (!$scope.iopForm.$valid) {
      $scope.submittedForm = true;
      return;
    }

    var modalInstance = $modal.open({
      templateUrl: 'modalConfirmCreate.html',
      controller: 'ModalConfirmInstanceController',
      size: size
    });

    modalInstance.result.then(
      function onModalConfirm() {
        $scope.createContract();
      },
      function onModalCancel() { }
    );
  };
}
DataContractCreateController.$inject = ['$scope', '$http', 'ApiConstants', 'DataContracts', 'EvseDataRequests', 'Operators', 'EndUserServices', '$modal', '$location', 'Identity', '$timeout'];

module.exports = DataContractCreateController;
