'use strict';

/**
 * @ngInject
 */
function ModalConfirmForwardCdrInstanceController($scope, $modalInstance, inputData) {
    $scope.input = inputData;

    $scope.ok = function () {
        $modalInstance.close();
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };
}
ModalConfirmForwardCdrInstanceController.$inject = ['$scope', '$modalInstance', 'inputData'];

module.exports = ModalConfirmForwardCdrInstanceController;
