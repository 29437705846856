'use strict';

/**
 * @ngInject
 */
function ModalConfirmRegisterOcpiInstanceController($scope, $modalInstance, $http, ApiConstants, ocpiRegistered, partnerId, interfaceType, $location) {
    $scope.partnerId = partnerId;
    $scope.ocpiRegistered = ocpiRegistered;
    $scope.isLoading = false;
    $scope.interfaceType = interfaceType;
    $scope.processTerminated = false;
    $scope.onError = false;
    $scope.cptId = '';
    $scope.cptIdLink = '';

    $scope.ok = function () {
        $scope.isLoading = false;
        $modalInstance.close();
    };

    $scope.cancel = function () {
        $scope.isLoading = false;
        $modalInstance.dismiss('cancel');
    };

    $scope.register = function (ocpiRegistered) {
        $scope.isLoading = true;

        if (ocpiRegistered) {
            $scope.doRegistration();
        } else {
            $scope.doUnregistration();
        }

    };

    $scope.doRegistration = function () {
        var url = ApiConstants.backendApiUrl + '/partner/' + $scope.partnerId + '/ocpi/register';
        var data = {'interfaceType': $scope.interfaceType};
        $http.post(url, data).then(
            function onPostSuccess(success) {
                $scope.isLoading = false;
                $scope.processTerminated = true;
                $scope.message = 'Success';

                if (success.data.cptId !== null && success.data.cptId !== '') {
                    $scope.cptId = success.data.cptId;
                    $scope.cptIdLink = $location.protocol() + '://' + $location.host() + ':' + $location.port() + '/admin/admin/diagnose?cptId=' + $scope.cptId;
                }
            },
            function onPostError(error) {
                $scope.isLoading = false;
                $scope.processTerminated = true;
                $scope.errorMessage = error.data.message;

                if (error.data.cptId !== null && error.data.cptId !== '') {
                    $scope.cptId = error.data.cptId;
                    $scope.cptIdLink = $location.protocol() + '://' + $location.host() + ':' + $location.port() + '/admin/admin/diagnose?cptId=' + $scope.cptId;
                }

                $scope.onError = true;
            }
        );
    };

    $scope.doUnregistration = function () {
        var url = ApiConstants.backendApiUrl + '/partner/' + $scope.partnerId + '/ocpi/unregister';
        var data = {'interfaceType': $scope.interfaceType};
        $http.post(url, data).then(
            function onPostSuccess(success) {
                $scope.isLoading = false;
                $scope.processTerminated = true;
                $scope.message = 'Success';

                if (success.data.cptId !== null && success.data.cptId !== '') {
                    $scope.cptId = success.data.cptId;
                    $scope.cptIdLink = $location.protocol() + '://' + $location.host() + ':' + $location.port() + '/admin/admin/diagnose?cptId=' + $scope.cptId;
                }
            },
            function onPostError(error) {
                $scope.isLoading = false;
                $scope.processTerminated = true;
                $scope.errorMessage = error.data.message;

                if (error.data.cptId !== null && error.data.cptId !== '') {
                    $scope.cptId = error.data.cptId;
                    $scope.cptIdLink = $location.protocol() + '://' + $location.host() + ':' + $location.port() + '/admin/admin/diagnose?cptId=' + $scope.cptId;
                }

                $scope.onError = true;
            }
        );
    };
}
ModalConfirmRegisterOcpiInstanceController.$inject = ['$scope', '$modalInstance', '$http', 'ApiConstants', 'ocpiRegistered', 'partnerId', 'interfaceType', '$location'];

module.exports = ModalConfirmRegisterOcpiInstanceController;
