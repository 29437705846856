'use strict';

/**
 * @ngInject
 */
function EventListController($scope, $location, Events, Identity) {
  $scope.events = Events.query();

  $scope.token = Identity.getToken();

  $scope.fields = [
    {
      'value': 'EventId',
      'name': 'EVENT_ID'
    },
    {
      'value': 'Date',
      'name': 'DATE'
    },
    {
      'value': 'FuncFlowId',
      'name': 'FUNCTIONAL_FLOW_ID'
    },
    {
      'value': 'Severity',
      'name': 'SEVERITY'
    },
    {
      'value': 'TechFlowId',
      'name': 'TECHNICAL_FLOW_ID'
    },
    {
      'value': 'CPTID',
      'name': 'CPTID'
    },
    {
      'value': 'Description',
      'name': 'DESCRIPTION'
    }
  ];

  $scope.golink = function(value){
    $location.path('/admin/diagnose').search({ 'cptId': value });
  };
}
EventListController.$inject = ['$scope', '$location', 'Events', 'Identity'];

module.exports = EventListController;
