'use strict';

/**
 * @ngInject
 */
function PartnersService($resource, ApiConstants, TransformJson) {
    return $resource(ApiConstants.authUrl + ApiConstants.endpointUrl + '/partner/', {id: '@id'}, {
        getPartnerListObject: {
            url: ApiConstants.backendApiUrl + '/partnerlist',
            method: 'GET',
            transformResponse: function (data) {
                return TransformJson.transformResponsePartners(data);
            },
            isArray: false
        },
        getPartnerListArray: {
            url: ApiConstants.backendApiUrl + '/partnerlist',
            method: 'GET',
            transformResponse: function (data) {
                return TransformJson.transformResponsePartnersArray(data);
            },
            isArray: true
        },
        get: {
            url: ApiConstants.backendApiUrl + '/partner/:id',
            method: 'GET',
            transformResponse: function (data) {
                var tmp = TransformJson.transformResponsePartnerView(data, 'partner');
                tmp.versionTls = '' + tmp.versionTls;
                return tmp;
            },
            isArray: false
        },
        newGet:{
            url: ApiConstants.backendApiUrl + '/new/partner/:id',
            method: 'GET',
            transformResponse: function (data) {
                return JSON.parse(data);
            },
            isArray: false
        },
        getUrls: {
            url: ApiConstants.authUrl + ApiConstants.endpointUrl + '/partnerurls/:id',
            method: 'GET',
            transformResponse: function (data) {
                return TransformJson.transformResponse(data, 'partnerurls');
            },
            isArray: false
        },
        getPeePartners: {
            url: ApiConstants.backendApiUrl + '/peepartner',
            method: 'GET',
            isArray: true
        },
        update: {
            url: ApiConstants.backendApiUrl + '/partnerupdate/:id',
            method: 'PUT',
            transformRequest: function (data) {
                return JSON.stringify(data);
            }
        },
        create: {
            url: ApiConstants.backendApiUrl + '/partnercreate',
            method: 'POST',
            transformRequest: function (data) {
                return TransformJson.transformRequest(data);
            }
        },
        delete: {
            url: ApiConstants.backendApiUrl + '/partner/:id',
            method: 'DELETE',
            transformResponse: function (data) {
                return JSON.parse(data);
            },
            isArray: false
        }
    });
}
PartnersService.$inject = ['$resource', 'ApiConstants', 'TransformJson'];

module.exports = PartnersService;
