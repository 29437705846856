'use strict';

/**
 * @ngInject
 */
function AlarmListController($scope, $location, Alarms) {
    $scope.query = '';
    $scope.sortedAlarms = [];
    $scope.filteredAlarmsByDay = [];

    $scope.severityOptions = {
        model: null,
        availableOptions: [
            {id: 'MINOR', name: 'Miineures et supérieures'},
            {id: 'MAJOR', name: 'Majeures et supérieures'},
            {id: 'FATAL', name: 'Fatales'}
        ]
    };

    $scope.alarmquery = {};
    $scope.alarmquery.loading = false;
    $scope.alarmquery.endDate = new moment().utc();
    $scope.alarmquery.endDate.endOf('day');
    $scope.alarmquery.startDate = new moment().utc().subtract(6, 'days');
    $scope.alarmquery.startDate.startOf('day');
    $scope.alarmquery.alarmseverity = 'MAJOR';
    $scope.alarmquery.pagingLabel = {};
    $scope.alarmquery.pagingLabel.startDate =  $scope.alarmquery.startDate.format('DD/MM/YYYY');
    $scope.alarmquery.pagingLabel.endDate =  $scope.alarmquery.endDate.format('DD/MM/YYYY');

    $scope.alarmquery.loading = true;
    $scope.alarms = Alarms.collapse({ startDate: $scope.alarmquery.startDate.toJSON(), endDate: $scope.alarmquery.endDate.toJSON(), alarmSeverity: $scope.alarmquery.alarmseverity  }, function() {
        $scope.alarmquery.loading = false;
    });

    $scope.fields = [
        {
            'value': 'idalarm',
            'name': 'IDALARM'
        },
        {
            'value': 'date',
            'name': 'DATE'
        },
        {
            'value': 'alarmcode',
            'name': 'CODE'
        },
        {
            'value': 'alarmseverity',
            'name': 'SEVERITY'
        },
        {
            'value': 'alarmsource',
            'name': 'SOURCE'
        },
        {
            'value': 'tid',
            'name': 'TRANSACTIONID'
        },
        {
            'value': 'cptid',
            'name': 'CPTID'
        },
        {
            'value': 'alarmdescription',
            'name': 'DESCRIPTION'
        },
        {
            'value': 'nboccur',
            'name': 'NB_ALARM'
        }
    ];

    $scope.goLeft = function() {
        $scope.alarmquery.startDate = $scope.alarmquery.startDate.subtract(7, 'days');
        $scope.alarmquery.endDate = $scope.alarmquery.endDate.subtract(7, 'days');
        $scope.updateAlarms();
    };

    $scope.goRight = function() {
        $scope.alarmquery.startDate = $scope.alarmquery.startDate.add(7, 'days');
        $scope.alarmquery.endDate = $scope.alarmquery.endDate.add(7, 'days');
        $scope.updateAlarms();
    };

    $scope.updateAlarms = function() {
        $scope.alarmquery.loading = true;
        $scope.alarmquery.pagingLabel = {};
        $scope.alarmquery.pagingLabel.startDate =  $scope.alarmquery.startDate.format('DD/MM/YYYY');
        $scope.alarmquery.pagingLabel.endDate =  $scope.alarmquery.endDate.format('DD/MM/YYYY');

        var parameters = {};
        parameters.startDate = $scope.alarmquery.startDate.toJSON();
        parameters.endDate = $scope.alarmquery.endDate.toJSON();
        if ((typeof $scope.alarmquery.filter !== 'undefined') && ($scope.alarmquery.filter !== null)) {
            parameters.filter = $scope.alarmquery.filter;
        }
        parameters.alarmSeverity = $scope.alarmquery.alarmseverity;
        $scope.alarms = Alarms.collapse(parameters, function() {
            $scope.alarmquery.loading = false;
        });
    };

    $scope.expandAlarm = function(expand, alarmGroup, startDate, endDate) {
        if (expand) {
            if (alarmGroup.loaded) {
                alarmGroup.expand = expand;
            } else {
                var parameters = {};
                var startUtc = new moment(startDate).utc();
                var endUtc = new moment(endDate).utc();
                parameters.startDate = startUtc.toJSON();
                parameters.endDate = endUtc.toJSON();
                parameters.alarmCode = alarmGroup.alarmcode;
                parameters.alarmSource = alarmGroup.alarmsource;
                parameters.alarmSeverity = alarmGroup.alarmseverity;
                parameters.alarmDescription = alarmGroup.alarmdescription;
                Alarms.query(parameters, function(data) {
                    console.log(alarmGroup);
                    if (typeof data.collapsed_alarms !== 'undefined') {
                        alarmGroup.details = data.collapsed_alarms.slice(1);
                        alarmGroup.expand = expand;
                        alarmGroup.loaded = true;
                    }
                });
            }
        } else {
            alarmGroup.expand = expand;
        }
    };


    $scope.updateSortedAlarms = function() {
        // Sort alarms by date desc
        $scope.sortedAlarms = $scope.alarms.sort(function (a, b) {
            var dateA = new Date(a.date);
            var dateB = new Date(b.date);

            if (dateA > dateB ) {
                return -1;
            }

            if (dateB > dateA) {
                return 1;
            }

            return 0;
        });

        $scope.updateFilteredAlarmsByDay();
    };

    $scope.updateSortedAlarms2 = function() {
        // Sort alarms by date desc
        $scope.sortedAlarms = $scope.alarms.sort(function (a, b) {
            var dateA = new Date(a.date);
            var dateB = new Date(b.date);

            if (dateA > dateB ) {
                return -1;
            }

            if (dateB > dateA) {
                return 1;
            }

            return 0;
        });

        $scope.updateFilteredAlarmsByDay();
    };

    $scope.updateFilteredAlarmsByDay = function() {
        $scope.filteredAlarmsByDay = [];

        var previousDate = null;

        for (var i = 0; i < $scope.sortedAlarms.length; i++) {
            var alarm = $scope.sortedAlarms[i];

            // Check if alarm matches the current query
            if ($scope.query) {
                var query = $scope.query.toLowerCase();

                var matchId = ('' + alarm.idalarm).toLowerCase().indexOf(query) !== -1;
                var matchCode = ('' + alarm.alarmcode).toLowerCase().indexOf(query) !== -1;
                var matchSeverity = ('' + alarm.alarmseverity).toLowerCase().indexOf(query) !== -1;
                var matchSource = ('' + alarm.alarmsource).toLowerCase().indexOf(query) !== -1;
                var matchDescription = ('' + alarm.alarmdescription).toLowerCase().indexOf(query) !== -1;

                if (!(matchId || matchCode || matchSeverity || matchSource || matchDescription)) {
                    continue;
                }
            }

            // Parse date
            var date = new Date(alarm.date);

            // Format date
            var year = date.getFullYear();
            var month = '' + (date.getMonth() + 1);
            month = '00'.substring(0, 2 - month.length) + month;
            var day = '' + date.getDate();
            day = '00'.substring(0, 2 - day.length) + day;

            var currentDate = day + '/' + month + '/' + year;

            // Check if there is already data for this date
            if (previousDate !== currentDate) {
                $scope.filteredAlarmsByDay.push({
                    date: currentDate,
                    groups: []
                });

                previousDate = currentDate;
            }

            // Check if we already have the same id alarm for that date
            var currentDateAlarmGroups = $scope.filteredAlarmsByDay[$scope.filteredAlarmsByDay.length - 1].groups;
            var alarmGroup = null;
            for (var j = 0; j < currentDateAlarmGroups.length; j++) {
                if (currentDateAlarmGroups[j].idalarm === alarm.idalarm) {
                    alarmGroup = currentDateAlarmGroups[j];
                }
            }

            // If no alarm group found, create it
            if (!alarmGroup) {
                alarmGroup = {
                    idalarm: alarm.idalarm,
                    alarms: []
                };

                currentDateAlarmGroups.push(alarmGroup);
            }

            // Add the alarm to the alarm group
            alarmGroup.alarms.push(alarm);
        }
    };
}
AlarmListController.$inject = ['$scope', '$location', 'Alarms'];

module.exports = AlarmListController;
