'use strict';

/**
 * @ngInject
 */
function TracesService($resource, ApiConstants) {
  return $resource(ApiConstants.backendApiUrl + '/traces', {}, {
    search: {
      method: 'POST',
      isArray: true
    }
  });
}
TracesService.$inject = ['$resource', 'ApiConstants'];

module.exports = TracesService;
