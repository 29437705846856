'use strict';

/**
 * @ngInject
 */
function PartnerListController($scope, Partners, $modal, QueueMgt) {

  $scope.refresh = function() {
    $scope.partnerListResponse = Partners.getPartnerListObject();
    $scope.peePartners = Partners.getPeePartners();
  };

  $scope.refresh();

  $scope.refreshDataTimer = null;
  $scope.refreshDataEnabled = false;
  $scope.refreshDataInterval = 300;
  $scope.formattedRefreshDataInterval = '5mn00';

  $scope.fields = [
    {
      'value': 'gireveid',
      'name': 'GIREVE_ID'
    },
    {
      'value': 'emi3id',
      'name': 'EMI3ID'
    },
    {
      'value': 'name',
      'name': 'NAME'
    },
    {
      'value': 'protocol',
      'name': 'PROTOCOL'
    },
    {
      'value': 'cdrEnqueued',
      'name': 'QUEUE'
    },
    {
      'value': 'certificateExpiringDate',
      'name': 'EXPIRING_CERTIFICATE'
    },
    {
      'value': 'state',
      'name': 'STATE'
    }
  ];

  $scope.skipNextCdr = function (queueName) {
    var modalInstance = $modal.open({
      templateUrl: 'modalConfirmSkipCdr.html',
      controller: 'ModalConfirmInstanceController'
    });
    modalInstance.result.then(
      function onModalConfirm() {
        QueueMgt.skipNextMessage({
          queueName: queueName,
          totalMessageToSkip: 1
          },
          function onSuccess() {
            $scope.refresh();
          },
          function onError(error) {
            $modal.open({
              templateUrl: 'modalError.html',
              controller: 'ModalErrorInstanceController',
              resolve: {
                errorData: function () {
                  return error;
                }
              }
            });
          });
      },
      function onModalCancel() { }
    );
  };

  $scope.isPeePartner = function (partner) {
    var found = false;

    if ($scope.peePartners) {
      angular.forEach($scope.peePartners, function (peePartner) {
        if (peePartner.id === partner.id) {
          found = true;
        }
      });
    }

    return found;
  };

  $scope.certificateStatus = function(partner) {
    var certificateStatus = 'certificate_unknown';
    var partnerCertificateMonthsTimeLimit = $scope.partnerListResponse.certificateExpirationMonthsTimeLimit;
    var currentDate = new Date();
    var limitDate = addMonths(currentDate, partnerCertificateMonthsTimeLimit);
    var partnerExpirationDate = partner.certificateExpiringDate;
    if(partnerExpirationDate){
      if (partnerExpirationDate < limitDate){
        certificateStatus = 'certificate_ko';
      }
      else{
        certificateStatus = 'certificate_ok';
      }
    }
    return certificateStatus;
  };

  $scope.updateRefreshTimer = function() {
    // Cancel any existing timer
    if ($scope.refreshDataTimer) {
      clearInterval($scope.refreshDataTimer);
      $scope.refreshDataTimer = null;
    }

    // If enabled, create a new timer
    if ($scope.refreshDataEnabled) {
      $scope.refreshDataTimer = setInterval(function() {
        $scope.refresh();
      }, $scope.refreshDataInterval * 1000);
    }

    // Update formatted refresh interval
    var minutes = '' + Math.floor($scope.refreshDataInterval / 60);
    var seconds = '' + $scope.refreshDataInterval % 60;

    $scope.formattedRefreshDataInterval = '';

    if (minutes !== '0') {
      $scope.formattedRefreshDataInterval += minutes + 'mn';
      seconds = '00'.substring(0, 2 - seconds.length) + seconds;
    }

    $scope.formattedRefreshDataInterval += seconds + 's';
  };

  $scope.$on('$create', function() {
    $scope.updateRefreshTimer();
  });

  $scope.$on('$destroy', function() {
    if ($scope.refreshDataTimer) {
      clearTimeout($scope.refreshDataTimer);
      $scope.refreshDataTimer = null;
    }
  });

  function addMonths (date, count) {
    if (date && count) {
      var m, d = (date = new Date(+date)).getDate();

      date.setMonth(date.getMonth() + count);
      m = date.getMonth();
      date.setDate(d);
      if (date.getMonth() !== m)
      {
        date.setDate(0);
      }
    }
    return date;
  }
}
PartnerListController.$inject = ['$scope', 'Partners', '$modal', 'QueueMgt'];

module.exports = PartnerListController;
