'use strict';

/**
 * @ngInject
 */
function PartnerMonitorController($scope, $http, $state, $stateParams, Partners, ApiConstants, $modal, $location, Identity, $timeout) {
  $scope.partnermonitor = Partners.get({ id: $stateParams.id }, function() {
    var partnerurlEmipListField = $scope.searchField($scope.fields, 'PARTNERS_URLS_EMIP', 'partnerurlEmipList');
    $scope.partnermonitor.partnerurlEmipList = $scope.subelementsConstraintList($scope.partnermonitor.partnerurlEmipList, partnerurlEmipListField);
    var partnerurlOchpListField = $scope.searchField($scope.fields, 'PARTNERS_URLS_OCHP', 'partnerurlOchpList');
    $scope.partnermonitor.partnerurlOchpList = $scope.subelementsConstraintList($scope.partnermonitor.partnerurlOchpList, partnerurlOchpListField);
    var partnerurlOicpListField = $scope.searchField($scope.fields, 'PARTNERS_URLS_OICP', 'partnerurlOicpList');
    $scope.partnermonitor.partnerurlOicpList = $scope.subelementsConstraintList($scope.partnermonitor.partnerurlOicpList, partnerurlOicpListField);
    var partnerurlOcpiCpoListField = $scope.searchField($scope.fields, 'PARTNERS_URLS_OCPI_CPO', 'partnerurlOcpiCpoList');
    $scope.partnermonitor.partnerurlOcpiCpoList = $scope.subelementsConstraintList($scope.partner.partnerurlOcpiCpoList, partnerurlOcpiCpoListField);
    var partnerurlOcpiEmspListField = $scope.searchField($scope.fields, 'PARTNERS_URLS_OCPI_EMSP', 'partnerurlOcpiEmspList');
    $scope.partnermonitor.partnerurlOcpiEmspList = $scope.subelementsConstraintList($scope.partner.partnerurlOcpiEmspList, partnerurlOcpiEmspListField);

    if ($scope.partnermonitor.preferredProtocolList === null) {
      $scope.partnermonitor.preferredProtocolList = [];
    }

    $scope.obj = $scope.partnermonitor;
  });

  $scope.rebuilddatepicker=true;
  $scope.$on('$localeChangeSuccess', function () {
    $scope.rebuilddatepicker = false;
    $timeout(function(){
      $scope.rebuilddatepicker = true;
    },0);
  });

  $scope.protocolList = [
    { name: 'eMIP' },
    { name: 'OCHP' },
    { name: 'OICP' }
  ];

  $scope.fields = [
    {
      'name': 'FIELDS_GENERAL',
      'value': [
        {
          'value': 'gireveid',
          'name': 'GIREVE_ID',
          'type': 'text',
          'required': true,
          'readonly': true
        },
        {
          'value': 'emi3id',
          'name': 'EMI3ID',
          'type': 'text',
          'readonly': true
        },
        {
          'value': 'name',
          'name': 'NAME',
          'type': 'text',
          'readonly': true
        }
      ]
    },
    {
      'name': 'PARTNERS_URLS_EMIP',
      'value': [{
        'value': 'partnerurlEmipList',
        'name': 'method',
        'type': 'subelements_monitoring',
        'constraint_function': 'subelementsConstraintFilter',
        'constraint_list': ['Heartbeat', 'SearchEVSE', 'SetServiceAuthorisation', 'GetServiceAuthorisation', 'SetChargeDetailRecord', 'SetExtendedChargeDetailRecord', 'GetEVChargingNeedFromeMSP', 'SetSessionActionRequest', 'SetSessionEventReport', 'GetBookingOnPools', 'SetBookingAccept', 'SetBookingAbort', 'SetBookingCancellation', 'SetBookingEventReport', 'SetBookingActionRequest', 'SetBookingChargingPoolOrEVSE'],
        'constraint_value': 'method',
        'constraint_objects_required': true,
        'subfields': [
          {
            'value': 'url',
            'name': 'URL',
            'type': 'text',
            'readonly': true
          },
          {
            'value': 'protocol',
            'name': 'PROTOCOL',
            'type': 'hidden',
            'default_value': 'eMIP'
          },
          {
            'value': 'method',
            'name': 'METHOD',
            'type': 'hidden',
            'required': true
          },
          {
            'value': 'timeout',
            'name': 'TIMEOUT',
            'type': 'integer',
            'readonly': true
          },
          {
            'value': 'description',
            'name': 'DESCRIPTION',
            'type': 'text',
            'readonly': true
          },
          {
            'value': 'periodiccallperiod',
            'name': 'PERIODIC_CALL_PERIOD',
            'type': 'hidden'
          },
          {
            'value': 'lastsuccessfulresponsetimestamp',
            'name': 'LAST_SUCCESSFUL_RESPONSE_TIMESTAMP',
            'type': 'hidden'
          },
          {
            'value': 'lasterrordate',
            'name': 'LAST_ERROR_DATE',
            'type': 'datetime',
            'options': {
              'placement': 'top'
            },
            'readonly': true
          },
          {
            'value': 'lasterrortid',
            'name': 'LAST_ERROR_TID',
            'type': 'url',
            'readonly': true,
            'target': '_blank',
            'callback': function (value) {
              return $state.href('admin.diagnose', { 'transactionId': value });
            }
          },
          {
            'value': 'ignoreuntil',
            'name': 'IGNORE_UNTIL',
            'type': 'datetime',
            'options': {
              'placement': 'top'
            }
          },
          {
            'value': 'comments',
            'name': 'COMMENTS',
            'type': 'textarea'
          }
        ]
      }]
    },
    {
      'name': 'PARTNERS_URLS_OCHP',
      'value': [{
        'value': 'partnerurlOchpList',
        'name': 'method',
        'type': 'subelements_monitoring',
        'constraint_list': ['GetStatus', 'GetCDRs', 'GetRoamingAuthorisationList', /*'ConfirmCDRs'*/],
        'constraint_function': 'subelementsConstraintFilter',
        'constraint_value': 'method',
        'constraint_objects_required': true,
        'subfields': [
          {
            'value': 'url',
            'name': 'URL',
            'type': 'text',
            'readonly': true
          },
          {
            'value': 'protocol',
            'name': 'PROTOCOL',
            'type': 'hidden',
            'default_value': 'eMIP'
          },
          {
            'value': 'method',
            'name': 'METHOD',
            'type': 'hidden',
            'required': true
          },
          {
            'value': 'timeout',
            'name': 'TIMEOUT',
            'type': 'integer',
            'readonly': true
          },
          {
            'value': 'description',
            'name': 'DESCRIPTION',
            'type': 'text',
            'readonly': true
          },
          {
            'value': 'periodiccallperiod',
            'name': 'PERIODIC_CALL_PERIOD',
            'type': 'hidden'
          },
          {
            'value': 'lastsuccessfulresponsetimestamp',
            'name': 'LAST_SUCCESSFUL_RESPONSE_TIMESTAMP',
            'type': 'hidden'
          },
          {
            'value': 'lasterrordate',
            'name': 'LAST_ERROR_DATE',
            'type': 'datetime',
            'options': {
              'placement': 'top'
            },
            'readonly': true
          },
          {
            'value': 'lasterrortid',
            'name': 'LAST_ERROR_TID',
            'type': 'url',
            'readonly': true,
            'target': '_blank',
            'callback': function (value) {
              return $state.href('admin.diagnose', { 'transactionId': value });
            }
          },
          {
            'value': 'ignoreuntil',
            'name': 'IGNORE_UNTIL',
            'type': 'datetime',
            'options': {
              'placement': 'top'
            }
          },
          {
            'value': 'comments',
            'name': 'COMMENTS',
            'type': 'textarea'
          }
        ]
      }]
    },
    {
      'name': 'PARTNERS_URLS_OICP',
      'value': [{
        'value': 'partnerurlOicpList',
        'name': 'method',
        'type': 'subelements_monitoring',
        'constraint_list': ['eRoamingPullEvseStatus', 'eRoamingGetChargeDetailRecords', 'eRoamingPullAuthenticationData', 'eRoamingAuthorizeStart', 'eRoamingAuthorizeRemoteStart', 'eRoamingAuthorizeRemoteStop'],
        'constraint_value': 'method',
        'subfields': [
          {
            'value': 'url',
            'name': 'URL',
            'type': 'text',
            'readonly': true
          },
          {
            'value': 'protocol',
            'name': 'PROTOCOL',
            'type': 'hidden',
            'default_value': 'eMIP'
          },
          {
            'value': 'method',
            'name': 'METHOD',
            'type': 'hidden',
            'required': true
          },
          {
            'value': 'timeout',
            'name': 'TIMEOUT',
            'type': 'integer',
            'readonly': true
          },
          {
            'value': 'description',
            'name': 'DESCRIPTION',
            'type': 'text',
            'readonly': true
          },
          {
            'value': 'periodiccallperiod',
            'name': 'PERIODIC_CALL_PERIOD',
            'type': 'hidden'
          },
          {
            'value': 'lastsuccessfulresponsetimestamp',
            'name': 'LAST_SUCCESSFUL_RESPONSE_TIMESTAMP',
            'type': 'hidden'
          },
          {
            'value': 'lasterrordate',
            'name': 'LAST_ERROR_DATE',
            'type': 'datetime',
            'options': {
              'placement': 'top'
            },
            'readonly': true
          },
          {
            'value': 'lasterrortid',
            'name': 'LAST_ERROR_TID',
            'type': 'url',
            'readonly': true,
            'target': '_blank',
            'callback': function (value) {
              return $state.href('admin.diagnose', { 'transactionId': value });
            }
          },
          {
            'value': 'ignoreuntil',
            'name': 'IGNORE_UNTIL',
            'type': 'datetime',
            'options': {
              'placement': 'top'
            }
          },
          {
            'value': 'comments',
            'name': 'COMMENTS',
            'type': 'textarea'
          }
        ]
      }]
    }
  ];

  $scope.updatePartner = function() {
    $scope.partnermonitor.updatedby = Identity.getUsername();
    $scope.partnermonitor.partnerurlObjects = [];
    $scope.partnermonitor.partnerurlObjects = $scope.partnermonitor.partnerurlObjects
      .concat($scope.partnermonitor.partnerurlEmipList)
      .concat($scope.partnermonitor.partnerurlOchpList)
      .concat($scope.partnermonitor.partnerurlOicpList)
      .concat($scope.partnermonitor.partnerurlOcpiCpoList)
      .concat($scope.partnermonitor.partnerurlOcpiEmspList);

    // Huh ?!
    for (var i = 0; i < $scope.partnermonitor.partnerurlObjects.length; i++) {
      if ($scope.partnermonitor.partnerurlObjects[i].ignoreuntil && typeof $scope.partnermonitor.partnerurlObjects[i].ignoreuntil.toJSON === 'function') {
        $scope.partnermonitor.partnerurlObjects[i].ignoreuntil = $scope.partnermonitor.partnerurlObjects[i].ignoreuntil.toJSON();
      }
    }

    delete $scope.partnermonitor['partnerurlEmipList'];
    delete $scope.partnermonitor['partnerurlOchpList'];
    delete $scope.partnermonitor['partnerurlOicpList'];
    delete $scope.partnermonitor['partnerurlOcpiCpoList'];
    delete $scope.partnermonitor['partnerurlOcpiEmspList'];

    $scope.partnermonitor.$update(
      function onUpdateSuccess() {
        $location.path('admin/partnermonitors');
      },
      function onUpdateError(error) {
        $modal.open({
          templateUrl: 'modalError.html',
          controller: 'ModalErrorInstanceController',
          resolve: {
            errorData: function () {
              return error;
            },
          }
        });
      }
    );
  };

  $scope.update = function (size) {
    if(!$scope.iopForm.$valid){
      $scope.submittedForm = true;
      return;
    }

    var modalInstance = $modal.open({
      templateUrl: 'modalConfirmUpdate.html',
      controller: 'ModalConfirmInstanceController',
      size: size
    });

    modalInstance.result.then(
      function onModalConfirm() { $scope.updatePartner(); },
      function onModalCancel() { }
    );
  };

  $scope.showLastErrors = function (emi3id, gireveId, method, size) {
    var modalInstance = $modal.open({
      templateUrl: 'modalPartnerErrors.html',
      controller: 'ModalPartnerErrorsInstanceController',
      size: size,
      resolve: {
        partnerEmi3Id: function () { return emi3id; },
        partnerGireveId: function() { return gireveId; },
        urlMethod: function () { return method; }
      }
    });
  };

  $scope.ignoreLastErrors = function (element) {
    element.errorflag = !element.errorflag;
  };

  $scope.remove = function (obj,index) {
    delete obj[index];
    return obj.filter(function(n){ return n !== undefined; });
  };

  $scope.addElement = function (obj) {
    if (obj === null) {
      obj = [];
    }

    obj.push({id:null});

    return obj;
  };

  $scope.searchField = function (fields, groupName, fieldName){
    var field;
    $scope.fields.forEach(function onEachKey(value) {
      if (value.name === groupName) {
        value.value.forEach(function onEachKey(subvalue) {
          if (subvalue.value === fieldName) {
            field = subvalue;
          }
        });
      }
    });
    return field;
  };

  $scope.subelementsConstraintList = function (items, field) {
    if (
      (typeof field['constraint_list'] !== 'undefined') && (field['constraint_list'] !== null) &&
      (typeof field['constraint_value'] !== 'undefined') && (field['constraint_value'] !== null) &&
      (typeof field['subfields'] !== 'undefined') && (field['subfields'] !== null)
    ) {
      var constraintList = field['constraint_list'];
      var constraintValue = field['constraint_value'];
      var fieldList = field['subfields'];
      var constraintListToAdd = constraintList.slice();
      var newItems = [];

      // Filtrage des items existants
      if ((typeof items !== 'undefined') && (items !== null)) {
        for (var i = 0; i < items.length; i++) {
          if (constraintList.indexOf(items[i][constraintValue]) !== -1) {
            newItems.push(items[i]);
            constraintListToAdd.splice(constraintListToAdd.indexOf(items[i][constraintValue]), 1);
          }
        }
      }

      newItems.sort(function(a,b) {
        if (a[constraintValue] < b[constraintValue]) {
          return -1;
        } else if (a[constraintValue] > b[constraintValue]) {
          return 1;
        } else {
          return 0;
        }
      });

      return newItems;
    }
  };
}
PartnerMonitorController.$inject = ['$scope', '$http', '$state', '$stateParams', 'Partners', 'ApiConstants', '$modal', '$location', 'Identity', '$timeout'];

module.exports = PartnerMonitorController;
