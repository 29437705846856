'use strict';

/**
 * @ngInject
 */
function RejeuSolicitationController($scope, $location, $modal, Extracts, $http, ApiConstants, Identity) {
    $scope.recoveryDate = new moment().subtract(1, 'months');
    $scope.result = null;

    $scope.recover = function(){
        var url = ApiConstants.backendApiUrl + '/solicitation_rejeu?recoverDate=' + $scope.recoveryDate.toJSON();
        $http.get(url).then(
            function onGetSuccess(success) {
                $scope.result = success.data;
            },
            function onGetError(error) {
                $scope.result = error.data;
            }
        );
    };

}
RejeuSolicitationController.$inject = ['$scope', '$location', '$modal', 'Extracts', '$http', 'ApiConstants', 'Identity'];

module.exports = RejeuSolicitationController;
