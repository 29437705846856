'use strict';

/**
 * @ngInject
 */
function EventsService($resource, ApiConstants, TransformJson) {
  return $resource(ApiConstants.authUrl + ApiConstants.endpointLogsUrl + '/event', {}, {
    query: {
      method: 'GET',
      transformResponse: function (data) { return TransformJson.transformResponse(data,'Event'); },
      isArray: true
    }
  });
}
EventsService.$inject = ['$resource', 'ApiConstants', 'TransformJson'];

module.exports = EventsService;
