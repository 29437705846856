'use strict';

function Storage(storage) {
  // Interface

  var service = {
    destroy: destroy,
    has: has,
    get: get,
    set: set
  };

  return service;

  // Implementation

  function destroy(key) {
    storage.removeItem(key);
  }

  function has(key) {
    return null !== storage.getItem(key);
  }

  function get(key) {
    var item = storage.getItem(key);

    try {
      item = JSON.parse(item);
    } catch (error) {
      item = null;
    }

    return (item && item.value) ? item.value : null;
  }

  function set(key, value) {
    var item = {
      value: value
    };

    var data = JSON.stringify(item);
    storage.setItem(key, data);
  }
}

module.exports = Storage;
