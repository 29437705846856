'use strict';

/**
 * @ngInject
 */
function ModalConfirmImportInvoicingDataInstanceController($scope, $modalInstance) {
    $scope.ok = function () {
        $modalInstance.close();
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };
}
ModalConfirmImportInvoicingDataInstanceController.$inject = ['$scope', '$modalInstance'];

module.exports = ModalConfirmImportInvoicingDataInstanceController;
