'use strict';

/**
 * @ngInject
 */
function QueueManagementController($scope, QueueMgt, $modal) {


  $scope.refresh = function() {
    $scope.queues = QueueMgt.getQueuesForList();
  };
  $scope.refresh();
  $scope.refreshDataTimer = null;
  $scope.refreshDataEnabled = false;
  $scope.refreshDataInterval = 300;
  $scope.formattedRefreshDataInterval = '5mn00';

  $scope.$on('$destroy', function() {
    if ($scope.refreshDataTimer) {
      clearTimeout($scope.refreshDataTimer);
      $scope.refreshDataTimer = null;
    }
  });

  $scope.fields = [
    {
      'value': 'name',
      'name': 'NAME'
    },
    {
      'value': 'description',
      'name': 'DESCRIPTION'
    },
    {
      'value': 'nbMessage',
      'name': 'NB_MESSAGE'
    }
  ];

  $scope.updateRefreshTimer = function() {
    // Cancel any existing timer
    if ($scope.refreshDataTimer) {
      clearInterval($scope.refreshDataTimer);
      $scope.refreshDataTimer = null;
    }

    // If enabled, create a new timer
    if ($scope.refreshDataEnabled) {
      $scope.refreshDataTimer = setInterval(function() {
        $scope.refresh();
      }, $scope.refreshDataInterval * 1000);
    }

    // Update formatted refresh interval
    var minutes = '' + Math.floor($scope.refreshDataInterval / 60);
    var seconds = '' + $scope.refreshDataInterval % 60;

    $scope.formattedRefreshDataInterval = '';

    if (minutes !== '0') {
      $scope.formattedRefreshDataInterval += minutes + 'mn';
      seconds = '00'.substring(0, 2 - seconds.length) + seconds;
    }

    $scope.formattedRefreshDataInterval += seconds + 's';
  };

  $scope.skipNextMessage = function (queueName) {
    var modalInstance = $modal.open({
      templateUrl: 'modalConfirmSkipCdr.html',
      controller: 'ModalConfirmInstanceController'
    });
    modalInstance.result.then(
      function onModalConfirm() {
        QueueMgt.skipNextMessage({
            queueName: queueName,
            totalMessageToSkip: 1
          },
          function onSuccess() {
            $scope.refresh();
          },
          function onError(error) {
            $modal.open({
              templateUrl: 'modalError.html',
              controller: 'ModalErrorInstanceController',
              resolve: {
                errorData: function () {
                  return error;
                }
              }
            });
          });
      },
      function onModalCancel() {
      }
    );
  };

  $scope.resetDduQueue = function () {
    var modalInstance = $modal.open({
      templateUrl: 'modalConfirmResetDduQueue.html',
      controller: 'ModalConfirmInstanceController'
    });
    modalInstance.result.then(
      function onModalConfirm() {
        QueueMgt.resetDdu(
          function onSuccess() {
            $scope.refresh();
          },
          function onError(error) {
            $modal.open({
              templateUrl: 'modalError.html',
              controller: 'ModalErrorInstanceController',
              resolve: {
                errorData: function () {
                  return error;
                }
              }
            });
            $scope.refresh();
          });
      },
      function onModalCancel() {
      }
    );
  };

}
QueueManagementController.$inject = ['$scope', 'QueueMgt', '$modal'];

module.exports = QueueManagementController;
