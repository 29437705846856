'use strict';

/**
 * @ngInject
 */
function ExportActionEventController($scope, $location, Traces, $http, ApiConstants, Identity) {
  $scope.actioneventquery = {};

  $scope.actioneventquery.startDate = new Date();
  $scope.actioneventquery.startDate.setHours(0);
  $scope.actioneventquery.startDate.setMinutes(0);

  $scope.export = function(){
    var url = ApiConstants.authUrl + ApiConstants.endpointUrl + '/actionevent';
    var firstParam = true;
    var sep;

    if (Identity.hasToken() && Identity.getUsername()) {
      sep = firstParam ? '?':'&';
      firstParam = false;
      url += sep + 'JSESSIONID=' + Identity.getToken();
    }

    if ((typeof $scope.actioneventquery.endDate !== 'undefined') && ($scope.actioneventquery.endDate !== null)) {
      sep = firstParam ? '?':'&';
      firstParam = false;
      url += sep + 'endDate=' + $scope.actioneventquery.endDate.toJSON();
    }

    if ((typeof $scope.actioneventquery.startDate !== 'undefined') && ($scope.actioneventquery.startDate !== null)) {
      sep = firstParam ? '?':'&';
      firstParam = false;
      url += sep + 'startDate=' + $scope.actioneventquery.startDate.toJSON();
    }

    window.open(url);
  };
}
ExportActionEventController.$inject = ['$scope', '$location', 'Traces', '$http', 'ApiConstants', 'Identity'];

module.exports = ExportActionEventController;
