'use strict';

/**
 * @ngInject
 */
function RetryCheckAndBillController($scope, $http, $modal, ApiConstants, Operators, $timeout) {

    $scope.displayUniqueCdr = true;
    $scope.displayCdrSession = false;
    $scope.displayCdrPeriod = false;

    $scope.displayUniqueCdrFunc = function () {
        $scope.displayUniqueCdr = !$scope.displayUniqueCdr;
        $scope.displayCdrSession = false;
        $scope.displayCdrPeriod = false;
    };
    $scope.displayCdrSessionFunc = function () {
        $scope.displayUniqueCdr = false;
        $scope.displayCdrSession = !$scope.displayCdrSession;
        $scope.displayCdrPeriod = false;
    };
    $scope.displayCdrPeriodFunc = function () {
        $scope.displayUniqueCdr = false;
        $scope.displayCdrSession = false;
        $scope.displayCdrPeriod = !$scope.displayCdrPeriod;
    };

    $scope.oneCdr = {
        cdrId: '',
        isLoading: false,
        cptIds: [],
        execute: function () {
            var self = this;
            var body = {cdrId: this.cdrId};
            var modalInstance = $modal.open({
                templateUrl: 'modalConfirmRetryCheckAndBill.html',
                controller: 'ModalConfirmRetryCheckAndBillController',
                resolve: {
                    dataConfirmation: function () {
                        return {message: 'RETRY_ONE_CDR_CONFIRMATION', params: body};
                    }
                }
            });
            modalInstance.result.then(
                function onModalConfirm() {
                    self.isLoading = true;
                    $http.post(ApiConstants.backendApiUrl + '/retry_check_and_bill/single_cdr', body).then(
                        function onGetSuccess(success) {
                            var data = success.data;
                            self.cptIds = [data];
                            self.isLoading = false;
                        },
                        function onGetError(error) {
                            self.isLoading = false;
                            $modal.open({
                                templateUrl: 'modalError.html',
                                controller: 'ModalErrorInstanceController',
                                resolve: {
                                    errorData: function () {
                                        return error;
                                    }
                                }
                            });
                        }
                    );
                },
                function onModalCancel() {
                    self.isLoading = false;
                }
            );
        },
        upload: function () {
            var self = this;
            var body = {file: $scope.oneCdr.file.name};
            var modalInstance = $modal.open({
                templateUrl: 'modalConfirmRetryCheckAndBill.html',
                controller: 'ModalConfirmRetryCheckAndBillController',
                resolve: {
                    dataConfirmation: function () {
                        return {message: 'RETRY_ONE_CDR_CONFIRMATION_IMPORT', params: body};
                    }
                }
            });
            modalInstance.result.then(
                function onModalConfirm() {
                    self.isLoading = true;

                    var formdata = new FormData();
                    formdata.append('file', $scope.oneCdr.file);

                    var request = {
                        method: 'POST',
                        url: ApiConstants.backendApiUrl + '/retry_check_and_bill/multiple_cdr_file',
                        data: formdata,
                        transformRequest: angular.identity,
                        headers: {
                            'Content-Type': undefined
                        }
                    };

                    $http(request).then(
                        function onGetSuccess(success) {
                            self.cptIds = success.data.cptIds;
                            self.isLoading = false;
                        },
                        function onGetError(error) {
                            self.isLoading = false;
                            $modal.open({
                                templateUrl: 'modalError.html',
                                controller: 'ModalErrorInstanceController',
                                resolve: {
                                    errorData: function () {
                                        return error;
                                    }
                                }
                            });
                        }
                    );
                },
                function onModalCancel() {
                    self.isLoading = false;
                }
            );
        }
    };

    $scope.lastCdr = {
        sessionId: '',
        isLoading: false,
        cptIds: [],
        execute: function () {
            var self = this;
            var body = {sessionId: this.sessionId};
            var modalInstance = $modal.open({
                templateUrl: 'modalConfirmRetryCheckAndBill.html',
                controller: 'ModalConfirmRetryCheckAndBillController',
                resolve: {
                    dataConfirmation: function () {
                        return {message: 'RETRY_LAST_CDR_CONFIRMATION', params: body};
                    }
                }
            });
            modalInstance.result.then(
                function onModalConfirm() {
                    self.isLoading = true;
                    $http.post(ApiConstants.backendApiUrl + '/retry_check_and_bill/last_cdr', body).then(
                        function onGetSuccess(success) {
                            var data = success.data;
                            self.cptIds = [data];
                            self.isLoading = false;
                        },
                        function onGetError(error) {
                            self.isLoading = false;
                            $modal.open({
                                templateUrl: 'modalError.html',
                                controller: 'ModalErrorInstanceController',
                                resolve: {
                                    errorData: function () {
                                        return error;
                                    }
                                }
                            });
                        }
                    );
                },
                function onModalCancel() {
                    self.isLoading = false;
                }
            );
        },
        upload: function () {
            var self = this;
            var body = {file: $scope.lastCdr.file.name};
            var modalInstance = $modal.open({
                templateUrl: 'modalConfirmRetryCheckAndBill.html',
                controller: 'ModalConfirmRetryCheckAndBillController',
                resolve: {
                    dataConfirmation: function () {
                        return {message: 'RETRY_LAST_CDR_CONFIRMATION_IMPORT', params: body};
                    }
                }
            });
            modalInstance.result.then(
                function onModalConfirm() {
                    self.isLoading = true;

                    var formdata = new FormData();
                    formdata.append('file', $scope.lastCdr.file);

                    var request = {
                        method: 'POST',
                        url: ApiConstants.backendApiUrl + '/retry_check_and_bill/last_cdr_file',
                        data: formdata,
                        transformRequest: angular.identity,
                        headers: {
                            'Content-Type': undefined
                        }
                    };

                    $http(request).then(
                        function onGetSuccess(success) {
                            self.cptIds = success.data.cptIds;
                            self.isLoading = false;
                        },
                        function onGetError(error) {
                            self.isLoading = false;
                            $modal.open({
                                templateUrl: 'modalError.html',
                                controller: 'ModalErrorInstanceController',
                                resolve: {
                                    errorData: function () {
                                        return error;
                                    }
                                }
                            });
                        }
                    );
                },
                function onModalCancel() {
                    self.isLoading = false;
                }
            );
        }
    };

    $scope.allCdrSession = {
        sessionId: '',
        isLoading: false,
        cptIds: [],
        execute: function () {
            var self = this;
            var body = {sessionId: this.sessionId};
            var modalInstance = $modal.open({
                templateUrl: 'modalConfirmRetryCheckAndBill.html',
                controller: 'ModalConfirmRetryCheckAndBillController',
                resolve: {
                    dataConfirmation: function () {
                        return {message: 'RETRY_ALL_CDR_SESSION_CONFIRMATION', params: body};
                    }
                }
            });
            modalInstance.result.then(
                function onModalConfirm() {
                    self.isLoading = true;
                    $http.post(ApiConstants.backendApiUrl + '/retry_check_and_bill/all_cdr_session', body).then(
                        function onGetSuccess(success) {
                            self.cptIds = success.data.cptIds;
                            self.isLoading = false;
                        },
                        function onGetError(error) {
                            self.isLoading = false;
                            $modal.open({
                                templateUrl: 'modalError.html',
                                controller: 'ModalErrorInstanceController',
                                resolve: {
                                    errorData: function () {
                                        return error;
                                    }
                                }
                            });
                        }
                    );
                },
                function onModalCancel() {
                    self.isLoading = false;
                }
            );
        },
        upload: function () {
            var self = this;
            var body = {file: $scope.allCdrSession.file.name};
            var modalInstance = $modal.open({
                templateUrl: 'modalConfirmRetryCheckAndBill.html',
                controller: 'ModalConfirmRetryCheckAndBillController',
                resolve: {
                    dataConfirmation: function () {
                        return {message: 'RETRY_ALL_CDR_SESSION_CONFIRMATION_IMPORT', params: body};
                    }
                }
            });
            modalInstance.result.then(
                function onModalConfirm() {
                    self.isLoading = true;

                    var formdata = new FormData();
                    formdata.append('file', $scope.allCdrSession.file);
                    var request = {
                        method: 'POST',
                        url: ApiConstants.backendApiUrl + '/retry_check_and_bill/all_cdr_session_file',
                        data: formdata,
                        transformRequest: angular.identity,
                        headers: {
                            'Content-Type': undefined
                        }
                    };

                    $http(request).then(
                        function onGetSuccess(success) {
                            self.cptIds = success.data.cptIds;
                            self.isLoading = false;
                        },
                        function onGetError(error) {
                            self.isLoading = false;
                            $modal.open({
                                templateUrl: 'modalError.html',
                                controller: 'ModalErrorInstanceController',
                                resolve: {
                                    errorData: function () {
                                        return error;
                                    }
                                }
                            });
                        }
                    );
                },
                function onModalCancel() {
                    self.isLoading = false;
                }
            );
        }
    };

    $scope.allCdrPeriod = {
        cpo: null,
        emsp: null,
        startDate: null,
        endDate: null,
        pertinentOnly: true,
        isLoading: false,
        cptIds: [],
        allEmsp: false,
        allCpo: false,
        csvUri: null,
        downloadSucceed: false,
        execute: function () {
            var self = this;
            if (!self.cpo && !self.allCpo) {
                $modal.open({
                    templateUrl: 'modalErrorInvalidForm.html',
                    controller: 'ModalErrorInvalidFormInstanceController',
                    resolve: {
                        errorData: function () {
                            return {message: 'CPO_CHECKBOX'};
                        }
                    }
                }).result.then(function onModalConfirm() {
                }, function onModalCancel() {
                    self.isLoading = false;
                });
            } else {
                if (!self.emsp && !self.allEmsp) {
                    $modal.open({
                        templateUrl: 'modalErrorInvalidForm.html',
                        controller: 'ModalErrorInvalidFormInstanceController',
                        resolve: {
                            errorData: function () {
                                return {message: 'EMSP_CHECKBOX'};
                            }
                        }
                    }).result.then(function onModalConfirm() {
                    }, function onModalCancel() {
                        self.isLoading = false;
                    });
                } else {
                    var body = {
                        cpoId: self.cpo ? self.cpo.id : null,
                        cpo: self.cpo ? self.cpo.name : null,
                        emspId: self.emsp ? self.emsp.id : null,
                        emsp: self.emsp ? self.emsp.name : null,
                        startDate: self.startDate,
                        endDate: self.endDate,
                        pertinentOnly: self.pertinentOnly
                    };
                    var modalInstance = $modal.open({
                        templateUrl: 'modalConfirmRetryCheckAndBill.html',
                        controller: 'ModalConfirmRetryCheckAndBillController',
                        resolve: {
                            dataConfirmation: function () {
                                return {
                                    message: 'RETRY_ALL_CDR_PERIOD_CONFIRMATION', params: body
                                };
                            }
                        }
                    });
                    modalInstance.result.then(
                        function onModalConfirm() {
                            self.isLoading = true;
                            $http.post(ApiConstants.backendApiUrl + '/retry_check_and_bill/all_cdr_period', body).then(
                                function onGetSuccess(success) {
                                    self.cptIds = success.data.cptIds;
                                    if (self.cptIds.length >= 100) {
                                        exportToCsv('exportCdrsByPeriod.csv', self.cptIds);
                                        self.downloadSucceed = true;
                                        self.isLoading = false;
                                    }
                                    self.isLoading = false;
                                },
                                function onGetError(error) {
                                    self.isLoading = false;
                                    $modal.open({
                                        templateUrl: 'modalError.html',
                                        controller: 'ModalErrorInstanceController',
                                        resolve: {
                                            errorData: function () {
                                                return error;
                                            }
                                        }
                                    });
                                }
                            );
                        },
                        function onModalCancel() {
                            self.isLoading = false;
                        }
                    );
                }
            }
        }
    };

    $scope.rebuilddatepicker = true;
    $scope.$on('$localeChangeSuccess', function () {
        $scope.rebuilddatepicker = false;
        $timeout(function () {
            $scope.rebuilddatepicker = true;
        }, 0);
    });

    $scope.fields = [
        {
            'value': 'cdrId',
            'name': 'CDR_ID'
        },
        {
            'value': 'cptId',
            'name': 'CPTID'
        },
        {
            'value': 'serviceSessionId',
            'name': 'SESSION_ID'
        }
    ];

    $scope.operatorsEMSP = Operators.queryByRoleSimple({id: 'eMSP'});
    $scope.operatorsCPO = Operators.queryByRoleSimple({id: 'CPO'});

    function exportToCsv(filename, rows) {
        var csvFile = rows.map(function (d) {
            return JSON.stringify(Object.values(d));
        })
            .join('\n')
            .replace(/(^\[)|(\]$)/mg, '');

        var blob = new Blob([csvFile], {type: 'text/csv;charset=utf-8;'});
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            var link = document.createElement('a');
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

}
RetryCheckAndBillController.$inject = ['$scope', '$http', '$modal', 'ApiConstants', 'Operators', '$timeout'];

module.exports = RetryCheckAndBillController;
