'use strict';

/**
 * @ngInject
 */
function PartnerEditController($scope, $http, $stateParams, Partners, $modal, $location, Identity) {
    $scope.rebuilddatepicker = true;

    $scope.partner = Partners.newGet({id: $stateParams.id}, function () {

        var partnerurlEmipListField = $scope.searchField($scope.fields, 'PARTNERS_URLS_EMIP', 'partnerurlEmipList');
        $scope.partner.partnerurlEmipList = $scope.categorizeSubelements($scope.subelementsConstraintList($scope.partner.partnerurlEmipList, partnerurlEmipListField));

        var partnerurlOchpListField = $scope.searchField($scope.fields, 'PARTNERS_URLS_OCHP', 'partnerurlOchpList');
        $scope.partner.partnerurlOchpList = $scope.subelementsConstraintList($scope.partner.partnerurlOchpList, partnerurlOchpListField);

        var partnerurlOicpListField = $scope.searchField($scope.fields, 'PARTNERS_URLS_OICP', 'partnerurlOicpList');
        $scope.partner.partnerurlOicpList = $scope.subelementsConstraintList($scope.partner.partnerurlOicpList, partnerurlOicpListField);
        var partnerurlOcpiCpoListField = $scope.searchInnerField($scope.fields, 'PARTNERS_URLS_OCPI_CPO', 'partnerurlOcpiCpoList');
        $scope.partner.partnerurlOcpiCpoList = $scope.subelementsConstraintList($scope.partner.partnerurlOcpiCpoList, partnerurlOcpiCpoListField);
        var partnerurlOcpiEmspListField = $scope.searchInnerField($scope.fields, 'PARTNERS_URLS_OCPI_EMSP', 'partnerurlOcpiEmspList');
        $scope.partner.partnerurlOcpiEmspList = $scope.subelementsConstraintList($scope.partner.partnerurlOcpiEmspList, partnerurlOcpiEmspListField);

        var partnerurlOcpi221ListField = $scope.searchInnerField($scope.fields, 'PARTNERS_OCPI_221_CONFIGURATION_GENERALE', 'partnerurlOcpi221ListGeneral');
        $scope.partner.partnerurlOcpi221ListGeneral = $scope.filterSubelementsByConstraintList($scope.partner.partnerurlOcpi221List, partnerurlOcpi221ListField);
        var partnerurlOcpi221ListCpoField = $scope.searchInnerField($scope.fields, 'PARTNERS_URLS_OCPI_221_CPO', 'partnerurlOcpi221ListCpo');
        $scope.partner.partnerurlOcpi221ListCpo = $scope.filterSubelementsByConstraintList($scope.partner.partnerurlOcpi221List, partnerurlOcpi221ListCpoField);
        var partnerurlOcpi221ListEmspField = $scope.searchInnerField($scope.fields, 'PARTNERS_URLS_OCPI_221_EMSP', 'partnerurlOcpi221ListEmsp');
        $scope.partner.partnerurlOcpi221ListEmsp = $scope.filterSubelementsByConstraintList($scope.partner.partnerurlOcpi221List, partnerurlOcpi221ListEmspField);

        if ($scope.partner.preferredProtocolList === null) {
            $scope.partner.preferredProtocolList = [];
        }

        $scope.partner.cpoFromiopOcpiTokenId = $scope.partner.cpoOcpiPartnerConfiguration.fromiopOcpiTokenId;
        $scope.partner.cpoOcpiVersion = $scope.partner.cpoOcpiPartnerConfiguration.ocpiVersion;
        $scope.partner.cpoToiopOcpiTokenId = $scope.partner.cpoOcpiPartnerConfiguration.toiopOcpiTokenId;
        $scope.partner.emspFromiopOcpiTokenId = $scope.partner.emspOcpiPartnerConfiguration.fromiopOcpiTokenId;
        $scope.partner.emspOcpiVersion = $scope.partner.emspOcpiPartnerConfiguration.ocpiVersion;
        $scope.partner.emspToiopOcpiTokenId = $scope.partner.emspOcpiPartnerConfiguration.toiopOcpiTokenId;

        $scope.partner.fromiopOcpi221TokenId = $scope.partner.ocpi221PartnerConfiguration.fromiopOcpiTokenId;
        $scope.partner.ocpi221Version = $scope.partner.ocpi221PartnerConfiguration.ocpiVersion;
        $scope.partner.toiopOcpi221TokenId = $scope.partner.ocpi221PartnerConfiguration.toiopOcpiTokenId;

        $scope.obj = $scope.partner;

    });
    $scope.protocolList = [
        {'id': 'eMIP', 'name': 'eMIP'},
        {'id': 'OICP', 'name': 'OICP'},
        {'id': 'OCHP', 'name': 'OCHP'},
        {'id': 'OCPI 2.1.1', 'name': 'OCPI 2.1.1'},
        {'id': 'OCPI 2.2.1', 'name': 'OCPI 2.2.1'},
    ];
    $scope.ochpModeList = ['eMI3', 'DataAggregator'];
    $scope.versionTlsList = ['1.2', '1.1'];
    $scope.fields = [
        {
            'name': 'FIELDS_GENERAL',
            'value': [
                {
                    'value': 'gireveId',
                    'name': 'GIREVE_ID',
                    'type': 'text',
                    'required': true
                },
                {
                    'value': 'emi3Id',
                    'name': 'EMI3ID',
                    'type': 'text'
                },
                {
                    'value': 'name',
                    'name': 'NAME',
                    'type': 'text'
                },
                {
                    'value': 'ipAddress',
                    'name': 'IP_IN',
                    'type': 'textarea'
                },
                {
                    'value': 'certificateId',
                    'name': 'CERTIFICATE_ID',
                    'type': 'text'
                },
                {
                    'value': 'certificateSignedRequest',
                    'name': 'CERTIFICATE_SIGNED_REQUEST',
                    'type': 'boolean',
                    'options': {
                        'truevalue': 'ACTIF_M',
                        'falsevalue': 'INACTIF_M',
                        'defaultvalue': 'INACTIF_M'
                    }
                },
                {
                    'value': 'certificateExpiringDate',
                    'name': 'EXPIRING_CERTIFICATE',
                    'type': 'datetimeDay',
                    'required': false
                },
                {
                    'value': 'operatorControl',
                    'name': 'OPERATOR_CONTROL',
                    'type': 'boolean',
                    'options': {
                        'truevalue': 'ACTIF_M',
                        'falsevalue': 'INACTIF_M',
                        'defaultvalue': 'INACTIF_M'
                    }
                },
                {
                    'value': 'ochpMode',
                    'name': 'OCHP_MODE',
                    'type': 'object',
                    'required': true,
                    'options': {
                        'list': 'ochpModeList',
                        'id': 'name',
                        'label': 'name'
                    }
                },
                {
                    'value': 'preferredProtocolList',
                    'name': 'PREFERRED_PROTOCOL',
                    'type': 'object_multi_autocomplete',
                    'options': {
                        'list': 'protocolList',
                        'id': 'id',
                        'label': 'name',
                        'sortable': true
                    }
                },
                {
                    'value': 'versionTls',
                    'name': 'VERSION_TLS',
                    'type': 'object',
                    'required': true,
                    'options': {
                        'list': 'versionTlsList',
                        'id': 'name',
                        'label': 'name'
                    }
                },
                {
                    'value': 'comment',
                    'name': 'COMMENTS',
                    'type': 'text'
                },
                {
                    'value': 'partnerGroup',
                    'name': 'PARTNER_GROUP',
                    'type': 'text'
                },
                {
                    'value': 'useGcpProxy',
                    'name': 'USE_GCP_PROXY',
                    'type': 'boolean',
                    'options': {
                        'truevalue': 'ACTIF_M',
                        'falsevalue': 'INACTIF_M',
                        'defaultvalue': 'INACTIF_M'
                    }
                }
            ]
        },
        {
            'name': 'PARTNERS_URLS_EMIP',
            'value': [{
                'value': 'partnerurlEmipList',
                'name': 'method',
                'type': 'subelements_constraint',
                'has_categories': true,
                'constraint_list': ['Heartbeat', 'SearchEVSE', 'SetServiceAuthorisation', 'GetServiceAuthorisation', 'SetChargeDetailRecord', 'SetExtendedChargeDetailRecord', 'GetEVChargingNeedFromeMSP', 'SetSessionActionRequest', 'SetSessionEventReport', 'GetBookingOnPools', 'SetBookingAccept', 'SetBookingAbort', 'SetBookingCancellation', 'SetBookingEventReport', 'SetBookingActionRequest', 'SetBookingChargingPoolOrEVSE'],
                'constraint_value': 'method',
                'constraint_objects_required': true,
                'subfields': [
                    {
                        'value': 'url',
                        'name': 'URL',
                        'type': 'text'
                    },
                    {
                        'value': 'protocol',
                        'name': 'PROTOCOL',
                        'type': 'hidden',
                        'default_value': 'eMIP'
                    },
                    {
                        'value': 'method',
                        'name': 'METHOD',
                        'type': 'hidden',
                        'required': true
                    },
                    {
                        'value': 'timeout',
                        'name': 'TIMEOUT',
                        'type': 'integer'
                    },
                    {
                        'value': 'description',
                        'name': 'DESCRIPTION',
                        'type': 'text'
                    }
                ]
            }]
        },
        {
            'name': 'PARTNERS_URLS_OCHP',
            'value': [
                {
                    'value': 'ochpUsername',
                    'name': 'OCHP_USERNAME',
                    'type': 'text'
                },
                {
                    'value': 'ochpPassword',
                    'name': 'OCHP_PASSWORD',
                    'type': 'text'
                },
                {
                    'value': 'partnerurlOchpList',
                    'name': 'method',
                    'type': 'subelements_constraint',
                    'has_categories': false,
                    'constraint_list': ['GetStatus', 'GetCDRs', 'GetRoamingAuthorisationList', /*'ConfirmCDRs'*/],
                    'constraint_value': 'method',
                    'constraint_objects_required': true,
                    'subfields': [
                        {
                            'value': 'url',
                            'name': 'URL',
                            'type': 'text'
                        },
                        {
                            'value': 'protocol',
                            'name': 'PROTOCOL',
                            'type': 'hidden',
                            'default_value': 'OCHP'
                        },
                        {
                            'value': 'method',
                            'name': 'METHOD',
                            'type': 'hidden'
                        },
                        {
                            'value': 'timeout',
                            'name': 'TIMEOUT',
                            'type': 'integer'
                        },
                        {
                            'value': 'description',
                            'name': 'DESCRIPTION',
                            'type': 'text'
                        },
                        {
                            'value': 'periodiccallperiod',
                            'name': 'PERIODIC_CALL_PERIOD',
                            'type': 'integer',
                            'condition': function (element, field) {
                                return ['GetCDRs', 'GetRoamingAuthorisationList'].indexOf(element[field.name]) >= 0;
                            }
                        },
                        {
                            'value': 'lastsuccessfulresponsetimestamp',
                            'name': 'LAST_SUCCESSFUL_RESPONSE_TIMESTAMP',
                            'type': 'datetime',
                            'condition': function (element, field) {
                                return ['GetCDRs', 'GetRoamingAuthorisationList'].indexOf(element[field.name]) >= 0;
                            }
                        }
                    ]
                }]
        },
        {
            'name': 'PARTNERS_URLS_OICP',
            'value': [{
                'value': 'partnerurlOicpList',
                'name': 'method',
                'type': 'subelements_constraint',
                'has_categories': false,
                'constraint_list': ['eRoamingPullEvseStatus', 'eRoamingGetChargeDetailRecords', 'eRoamingPullAuthenticationData', 'eRoamingAuthorizeStart', 'eRoamingAuthorizeRemoteStart', 'eRoamingAuthorizeRemoteStop'],
                'constraint_value': 'method',
                'subfields': [
                    {
                        'value': 'url',
                        'name': 'URL',
                        'type': 'text'
                    },
                    {
                        'value': 'protocol',
                        'name': 'PROTOCOL',
                        'type': 'hidden',
                        'default_value': 'OICP'
                    },
                    {
                        'value': 'method',
                        'name': 'METHOD',
                        'type': 'hidden'
                    },
                    {
                        'value': 'timeout',
                        'name': 'TIMEOUT',
                        'type': 'integer'
                    },
                    {
                        'value': 'description',
                        'name': 'DESCRIPTION',
                        'type': 'text'
                    },
                    {
                        'value': 'periodiccallperiod',
                        'name': 'PERIODIC_CALL_PERIOD',
                        'type': 'integer',
                        'condition': function (element, field) {
                            return ['eRoamingGetChargeDetailRecords', 'eRoamingPullAuthenticationData'].indexOf(element[field.name]) >= 0;
                        }
                    },
                    {
                        'value': 'lastsuccessfulresponsetimestamp',
                        'name': 'LAST_SUCCESSFUL_RESPONSE_TIMESTAMP',
                        'type': 'datetime',
                        'condition': function (element, field) {
                            return ['eRoamingGetChargeDetailRecords', 'eRoamingPullAuthenticationData'].indexOf(element[field.name]) >= 0;
                        }
                    }
                ]
            }]
        },
        {
            'name': 'PARTNERS_OCPI_211_CONFIGURATION',
            'value': [
                {
                    'name': 'PARTNERS_URLS_OCPI_CPO',
                    'type': 'innerelements',
                    'value': [
                        {
                            'value': 'cpoOcpiVersion',
                            'name': 'OCPI_VERSION',
                            'type': 'text',
                            'readonly': true
                        },
                        {
                            'value': 'cpoFromiopOcpiTokenId',
                            'name': 'FROM_IOP_OCPI_TOKEN_ID',
                            'type': 'text'
                        },
                        {
                            'value': 'cpoToiopOcpiTokenId',
                            'name': 'TO_IOP_OCPI_TOKEN_ID',
                            'type': 'text'
                        },
                        {
                            'value': 'partnerurlOcpiCpoList',
                            'name': 'method',
                            'type': 'subelements_constraint',
                            'has_categories': false,
                            'constraint_list': ['versions', 'locations', 'cdrs', 'tariffs'],
                            'constraint_value': 'method',
                            'subfields': [
                                {
                                    'value': 'url',
                                    'name': 'URL',
                                    'type': 'text',
                                    'condition': function (element, field) {
                                        return ['versions'].indexOf(element[field.name]) >= 0;
                                    }
                                },
                                {
                                    'value': 'protocol',
                                    'name': 'PROTOCOL',
                                    'type': 'hidden',
                                    'default_value': 'OCPI_CPO'
                                },
                                {
                                    'value': 'method',
                                    'name': 'METHOD',
                                    'type': 'hidden'
                                },
                                {
                                    'value': 'timeout',
                                    'name': 'TIMEOUT',
                                    'type': 'integer'
                                },
                                {
                                    'value': 'description',
                                    'name': 'DESCRIPTION',
                                    'type': 'text'
                                },
                                {
                                    'value': 'periodiccallperiod',
                                    'name': 'PERIODIC_CALL_PERIOD',
                                    'type': 'integer',
                                    'condition': function (element, field) {
                                        return ['locations', 'cdrs', 'tariffs'].indexOf(element[field.name]) >= 0;
                                    }
                                },
                                {
                                    'value': 'lastsuccessfulresponsetimestamp',
                                    'name': 'LAST_SUCCESSFUL_RESPONSE_TIMESTAMP',
                                    'type': 'datetime',
                                    'condition': function (element, field) {
                                        return ['cdrs', 'tariffs'].indexOf(element[field.name]) >= 0;
                                    }
                                }
                            ],
                        }],
                },
                {
                    'name': 'PARTNERS_URLS_OCPI_EMSP',
                    'type': 'innerelements',
                    'value': [
                        {
                            'value': 'emspOcpiVersion',
                            'name': 'OCPI_VERSION',
                            'type': 'text',
                            'readonly': true
                        },
                        {
                            'value': 'emspFromiopOcpiTokenId',
                            'name': 'FROM_IOP_OCPI_TOKEN_ID',
                            'type': 'text'
                        },
                        {
                            'value': 'emspToiopOcpiTokenId',
                            'name': 'TO_IOP_OCPI_TOKEN_ID',
                            'type': 'text'
                        },
                        {
                            'value': 'partnerurlOcpiEmspList',
                            'name': 'method',
                            'type': 'subelements_constraint',
                            'has_categories': false,
                            'constraint_list': ['versions', 'tokens'],
                            'constraint_value': 'method',
                            'subfields': [
                                {
                                    'value': 'url',
                                    'name': 'URL',
                                    'type': 'text',
                                    'condition': function (element, field) {
                                        return ['versions'].indexOf(element[field.name]) >= 0;
                                    }
                                },
                                {
                                    'value': 'protocol',
                                    'name': 'PROTOCOL',
                                    'type': 'hidden',
                                    'default_value': 'OCPI_EMSP'
                                },
                                {
                                    'value': 'method',
                                    'name': 'METHOD',
                                    'type': 'hidden'
                                },
                                {
                                    'value': 'timeout',
                                    'name': 'TIMEOUT',
                                    'type': 'integer'
                                },
                                {
                                    'value': 'description',
                                    'name': 'DESCRIPTION',
                                    'type': 'text'
                                },
                                {
                                    'value': 'periodiccallperiod',
                                    'name': 'PERIODIC_CALL_PERIOD',
                                    'type': 'integer',
                                    'condition': function (element, field) {
                                        return ['locations', 'tokens'].indexOf(element[field.name]) >= 0;
                                    }
                                },
                                {
                                    'value': 'lastsuccessfulresponsetimestamp',
                                    'name': 'LAST_SUCCESSFUL_RESPONSE_TIMESTAMP',
                                    'type': 'datetime',
                                    'condition': function (element, field) {
                                        return ['tokens'].indexOf(element[field.name]) >= 0;
                                    }
                                }
                            ]
                        }],
                },
            ],
            'condition': function (element, field) {
                return $scope.checkProtocol('OCPI 2.1.1');
            }
        },

        {
            'name': 'PARTNERS_OCPI_221_CONFIGURATION',
            'value': [
                {
                    'name': 'PARTNERS_OCPI_221_CONFIGURATION_GENERALE',
                    'type': 'innerelements',
                    'value': [
                        {
                            'value': 'ocpi221Version',
                            'name': 'OCPI_VERSION',
                            'type': 'text',
                            'readonly': true
                        },
                        {
                            'value': 'fromiopOcpi221TokenId',
                            'name': 'FROM_IOP_OCPI_TOKEN_ID',
                            'type': 'text'
                        },
                        {
                            'value': 'toiopOcpi221TokenId',
                            'name': 'TO_IOP_OCPI_TOKEN_ID',
                            'type': 'text'
                        },
                        {
                            'value': 'partnerurlOcpi221ListGeneral',
                            'name': 'method',
                            'type': 'subelements_constraint',
                            'has_categories': false,
                            'constraint_list': ['versions', 'credentials'],
                            'constraint_value': 'method',
                            'subfields': [
                                {
                                    'value': 'url',
                                    'name': 'URL',
                                    'type': 'text',
                                    'condition': function (element, field) {
                                        return ['versions'].indexOf(element[field.name]) >= 0;
                                    }
                                },
                                {
                                    'value': 'url',
                                    'name': 'URL',
                                    'type': 'text',
                                    'readonly': true,
                                    'condition': function (element, field) {
                                        return ['versions'].indexOf(element[field.name]) < 0;
                                    }
                                },
                                {
                                    'value': 'protocol',
                                    'name': 'PROTOCOL',
                                    'type': 'hidden',
                                    'default_value': 'OCPI_2.2.1'
                                },
                                {
                                    'value': 'method',
                                    'name': 'METHOD',
                                    'type': 'hidden'
                                },
                                {
                                    'value': 'timeout',
                                    'name': 'TIMEOUT',
                                    'type': 'integer'
                                },
                                {
                                    'value': 'description',
                                    'name': 'DESCRIPTION',
                                    'type': 'text'
                                }
                            ]
                        }],
                },
                {
                    'name': 'PARTNERS_URLS_OCPI_221_CPO',
                    'type': 'innerelements',
                    'value': [
                        {
                            'value': 'partnerurlOcpi221ListCpo',
                            'name': 'method',
                            'type': 'subelements_constraint',
                            'has_categories': false,
                            'constraint_list': ['cdrs (sender)', 'commands (receiver)', 'sessions (sender)', 'locations (sender)', 'tariffs (sender)', 'tokens (receiver)'],
                            'constraint_value': 'method',
                            'subfields': [
                                {
                                    'value': 'url',
                                    'name': 'URL',
                                    'type': 'text',
                                    'readonly': true
                                },
                                {
                                    'value': 'protocol',
                                    'name': 'PROTOCOL',
                                    'type': 'hidden',
                                    'default_value': 'OCPI_2.2.1'
                                },
                                {
                                    'value': 'method',
                                    'name': 'METHOD',
                                    'type': 'hidden'
                                },
                                {
                                    'value': 'timeout',
                                    'name': 'TIMEOUT',
                                    'type': 'integer'
                                },
                                {
                                    'value': 'description',
                                    'name': 'DESCRIPTION',
                                    'type': 'text'
                                },
                                {
                                    'value': 'periodiccallperiod',
                                    'name': 'PERIODIC_CALL_PERIOD',
                                    'type': 'integer',
                                    'condition': function (element, field) {
                                        return ['locations (sender)'].indexOf(element[field.name]) >= 0;
                                    }
                                }
                            ]
                        }],
                },
                {
                    'name': 'PARTNERS_URLS_OCPI_221_EMSP',
                    'type': 'innerelements',
                    'value': [
                        {
                            'value': 'partnerurlOcpi221ListEmsp',
                            'name': 'method',
                            'type': 'subelements_constraint',
                            'has_categories': false,
                            'constraint_list': ['cdrs (receiver)', 'commands (sender)', 'locations (receiver)', 'sessions (receiver)', 'tariffs (receiver)', 'tokens (sender)'],
                            'constraint_value': 'method',
                            'subfields': [
                                {
                                    'value': 'url',
                                    'name': 'URL',
                                    'type': 'text',
                                    'readonly': true
                                },
                                {
                                    'value': 'protocol',
                                    'name': 'PROTOCOL',
                                    'type': 'hidden',
                                    'default_value': 'OCPI_2.2.1'
                                },
                                {
                                    'value': 'method',
                                    'name': 'METHOD',
                                    'type': 'hidden'
                                },
                                {
                                    'value': 'timeout',
                                    'name': 'TIMEOUT',
                                    'type': 'integer'
                                },
                                {
                                    'value': 'description',
                                    'name': 'DESCRIPTION',
                                    'type': 'text'
                                }
                            ]
                        }],
                },/**/
            ],
            'condition': function (element, field) {
                return $scope.checkProtocol('OCPI 2.2.1');
            }
        },

        {
            'name': 'MUST_BE_MONITORED',
            'value': [
                {
                    'value': 'mustBeMonitored',
                    'name': 'MUST_BE_MONITORED_STATE',
                    'type': 'boolean',
                    'options': {
                        'truevalue': 'ACTIF_F',
                        'falsevalue': 'INACTIF_F',
                        'defaultvalue': 'INACTIF_F'
                    }
                },
                {
                    'value': 'clientHeartbeatPeriod',
                    'name': 'MONITORING_PERIOD',
                    'type': 'text'
                }
            ]
        },
        {
            'name': 'FIELDS_HEARTBEAT',
            'value': [
                {
                    'value': 'serverMustBeConnected',
                    'name': 'MUST_BE_CONNECTED',
                    'type': 'boolean',
                    'options': {
                        'truevalue': 'ACTIF_M',
                        'falsevalue': 'INACTIF_M',
                        'defaultvalue': 'INACTIF_M'
                    }
                },
                {
                    'value': 'serverHeartbeatPeriod',
                    'name': 'HEARTBEAT_PERIOD',
                    'type': 'text'
                }
            ]
        }
    ];

    $scope.updatePartner = function () {
        $scope.partner.updatedBy = Identity.getUsername();
        $scope.partner.partnerurlEmipList = $scope.uncategorizeSubelements($scope.partner.partnerurlEmipList);


        $scope.partner.$update(
            function onUpdateSuccess() {
                $location.path('admin/partners/' + $stateParams.id + '/view');
            },
            function onUpdateError(error) {
                $modal.open({
                    templateUrl: 'modalError.html',
                    controller: 'ModalErrorInstanceController',
                    resolve: {
                        errorData: function () {
                            return error;
                        }
                    }
                });
            }
        );
    };

    $scope.update = function (size) {
        if (!$scope.iopForm.$valid || $scope.hasIncorrectHeartbeatValues()) {
            $scope.submittedForm = true;
            return;
        }

        var preferredProtocolList = $scope.obj.preferredProtocolList;
        var modalInstance;
        if ($scope.ochpAuthentInvalid()) {
            modalInstance = $modal.open({
                templateUrl: 'modalConfirmOchpEmptyAuthentication.html',
                controller: 'ModalConfirmInstanceController',
                size: size
            });
        } else {
            modalInstance = $modal.open({
                templateUrl: 'modalConfirmUpdate.html',
                controller: 'ModalConfirmInstanceController',
                size: size
            });
        }

        modalInstance.result.then(
            function onModalConfirm() {
                $scope.updatePartner();
            },
            function onModalCancel() {
            }
        );
    };

    $scope.ochpAuthentInvalid = function () {
        var preferredProtocolList = $scope.obj.preferredProtocolList;
        if (typeof preferredProtocolList !== 'undefined' && preferredProtocolList !== null && preferredProtocolList[0] === 'OCHP') {
            var ochpusername = $scope.obj.ochpUsername;
            var ochppassword = $scope.obj.ochpPassword;
            if (typeof ochpusername !== 'undefined' && ochpusername && typeof ochppassword !== 'undefined' && ochppassword) {
                return false;
            } else {
                return true;
            }
        }
        return false;
    };

    $scope.hasIncorrectHeartbeatValues = function () {
        var hbClientOk = $scope.partner.clientHeartbeatPeriod > 0;
        var hbServerOk = $scope.partner.serverHeartbeatPeriod > 0;

        if ($scope.partner.clientMustBeConnected && !hbClientOk) {
            $scope.incorrectHeartbeatValue = true;
            return true;
        }

        if ($scope.partner.serverMustBeConnected && !hbServerOk) {
            $scope.incorrectHeartbeatValue = true;
            return true;
        }

        return false;
    };

    $scope.remove = function (obj, index) {
        delete obj[index];
        return obj.filter(function (n) {
            return n !== undefined;
        });
    };

    $scope.addElement = function (obj) {
        if (obj === null) {
            obj = [];
        }

        obj.push({id: null});

        return obj;
    };

    $scope.searchField = function (fields, groupName, fieldName) {
        var field;

        $scope.fields.forEach(function onEachKey(value) {
            if (value.name === groupName) {
                value.value.forEach(function onEachKey(subvalue) {
                    if (subvalue.value === fieldName) {
                        field = subvalue;
                    }
                });
            }
        });

        return field;
    };

    $scope.searchInnerField = function (fields, groupName, fieldName) {
        var field;

        $scope.fields.forEach(function onEachKey(value) {
            value.value.forEach(function onEachKey(subvalue) {
                if (subvalue.name === groupName) {
                    subvalue.value.forEach(function onEachKey(innersubvalue) {
                        if (innersubvalue.value === fieldName) {
                            field = innersubvalue;
                        }
                    });
                }
            });
        });

        return field;
    };

    $scope.subelementsConstraintList = function (items, field) {
        if (
            (typeof field['constraint_list'] !== 'undefined') && (field['constraint_list'] !== null) &&
            (typeof field['constraint_value'] !== 'undefined') && (field['constraint_value'] !== null) &&
            (typeof field['subfields'] !== 'undefined') && (field['subfields'] !== null)
        ) {
            var constraintList = field['constraint_list'];
            var constraintValue = field['constraint_value'];
            var fieldList = field['subfields'];
            var constraintListToAdd = constraintList.slice();
            var newItems = [];

            // Filtrage des items existants
            if ((typeof items !== 'undefined') && (items !== null)) {
                for (var i = 0; i < items.length; i++) {
                    if (constraintList.indexOf(items[i][constraintValue]) !== -1) {
                        newItems.push(items[i]);
                        constraintListToAdd.splice(constraintListToAdd.indexOf(items[i][constraintValue]), 1);
                    } else if (items[i] !== '') {
                        items[i].hidden = true;
                        newItems.push(items[i]);
                    }
                }
            }

            // Ajout des items manquants
            var handleMissingItem = function (value) {
                if (value.value === constraintValue) {
                    newObject[value.value] = constraintListToAdd[j];
                } else if ((typeof value['default_value'] !== 'undefined') && (value['default_value'] !== null)) {
                    newObject[value.value] = value['default_value'];
                } else {
                    newObject[value.value] = '';
                }
            };

            for (var j = 0; j < constraintListToAdd.length; j++) {
                var newObject = {};
                newObject['id'] = '';
                fieldList.forEach(handleMissingItem);
                newItems.push(newObject);
            }

            newItems.sort(function (a, b) {
                if (a[constraintValue] < b[constraintValue]) {
                    return -1;
                } else if (a[constraintValue] > b[constraintValue]) {
                    return 1;
                } else {
                    return 0;
                }
            });

            return newItems;
        }
    };

    $scope.filterSubelementsByConstraintList = function (items, field) {
        if (
            (typeof field['constraint_list'] !== 'undefined') && (field['constraint_list'] !== null) &&
            (typeof field['constraint_value'] !== 'undefined') && (field['constraint_value'] !== null) &&
            (typeof field['subfields'] !== 'undefined') && (field['subfields'] !== null)
        ) {
            var constraintList = field['constraint_list'];
            var constraintValue = field['constraint_value'];
            var fieldList = field['subfields'];
            var constraintListToAdd = constraintList.slice();
            var newItems = [];

            // Filtrage des items existants
            if ((typeof items !== 'undefined') && (items !== null)) {
                for (var i = 0; i < items.length; i++) {
                    if (constraintList.indexOf(items[i][constraintValue]) !== -1) {
                        newItems.push(items[i]);
                        constraintListToAdd.splice(constraintListToAdd.indexOf(items[i][constraintValue]), 1);
                    }
                }
            }

            // Ajout des items manquants
            var handleMissingItem = function (value) {
                if (value.value === constraintValue) {
                    newObject[value.value] = constraintListToAdd[j];
                } else if ((typeof value['default_value'] !== 'undefined') && (value['default_value'] !== null)) {
                    newObject[value.value] = value['default_value'];
                } else {
                    newObject[value.value] = '';
                }
            };

            for (var j = 0; j < constraintListToAdd.length; j++) {
                var newObject = {};
                newObject['id'] = '';
                fieldList.forEach(handleMissingItem);
                newItems.push(newObject);
            }

            newItems.sort(function (a, b) {
                if (a[constraintValue] < b[constraintValue]) {
                    return -1;
                } else if (a[constraintValue] > b[constraintValue]) {
                    return 1;
                } else {
                    return 0;
                }
            });

            return newItems;
        }
    };


    $scope.categorizeSubelements = function (elements) {
        if (!elements || elements.length === 0) {
            return [];
        }

        var categories = ['URL_CATEGORY_BOOKING', 'URL_CATEGORY_ROAMING', 'URL_CATEGORY_SMARTCHARGE', 'URL_CATEGORY_OTHERS'];
        var defaultCategory = 'URL_CATEGORY_OTHERS';
        var mapping = {
            'URL_CATEGORY_BOOKING': ['GetBookingOnPools', 'SetBookingAccept', 'SetBookingAbort', 'SetBookingCancellation', 'SetBookingEventReport', 'SetBookingActionRequest', 'SetBookingChargingPoolOrEVSE'],
            'URL_CATEGORY_ROAMING': ['SetServiceAuthorisation', 'GetServiceAuthorisation', 'SetChargeDetailRecord', 'SetExtendedChargeDetailRecord', 'SetSessionActionRequest', 'SetSessionEventReport'],
            'URL_CATEGORY_SMARTCHARGE': ['GetEVChargingNeedFromeMSP']
        };

        var categorizedElements = {};

        for (var i = 0; i < elements.length; i++) {
            var elementCategory = null;

            if (elements[i].method) {
                // If the element has a 'method' field, check the mapping and try
                // to retrieve its category
                for (var category in mapping) {
                    if (mapping[category].indexOf(elements[i].method) >= 0) {
                        elementCategory = category;
                        break;
                    }
                }
            }

            // If we didn't find a category for this element, put it in the default one
            if (!elementCategory) {
                elementCategory = defaultCategory;
            }

            // Check if we already have this category in the result object
            if (!categorizedElements[elementCategory]) {
                categorizedElements[elementCategory] = [];
            }

            // Add the element to the category
            categorizedElements[elementCategory].push(elements[i]);
        }

        // Build final result : [{name: <name>, elements: <elements>}, ...]
        var result = [];
        for (var j = 0; j < categories.length; j++) {
            if (categorizedElements[categories[j]] && categorizedElements[categories[j]].length > 0) {
                result.push({
                    name: categories[j],
                    elements: categorizedElements[categories[j]]
                });
            }
        }

        // Return final result
        return result;
    };

    $scope.uncategorizeSubelements = function (elements) {
        if (!elements || elements.length === 0) {
            return [];
        }

        var result = elements
            .map(function (category) {
                return category.elements || [];
            })
            .reduce(function (previousValue, currentValue) {
                return previousValue.concat(currentValue);
            }, [])
        ;

        return result;
    };

    $scope.checkProtocol = function (protocol) {
        var partner = $scope.partner;
        return typeof (partner.preferredProtocolList) !== 'undefined' && partner.preferredProtocolList.indexOf(protocol) !== -1;
    };

}
PartnerEditController.$inject = ['$scope', '$http', '$stateParams', 'Partners', '$modal', '$location', 'Identity'];

module.exports = PartnerEditController;
