'use strict';

var storageWrapper = require('../utils/storage-wrapper');

function AppStorage(appKey, appVersion) {
  // Interface

  var service = {
    destroy: destroy,
    has: has,
    get: get,
    set: set,
    setExpiry: setExpiry
  };

  // Constants

  var DEFAULT_EXPIRY = (24 * 60 * 60 * 1000); // 1 day

  // Variables

  var storage = storageWrapper(window.localStorage);
  var cache = null;
  var now = Date.now();
  var appExpiry = now + DEFAULT_EXPIRY;

  // Implementation

  function lazyload() {
    if (null === cache) {
      var stored = storage.get(appKey);
      cache = isValid(stored) ? stored : getDefaultCache();
      storage.set(appKey, cache);
    }
  }

  function isValid(stored) {
    var validity = false;
    if (stored && stored.data) {
      validity = !stored.expiry || (stored.expiry && stored.expiry > now);
    }
    return validity;
  }

  function getDefaultCache() {
    return {
      version: appVersion,
      expiry: appExpiry,
      data: {}
    };
  }

  function setExpiry(expiry) {
    lazyload();
    // Update application expiry
    appExpiry = expiry;
    cache.expiry = appExpiry;
    // Persist cache
    storage.set(appKey, cache);
  }

  function destroy() {
    cache = getDefaultCache();
    storage.set(appKey, cache);
  }

  function has(key) {
    return null !== get(key);
  }

  function get(key) {
    // Lazyload storage
    lazyload();
    // Get value from cache
    var value = cache.data[key];
    return ('undefined' === typeof value) ? null : value;
  }

  function set(key, value) {
    // Lazyload storage
    lazyload();
    // Set cache in cache and persist
    cache.data[key] = value;
    storage.set(appKey, cache);
  }

  // Exports
  return service;
}

function AppStorageFactory() {
  return AppStorage;
}

module.exports = AppStorageFactory;
