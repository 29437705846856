'use strict';

/**
 * @ngInject
 */
function LoginController($state, ApiAuthenticate, Identity, $location) {
  var vm = this;
  vm.login = login;

  function login() {
    ApiAuthenticate.login({
      password: vm.password,
      username: vm.username
    }).then(
      function onLoginWithSuccess() {
          let postLogInRoute = Identity.getPostLogInRoute();
          if (postLogInRoute === undefined || postLogInRoute === null) {
              $state.go('admin.alarms');
          }
          else{
              $location.path(postLogInRoute);
              Identity.setPostLogInRoute(null);
          }
      },
      function onLoginWithError() {
        vm.errorLogin = true;
        console.log('ERROR_LOGIN');
      }
    );
  }
}
LoginController.$inject = ['$state', 'ApiAuthenticate', 'Identity', '$location'];

module.exports = LoginController;
