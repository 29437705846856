'use strict';

/**
 * @ngInject
 */
function FilteringDduEvcisService($resource, ApiConstants, TransformJson) {
  return $resource(ApiConstants.backendApiUrl + '/filtering_ddu_evci', {}, {
    query: {
      method: 'GET',
      transformResponse: function (data) { return TransformJson.transformResponse(data); },
      isArray: true
    },
    getAll: {
      method: 'GET',
      isArray: true
    },
    create: {
      url: ApiConstants.backendApiUrl + '/filtering_ddu_evci',
      method: 'POST',
      transformRequest: function (data) { return TransformJson.transformRequest(data); }
    },
    get: {
      url: ApiConstants.backendApiUrl + '/filtering_ddu_evci/:id',
      method: 'GET',
      transformResponse: function(data){ return JSON.parse(data); },
      isArray: true
    },
    delete: {
      url: ApiConstants.backendApiUrl + '/filtering_ddu_evci/:id',
      method: 'DELETE'
    }
  });
}
FilteringDduEvcisService.$inject = ['$resource', 'ApiConstants', 'TransformJson'];

module.exports = FilteringDduEvcisService;
