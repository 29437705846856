'use strict';

/**
 * @ngInject
 */
function ExtractsService($resource, ApiConstants, TransformJson) {
  return $resource(ApiConstants.backendApiUrl + '/extracts', {}, {
    listScd: {
      url: ApiConstants.backendApiUrl + '/extracts/scdlist',
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      isArray: true
    },
    downloadScd: {
      url: ApiConstants.backendApiUrl + '/extracts/scddownload',
      method: 'GET',
      isArray: true
    },
    downloadBooking: {
      url: ApiConstants.backendApiUrl + '/extracts/bookingdownload',
      method: 'GET',
      isArray: true
    },
    downloadUnknownElements: {
      url: ApiConstants.backendApiUrl + '/extracts/unknownelementsdownload',
      method: 'GET',
      isArray: true
    },
    downloadSolicitation: {
      url: ApiConstants.backendApiUrl + '/extracts/solicitationdownload',
      method: 'GET',
      isArray: true
    }
  });
}
ExtractsService.$inject = ['$resource', 'ApiConstants', 'TransformJson'];

module.exports = ExtractsService;
