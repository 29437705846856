'use strict';

/**
 * @ngInject
 */
function OperatorListController($scope, Operators, $modal, ApiConstants, Identity) {
  $scope.refresh = function(){
    if ($scope.currentRole === 'ALL') {
      $scope.operators = Operators.findAll();
    } else {
      $scope.operators = Operators.findAllByRole({ id: $scope.currentRole });
    }
  };

  $scope.refresh();

  $scope.fields = [
    {
      'value': 'gireveId',
      'name': 'GIREVE_ID'
    },
    {
      'value': 'emi3Id',
      'name': 'EMI3ID'
    },
    {
      'value': 'name',
      'name': 'NAME'
    },
    {
      'value': 'comment',
      'name': 'COMMENTS'
    }
  ];

  $scope.isRoleActive = function(role) {
    return $scope.currentRole === role;
  };

  $scope.setCurrentRole = function(role) {
    $scope.currentRole = role;
    $scope.refresh();
  };

  $scope.deleteOperator = function(id) {
    Operators.delete(
      { id: id },
      function onDeleteSuccess() {
        $scope.refresh();
      },
      function onDeleteError(error) {
        $modal.open({
          templateUrl: 'modalError.html',
          controller: 'ModalErrorInstanceController',
          resolve: {
            errorData: function () {
              return error;
            }
          }
        });
      }
    );
  };

  $scope.export = function(){
    var url = ApiConstants.backendApiUrl + '/operators/download';

    if (Identity.hasToken() && Identity.getUsername()) {
      url += '?JSESSIONID=' + Identity.getToken();
    }

    window.open(url);
  };

  var idDelete;
  $scope.delete = function (id) {
    idDelete = id;

    var modalInstance = $modal.open({
      templateUrl: 'modalConfirmDelete.html',
      controller: 'ModalConfirmInstanceController'
    });

    modalInstance.result.then(
      function onModalConfirm() { $scope.deleteOperator(idDelete); },
      function onModalCancel() { }
    );
  };
}
OperatorListController.$inject = ['$scope', 'Operators', '$modal', 'ApiConstants', 'Identity'];

module.exports = OperatorListController;
