'use strict';

/**
 * @ngInject
 */
function ModalConfirmInstanceController($scope, $modalInstance) {
  $scope.ok = function () {
    $modalInstance.close();
  };

  $scope.cancel = function () {
    $modalInstance.dismiss('cancel');
  };
}
ModalConfirmInstanceController.$inject = ['$scope', '$modalInstance'];

module.exports = ModalConfirmInstanceController;
