'use strict';

/**
 * @ngInject
 */
function WhiteListListController($scope, $location, WhiteLists, $http, ApiConstants, $modal, Identity) {
    $scope.pageSizeList = [
        {value: 50},
        {value: 100},
        {value: 500}
    ];

    $scope.searchData = {
        'page': 1,
        'page_size': {
            'value': 50
        },
        'order': {
            'field': 'id',
            'type': 'asc'
        }
    };

    $scope.fields = [
        {
            'value': 'id',
            'name': 'ID'
        },
        {
            'value': 'operatorIdType',
            'name': 'OPERATORIDTYPE'
        },
        {
            'value': 'operatorId',
            'name': 'OPERATORID'
        },
        {
            'value': 'userIdType',
            'name': 'USERIDTYPE'
        },
        {
            'value': 'userId',
            'name': 'USERID'
        },
        {
            'value': 'authorizedServiceId',
            'name': 'AUTHORISEDSERVICEID'
        },
        {
            'value': 'expiryDate',
            'name': 'EXPIRYDATE'
        }
    ];

    $scope.isLoading = false;

    $scope.doSearch = function (query) {
        $scope.isLoading = true;

        $http.post(ApiConstants.backendApiUrl + '/whitelist', query).then(
            function onPostSuccess(success) {
                var data = success.data;
                $scope.isLoading = false;
                $scope.whitelists = data.whitelist;
                $scope.currentPage = data.current_page;
                $scope.maxPage = data.max_page;

                // $scope.moreTraces = data.length > 0;
            },
            function onPostError(error) {
                // called asynchronously if an error occurs
                // or server returns response with an error status.
                $scope.isLoading = false;
                $modal.open({
                    templateUrl: 'modalError.html',
                    controller: 'ModalErrorInstanceController',
                    resolve: {
                        errorData: function () {
                            return 'An error occurs. Please contact administrator.';
                        }
                    }
                });
            }
        );
    };

    $scope.doSearch($scope.searchData);

    $scope.setPage = function (page) {
        if ((page >= 1) && (page <= $scope.maxPage)) {
            $scope.searchData.page = page;
            $scope.search();
        }
    };

    $scope.pageUp = function () {
        $scope.setPage($scope.currentPage + 1);
    };

    $scope.pageDown = function () {
        $scope.setPage($scope.currentPage - 1);
    };

    $scope.sort = function (field) {
        // field.value == searchData.order.field && searchData.order.type == 'asc'

        if (($scope.searchData.order.field === field) && ($scope.searchData.order.type === 'asc')) {
            $scope.searchData.order.type = 'desc';
        } else {
            $scope.searchData.order.field = field;
            $scope.searchData.order.type = 'asc';
        }

        $scope.search();
    };

    $scope.search = function () {
        $scope.doSearch($scope.searchData);
    };

    $scope.deleteEntry = function (id) {
        WhiteLists.delete({id: id}, function onDeleteSuccess() {
            $scope.search();
        });
    };

    var idDelete;
    $scope.delete = function (id) {
        idDelete = id;

        var modalInstance = $modal.open({
            templateUrl: 'modalConfirmDelete.html',
            controller: 'ModalConfirmInstanceController'
        });

        modalInstance.result.then(
            function onModalConfirm() {
                $scope.deleteEntry(idDelete);
            },
            function onModalCancel() {
            }
        );
    };

    $scope.export = function () {
        var url = ApiConstants.backendApiUrl + '/whitelist/download';
        var firstParam = true;
        var sep;

        if (Identity.hasToken() && Identity.getUsername()) {
            sep = firstParam ? '?' : '&';
            firstParam = false;
            url += sep + 'JSESSIONID=' + Identity.getToken();
        }

        if ((typeof $scope.searchData.operatorId !== 'undefined') && ($scope.searchData.operatorId !== null)) {
            sep = firstParam ? '?' : '&';
            firstParam = false;
            url += sep + 'operatorId=' + $scope.searchData.operatorId;
        }

        if ((typeof $scope.searchData.userId !== 'undefined') && ($scope.searchData.userId !== null)) {
            sep = firstParam ? '?' : '&';
            firstParam = false;
            url += sep + 'userId=' + $scope.searchData.userId;
        }

        if ((typeof $scope.searchData.userContractIdAlias !== 'undefined') && ($scope.searchData.userContractIdAlias !== null)) {
            sep = firstParam ? '?' : '&';
            url += sep + 'userContractIdAlias=' + $scope.searchData.userContractIdAlias;
        }

        window.open(url);
    };

    $scope.display = function (whitelist) {
        var modalInstance = $modal.open({
            templateUrl: 'modalWhiteListDetail.html',
            controller: 'ModalWhiteListDetailInstanceController',
            resolve: {
                whiteList: function () {
                    return whitelist;
                }
            }
        });
    };
}
WhiteListListController.$inject = ['$scope', '$location', 'WhiteLists', '$http', 'ApiConstants', '$modal', 'Identity'];

module.exports = WhiteListListController;
