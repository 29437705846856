'use strict';

/**
 * @ngInject
 */
function ModalEvseCacheDetailInstanceController($scope, $modalInstance, ApiConstants, $http, $modal, evseCache) {
  
  $scope.evseCache = evseCache;

  $scope.ok = function () {
    $modalInstance.close();
  };

  
}
ModalEvseCacheDetailInstanceController.$inject = ['$scope', '$modalInstance', 'ApiConstants', '$http', '$modal', 'evseCache'];

module.exports = ModalEvseCacheDetailInstanceController;
