'use strict';

module.exports = angular
  .module('bo-web.ui', [
    'ngSanitize',
    'ui.router',
    'ui.bootstrap',
    'ui.select',
    // 'ui.bootstrap.datetimepicker',
    'bo-web.common',
    'bo-web.data',
    'bo-web.templates',
    'pascalprecht.translate',
    'tableSort',
    'mgcrea.ngStrap.datepicker',
    'mgcrea.ngStrap.timepicker',
    'mgcrea.ngStrap.select',
    'ngLocale',
    'tmh.dynamicLocale'
    // 'mgcrea.ngStrap'
  ])
  .config(require('./config/route'))
  .config(['$translateProvider', 'tmhDynamicLocaleProvider',function($translateProvider, tmhDynamicLocaleProvider) {
    $translateProvider.preferredLanguage('fr');
    tmhDynamicLocaleProvider.localeLocationPattern('./i18n/angular-locale_{{locale}}.js');
    $translateProvider.useStaticFilesLoader({
      prefix: 'languages/',
      suffix: '.json'
    });
  }])
  .config(['$datepickerProvider', function($datepickerProvider) {
    angular.extend($datepickerProvider.defaults, {
      dateFormat: 'dd/MM/yyyy',
      // delay: { show: 100, hide: 500000 },
      startWeek: 1
      // autoclose: true,
    });
  }])
  .config(['$timepickerProvider', function($timepickerProvider) {
    angular.extend($timepickerProvider.defaults, {
      timeFormat: 'HH:mm',
      // delay: { show: 100, hide: 500 },
      minuteStep: 1
    });
  }])
  .filter('formatTime', function() {
    return function(value) {
      if(value){
        return value+' ms';
      } else {
       return '';
      }
    };
  })
   .filter('capitalize', function() {
    return function(input) {
        return (angular.isString(input) && input.length > 0) ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase() : input;
    };
  })
  .controller('IndexController', require('./controllers/index'))
  .controller('LoginController', require('./controllers/login'))
  .controller('PartnerListController', require('./controllers/partner_list'))
  .controller('PartnerViewController', require('./controllers/partner_view'))
  .controller('PartnerEditController', require('./controllers/partner_edit'))
  .controller('PartnerCreateController', require('./controllers/partner_create'))
  .controller('PartnerListMonitorController', require('./controllers/partner_list_monitor'))
  .controller('PartnerMonitorController', require('./controllers/partner_monitor'))
  .controller('OperatorListController', require('./controllers/operator_list'))
  .controller('OperatorViewController', require('./controllers/operator_view'))
  .controller('OperatorEditController', require('./controllers/operator_edit'))
  .controller('OperatorCreateController', require('./controllers/operator_create'))
  .controller('ContractListController', require('./controllers/contract_list'))
  .controller('ContractViewController', require('./controllers/contract_view'))
  .controller('ContractEditController', require('./controllers/contract_edit'))
  .controller('ContractCreateController', require('./controllers/contract_create'))
  .controller('ContractImportController', require('./controllers/contract_import'))
  .controller('DataContractListController', require('./controllers/datacontract_list'))
  .controller('DataContractViewController', require('./controllers/datacontract_view'))
  .controller('DataContractEditController', require('./controllers/datacontract_edit'))
  .controller('DataContractCreateController', require('./controllers/datacontract_create'))
  .controller('ModalConfirmInstanceController', require('./controllers/modal_confirm'))
  .controller('ModalConfirmAlreadyInContractInstanceController', require('./controllers/modal_confirm_already_in_contract'))
  .controller('ModalErrorInstanceController', require('./controllers/modal_error'))
  .controller('ModalErrorInvalidFormInstanceController', require('./controllers/modal_error_invalid_form'))
  .controller('ModalPartnerErrorsInstanceController', require('./controllers/modal_partner_errors'))
  .controller('ModalConfirmRetryCheckAndBillController', require('./controllers/modal_confirm_retry_checkandbill'))
  .controller('ModalRefreshOcpiLocationOperatorController', require('./controllers/modal_refresh_ocpi_location_operator'))
  .controller('ModalRefreshOcpiTariffOperatorController', require('./controllers/modal_refresh_ocpi_tariff_operator'))
  .controller('ModalWaitingForCptidController', require('./controllers/modal_waiting_for_cptid'))
  .controller('EventListController', require('./controllers/event_list'))
  .controller('AlarmListController', require('./controllers/alarm_list'))
  .controller('RoamingMatrixController', require('./controllers/roaming_matrix'))
  .controller('RecordListController', require('./controllers/record_list'))
  .controller('DiagnoseController', require('./controllers/diagnose'))
  .controller('ExportCdrController', require('./controllers/exportcdr'))
  .controller('ExportBookingController', require('./controllers/exportbooking'))
  .controller('ExportSolicitationController', require('./controllers/exportsolicitation'))
  .controller('ExportUnknownElementsController', require('./controllers/exportunknownelements'))
  .controller('ExportActionEventController', require('./controllers/exportactionevent'))
  .controller('EvseCacheListController', require('./controllers/evsecache_list'))
  .controller('ModalEvseCacheDetailInstanceController', require('./controllers/modal_evse_cache_detail'))
  .controller('UserCacheListController', require('./controllers/usercache_list'))
  .controller('ModalUserCacheDetailInstanceController', require('./controllers/modal_user_cache_detail'))
  .controller('WhiteListListController', require('./controllers/whitelist_list'))
  .controller('ModalWhiteListDetailInstanceController', require('./controllers/modal_whitelist_detail'))
  .controller('ChargingPoolCacheListController', require('./controllers/chargingpoolcache_list'))
  .controller('ModalChargingPoolCacheDetailInstanceController', require('./controllers/modal_filteringdduevci_detail'))
  .controller('ModalConfirmRegisterOcpiInstanceController', require('./controllers/modal_confirm_register_ocpi'))
  .controller('ModalInactiveContractInstanceController', require('./controllers/modal_inactive_contract'))
  .controller('ModalConfirmActivatePushLocationsInstanceController', require('./controllers/modal_confirm_activate_push_locations'))
  .controller('WhiteListSyntheticBoardController', require('./controllers/whitelist_synthetic_board'))
  .controller('RequestGeneratorController', require('./controllers/request_generator'))
  .controller('RetryCheckAndBillController', require('./controllers/retry_checkandbill'))
  .controller('QueueManagementController', require('./controllers/queue_management'))
  .controller('FilteringDduEvciController', require('./controllers/filteringdduevci'))
  .controller('FilteringDduEvciCreateController', require('./controllers/filteringdduevci_create'))
  .controller('ModalFilteringDduEvciDetailInstanceController', require('./controllers/modal_filteringdduevci_detail'))
  .controller('ForwardCdrController', require('./controllers/forwardcdr'))
  .controller('ModalConfirmForwardCdrInstanceController', require('./controllers/modal_confirm_forward_cdr'))
  .controller('ModalForwardCdrResponseController', require('./controllers/modal_forward_cdr_response'))
  .controller('ImportInvoicingDataController', require('./controllers/importinvoicingdata'))
  .controller('ModalConfirmImportInvoicingDataInstanceController', require('./controllers/modal_confirm_import_invoicing_data'))
  .controller('RejeuSolicitationController', require('./controllers/rejeu_solicitation'))
  .controller('MainCtrl', require('./controllers/main_ctrl'))
  .directive('iopVersion', require('./directives/iop_version'))
  .directive('applyHighlight', require('./directives/applyHighlight'))
  .directive('fileChange', require('./directives/fileChange'))
;
