'use strict';

/**
 * @ngInject
 */
function OperatorsService($resource, ApiConstants, TransformJson) {
  return $resource(ApiConstants.authUrl + ApiConstants.endpointUrl + '/operator/', {id: '@id'}, {
    query: {
      method: 'GET',
      transformResponse: function (data) {
        return TransformJson.transformResponse(data, 'operator');
      },
      isArray: true
    },
    findAll: {
      url: ApiConstants.backendApiUrl + '/operators',
      method: 'GET',
      transformResponse: function (data) { return JSON.parse(data); },
      isArray: true

    },
    findAllByRole:{
      url: ApiConstants.backendApiUrl + '/operators?role=:id',
      method: 'GET',
      transformResponse: function (data) { return JSON.parse(data); },
      isArray: true
    },
    queryByRole: {
      url: ApiConstants.authUrl + ApiConstants.endpointUrl + '/operatorbyrole/:id',
      method: 'GET',
      transformResponse: function (data) {
        var op = TransformJson.transformResponse(data, 'operator');
      },
      isArray: true
    },
    queryByRoleSimple: {
      url: ApiConstants.backendApiUrl + '/operators?role=:id',
      method: 'GET',
      transformResponse: function (data) {
        return TransformJson.transformResponse(data, 'operator').map(function (ope) {
          return {id: ope.id, emi3id: ope.emi3Id, name: ope.name};
        });
      },
      isArray: true
    },
    get: {
      url: ApiConstants.backendApiUrl + '/operators/:id',
      method: 'GET',
      transformResponse: function (data) {
        var operator = JSON.parse(data);
        var checkServiceSubscription = operator.checkServiceSubscription;
        var billServiceSubscription = operator.billServiceSubscription;
        operator.checkServiceSubscription = !!checkServiceSubscription;
        operator.billServiceSubscription = !!billServiceSubscription;
        return operator;
      },
      isArray: false
    },
    update: {
      url: ApiConstants.backendApiUrl + '/operators/:id',
      method: 'PUT',
      transformRequest: function (data) {
        return TransformJson.transformRequest(data);
      }
    },
    create: {
      url: ApiConstants.backendApiUrl + '/operators',
      method: 'POST',
      transformRequest: function (data) {
        return TransformJson.transformRequest(data);
      }
    },
    delete: {
      url: ApiConstants.backendApiUrl + '/operators/:id',
      method: 'DELETE'
    }
  });
}
OperatorsService.$inject = ['$resource', 'ApiConstants', 'TransformJson'];

module.exports = OperatorsService;
