'use strict';

/**
 * @ngInject
 */
function ModalErrorInstanceController($scope, $modalInstance, errorData) {
  $scope.ok = function () {
    $modalInstance.close();
  };

  $scope.cancel = function () {
    $modalInstance.dismiss('cancel');
  };

  console.log(errorData);
  $scope.errorCode = 'UNKNOWN_ERROR';
  if (
    ((typeof errorData.data !== 'undefined') && (errorData.data !== null)) &&
    ((typeof errorData.data.Fault !== 'undefined') && (errorData.data.Fault !== null)) &&
    ((typeof errorData.data.Fault.detail !== 'undefined') && (errorData.data.Fault.detail !== null)) &&
    ((typeof errorData.data.Fault.detail.DataServiceFault !== 'undefined') && (errorData.data.Fault.detail.DataServiceFault !== null)) &&
    ((typeof errorData.data.Fault.detail.DataServiceFault.ds_code !== 'undefined') && (errorData.data.Fault.detail.DataServiceFault.ds_code !== null))
  ) {
    $scope.errorCode = '' + errorData.data.Fault.detail.DataServiceFault.ds_code;
  } else if (((typeof errorData.data !== 'undefined') && (errorData.data !== null)) &&
    ((typeof errorData.data.status !== 'undefined') && (errorData.data.status !== null))) {
    $scope.errorCode = '' + errorData.data.status;
  } else if ((errorData.status !== 'undefined') && (errorData.status !== null)) {
    $scope.errorCode = '' + errorData.status;
  }

  $scope.errorMessage = 'Unknown Error';
  if (
    ((typeof errorData.data !== 'undefined') && (errorData.data !== null)) &&
    ((typeof errorData.data.Fault !== 'undefined') && (errorData.data.Fault !== null)) &&
    ((typeof errorData.data.Fault.detail !== 'undefined') && (errorData.data.Fault.detail !== null)) &&
    ((typeof errorData.data.Fault.detail.DataServiceFault !== 'undefined') && (errorData.data.Fault.detail.DataServiceFault !== null)) &&
    ((typeof errorData.data.Fault.detail.DataServiceFault.nested_exception !== 'undefined') && (errorData.data.Fault.detail.DataServiceFault.nested_exception !== null))
  ) {
    $scope.errorMessage = errorData.data.Fault.detail.DataServiceFault.nested_exception;
  } else if (((typeof errorData.data !== 'undefined') && (errorData.data !== null)) &&
    ((typeof errorData.data.message !== 'undefined') && (errorData.data.message !== null))) {
    $scope.errorMessage = errorData.data.message;
  } else if ((errorData.statusText !== 'undefined') && (errorData.statusText !== null)) {
    $scope.errorMessage = errorData.statusText;
  }

  if ('DATABASE_ERROR' === $scope.errorCode) {
    if ($scope.errorMessage.indexOf('uix_partner_emi3id') > -1) {
      $scope.errorCode = 'UNIQUE_VALUE_ERROR';
      $scope.errorMessage = 'PARTNER_ALREADY_EXISTS_WITH_EMI3ID';
    } else if ($scope.errorMessage.indexOf('uix_operator_emi3id') > -1) {
      $scope.errorCode = 'UNIQUE_VALUE_ERROR';
      $scope.errorMessage = 'OPERATOR_ALREADY_EXISTS_WITH_EMI3ID';
    }
  }
}
ModalErrorInstanceController.$inject = ['$scope', '$modalInstance', 'errorData'];

module.exports = ModalErrorInstanceController;
