'use strict';

/**
 * @ngInject
 */
function ExportSolicitationController($scope, $location, $modal, Extracts, $http, ApiConstants, Identity) {
      $scope.startDate = new moment().subtract(1, 'months');
      $scope.endDate = null;
      $scope.recoveryDate = new moment().subtract(1, 'months');
      $scope.result = null;

      $scope.export = function(){
        if ($scope.startDate) {
          var url = ApiConstants.backendApiUrl + '/extracts/solicitationdownload';
          var firstParam = true;
          var sep;

          if (Identity.hasToken() && Identity.getUsername()) {
            sep = firstParam ? '?':'&';
            firstParam = false;
            url += sep + 'JSESSIONID=' + Identity.getToken();
          }

          sep = firstParam ? '?':'&';

          url += sep + 'startDate=' + $scope.startDate.toJSON();

          if ($scope.endDate) {
            url += '&endDate=' + $scope.endDate.toJSON();
          }

          window.open(url);
        }
      };

      $scope.recover = function(){
          var url = ApiConstants.backendApiUrl + '/solicitation_rejeu?recoverDate=' + $scope.recoveryDate.toJSON();
          $http.get(url).then(
              function onGetSuccess(success) {
                  $scope.result = success.data;
                  },
              function onGetError(error) {
                    $scope.result = error.data;
              });
      };
}
ExportSolicitationController.$inject = ['$scope', '$location', '$modal', 'Extracts', '$http', 'ApiConstants', 'Identity'];

module.exports = ExportSolicitationController;
