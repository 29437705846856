'use strict';

/**
 * @ngInject
 */
function Identity(AppStorage) {
  // Constantes

  var APP_STORAGE_KEY = 'gireve-iop:storage:app';

  // Variables

  var storage = AppStorage(APP_STORAGE_KEY, '1.0.0', (Date.now() + 30 * 1000));

  // Interface

  var service = {
    destroy: destroy,
    hasToken: hasToken,
    getToken: getToken,
    setToken: setToken,
    getUsername: getUsername,
    setUsername: setUsername,
    setExpiry: setExpiry,
    getPostLogInRoute : getPostLogInRoute,
    setPostLogInRoute : setPostLogInRoute
  };

  return service;

  // Implementation

  function destroy() {
    ['token', 'profil'].forEach(function onEachKey(key) {
      storage.set(key, null);
    });
  }

  function hasToken() {
    return storage.has('token');
  }

  function getToken() {
    return storage.get('token');
  }

  function setToken(token) {
    return storage.set('token', token);
  }

    function getPostLogInRoute() {
        return storage.get('postLogInRoute');
    }

    function setPostLogInRoute(postLogInRoute) {
        return storage.set('postLogInRoute', postLogInRoute);
    }

  function getUsername() {
    return storage.get('username');
  }

  function setUsername(username) {
    return storage.set('username', username);
  }

  function setExpiry(expiry) {
    storage.setExpiry(expiry);
  }
}
Identity.$inject = ['AppStorage'];

module.exports = Identity;
