'use strict';

/**
 * @ngInject
 */
function ModalForwardCdrResponseController($scope, $modalInstance, inputData) {
    var input = inputData;

    $scope.modalTitle = input.modalTitle;
    $scope.isLoading = true;

    input.request.then(
        function onGetSuccess(response) {
            $scope.isLoading = false;
            $scope.onError = false;
            $scope.responseData = response.data;
        },
        function onGetError(error) {
            $scope.isLoading = false;
            $scope.onError = true;
            $scope.responseData = error.data;
        })
    ;

    $scope.ok = function () {
        $modalInstance.close();
    };
}
ModalForwardCdrResponseController.$inject = ['$scope', '$modalInstance', 'inputData'];

module.exports = ModalForwardCdrResponseController;
