'use strict';

/**
 * @ngInject
 */
function DataContractListController($scope, DataContracts, $http, $modal, ApiConstants, Identity) {
  $scope.pageSizeList = [
    { value: 50 },
    { value: 100 },
    { value: 500 }
  ];

  $scope.searchData = {
    'page': 1,
    'page_size': {
      'value': 100
    },
    'order': {
      'field': 'state',
      'type': 'asc'
    }
  };

  $scope.fields = [
    {
      'value': 'datacontractid',
      'name': 'CONTRACT_ID'
    },
    {
      'value': 'operatorName',
      'name': 'OPERATOR'
    },
    {
      'value': 'evsedatarequestgireveid',
      'name': 'EVSE_DATA_REQUEST'
    },
    {
      'value': 'datacontractname',
      'name': 'NAME'
    },
    {
      'value': 'validFrom',
      'name': 'VALID_FROM'
    },
    {
      'value': 'validUntil',
      'name': 'VALID_TO'
    },
    {
      'value': 'state',
      'name': 'STATUS'
    }
  ];

  $scope.services = [
    { id: 'csf', label: 'CSF' },
    { id: 'ddd', label: 'DDD' },
    { id: 'sdd', label: 'SDD' }
  ];

  //While this variable > 0, we display the loading spinner
  $scope.isLoading = 0;

  $scope.deleteContract = function (id) {
    DataContracts.delete({ id: id }, function onDeleteSuccess() {
      $scope.search();
    });
  };

  $scope.toggleContractStatus = function (id) {
    // Retrieve contract matching the given ID
    var contracts = $scope.contracts || [];
    var contract = contracts.filter(function (c) {
      return c.id === id;
    })[0];

    if (!contract) {
      return;
    }

    var oldAdministrativeStatus = contract.administrativeStatus ? contract.administrativeStatus.toUpperCase() : 'INACTIVE';
    var newAdministrativeStatus = (oldAdministrativeStatus === 'ACTIVE') ? 'INACTIVE' : 'ACTIVE';

    // Don't update if the contract is inactive.
    var now = new Date();
    var validFrom = contract.validFrom ? new Date(contract.validFrom) : null;
    var validUntil = contract.validUntil ? new Date(contract.validUntil) : null;

    if ((validFrom && validFrom > now) || (validUntil && validUntil < now)) {
      $modal.open({
        templateUrl: 'modalInactiveContract.html',
        controller: 'ModalInactiveContractInstanceController',
        resolve: {
          contract: function () { return contract; }
        }
      });

      return;
    }

    // Send update request to the server
    $http.patch(
      ApiConstants.baseHref + ApiConstants.backendApiUrl + '/dataContracts/' + contract.id,
      { administrativeStatus: newAdministrativeStatus }
    ).then(
      function onUpdateSuccess(response) {
        // Update contract
        var newContract = response.data;
        if (!newContract || !newContract.id) {
          return;
        }

        $scope.contracts = $scope.contracts.map(function (oldContract) {
          if (oldContract.id === newContract.id) {
            return newContract;
          }

          return oldContract;
        });
      },
      function onUpdateError(error) {
        // Display error modal
        $scope.displayError(error);
      }
    );
  };

  var idDelete;
  $scope.delete = function (id) {
    idDelete = id;

    var modalInstance = $modal.open({
      templateUrl: 'modalConfirmDelete.html',
      controller: 'ModalConfirmInstanceController'
    });

    modalInstance.result.then(
      function onModalConfirm() { $scope.deleteContract(idDelete); },
      function onModalCancel() { }
    );
  };

  $scope.search = function () {
    var query = $scope.searchData;

    //When a request starts, isLoading is incremented
    $scope.isLoading++;

    $http.post(ApiConstants.baseHref + ApiConstants.backendApiUrl + '/dataContracts/search', query).then(
      function onGetSuccess(success) {
        var data = success.data;

        //When a request is done, isLoading is decremented
        $scope.isLoading--;

        $scope.contracts = data.dataContracts;
        $scope.currentPage = data.current_page;
        $scope.maxPage = data.max_page;
      },
      function onGetError(error) {
        //When a request is done, isLoading is decremented
        $scope.isLoading--;

        // Display error modal
        $scope.displayError(error);
      }
    );
  };

  $scope.setPage = function (page) {
    if ((page >= 1) && (page <= $scope.maxPage)) {
      $scope.searchData.page = page;
      $scope.search();
    }
  };

  $scope.pageUp = function () {
    $scope.setPage($scope.currentPage + 1);
  };

  $scope.pageDown = function () {
    $scope.setPage($scope.currentPage - 1);
  };

  $scope.sort = function (field) {
    if (($scope.searchData.order.field === field) && ($scope.searchData.order.type === 'asc')) {
      $scope.searchData.order.type = 'desc';
    } else {
      $scope.searchData.order.field = field;
      $scope.searchData.order.type = 'asc';
    }

    $scope.search();
  };

  $scope.displayError = function (error) {
    // called asynchronously if an error occurs
    // or server returns response with an error status.
    $modal.open({
      templateUrl: 'modalError.html',
      controller: 'ModalErrorInstanceController',
      resolve: {
        errorData: function () {
          return error;
        }
      }
    });
  };

  // Initial search
  $scope.search();
}
DataContractListController.$inject = ['$scope', 'DataContracts', '$http', '$modal', 'ApiConstants', 'Identity'];

module.exports = DataContractListController;
