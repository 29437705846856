'use strict';

/**
 * @ngInject
 */
function PartnerListMonitorController($scope, Partners, $modal, $http, ApiConstants) {
  $scope.isLoading = 0;
  $scope.currentPage = 1;
  $scope.maxPage = 1;

  $scope.pageSizeList = [
    { value: 50 },
    { value: 100 },
    { value: 500 }
  ];

  $scope.searchData = {
    'page': 1,
    'page_size': {
      'value': 50
    },
    'order': {
      'field': 'state',
      'type': 'asc'
    }
  };

  $scope.fields = [
    {
      'value': 'gireveId',
      'name': 'GIREVE_ID'
    },
    {
      'value': 'emi3Id',
      'name': 'EMI3ID'
    },
    {
      'value': 'name',
      'name': 'NAME'
    },
    {
      'value': 'state',
      'name': 'STATE'
    }
  ];

  $scope.search = function(query) {
    query = query || $scope.searchData;

    $scope.isLoading++;

    $http.post(ApiConstants.backendApiUrl + '/partnermonitor', query).then(
      function onPostSuccess(success) {
        var data = success.data;
        console.log(data);
        $scope.partners = data.partnerList;
        $scope.currentPage = data.current_page;
        $scope.maxPage = data.max_page;
      },
      function onPostError(error) {
        // called asynchronously if an error occurs
        // or server returns response with an error status.
        $modal.open({
          templateUrl: 'modalError.html',
          controller: 'ModalErrorInstanceController',
          resolve: {
            errorData: function () {
              return 'An error occurred. Please contact administrator.';
            }
          }
        });
      }
    ).finally(function() {
      $scope.isLoading--;
    });
  };

  $scope.sort = function (field) {
    if (($scope.searchData.order.field === field) && ($scope.searchData.order.type === 'asc')) {
      $scope.searchData.order.type = 'desc';
    } else {
      $scope.searchData.order.field = field;
      $scope.searchData.order.type = 'asc';
    }

    $scope.search();
  };

  $scope.setPage = function (page) {
    if ((page >= 1) && (page <= $scope.maxPage)) {
      $scope.searchData.page = page;
      $scope.search();
    }
  };

  $scope.pageUp = function () {
    $scope.setPage($scope.currentPage + 1);
  };

  $scope.pageDown = function () {
    $scope.setPage($scope.currentPage - 1);
  };

  // Search on initialization
  $scope.search();
}
PartnerListMonitorController.$inject = ['$scope', 'Partners', '$modal', '$http', 'ApiConstants'];

module.exports = PartnerListMonitorController;
