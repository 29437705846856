'use strict';

/**
 * @ngInject
 */
function ContractsService($resource, ApiConstants) {

    var servicesMap = {
        'WhiteList': {'fieldId' : 'endUserServiceAuthDataIdList', 'fieldName' : 'endUserServiceAuthDataNameList'},
        'SDD': {'fieldId' : 'endUserServiceSDDIdList', 'fieldName' : 'endUserServiceSDDNameList'},
        'Roaming': {'fieldId' : 'endUserServiceRoamingIdList', 'fieldName' : 'endUserServiceRoamingNameList'},
        'DuplicationCDR': {'fieldId' : 'endUserServiceDuplicateCDRIdList', 'fieldName' : 'endUserServiceDuplicateCDRNameList'},
        'DDD': {'fieldId' : 'endUserServiceDDDIdList', 'fieldName' : 'endUserServiceDDDNameList'},
        'Data': {'fieldId' : 'endUserServiceDataIdList', 'fieldName' : 'endUserServiceDataNameList'},
        'Booking': {'fieldId' : 'endUserServiceBookingIdList', 'fieldName' : 'endUserServiceBookingNameList'},
        'Tariff': {'fieldId' : 'endUserServiceTariffIdList', 'fieldName' : 'endUserServiceTariffNameList'},
    };

    function transformRequest(data) {
        var requestData = Object.assign({}, data);

        if (!requestData.services) {
            requestData.services = [];
        }

        for (var platform in servicesMap) {
            var service = null;
            for (var i = 0; i < requestData.services.length; i++) {
                if (requestData.services[i].servicePlatform === platform) {
                    service = requestData.services[i];
                }
            }

            if (service === null) {
                service = {
                    servicePlatform: platform,
                    serviceIds: []
                };

                requestData.services.push(service);
            }

            service.serviceIds = requestData[servicesMap[platform].fieldId] || [];
        }

        return JSON.stringify(requestData);
    }

    function transformResponse(data) {
        console.log('Contract response : ' + data);
        var responseData = JSON.parse(data);
        for (var platform in servicesMap) {
            responseData[servicesMap[platform].fieldId] = [];
            responseData[servicesMap[platform].fieldName] = [];
        }

        if (responseData.services) {
            for (var i = 0; i < responseData.services.length; i++) {
                if (responseData.services[i].servicePlatform in servicesMap) {
                    var fieldId = servicesMap[responseData.services[i].servicePlatform].fieldId;
                    var fieldName = servicesMap[responseData.services[i].servicePlatform].fieldName;
                    responseData[fieldId] = responseData.services[i].serviceIds.map(function (elt) {
                        return parseInt(elt, 10);
                    });
                    responseData[fieldName] = responseData.services[i].serviceNames;
                }
            }
        }

        return responseData;
    }

    return $resource(ApiConstants.backendApiUrl + '/contracts/', {id: '@id'}, {
        get: {
            url: ApiConstants.backendApiUrl + '/contracts/:id',
            headers: { 'Content-Type': 'application/json' },
            transformResponse: transformResponse,
        },
        update: {
            url: ApiConstants.backendApiUrl + '/contracts/:id',
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            transformRequest: transformRequest,
            transformResponse: transformResponse,
        },
        create: {
            method: 'POST',
            url: ApiConstants.backendApiUrl + '/contracts',
            transformRequest: transformRequest,
            transformResponse: transformResponse,
        },
        delete: {
            url: ApiConstants.backendApiUrl + '/contracts/:id',
            method: 'DELETE'
        }
    });
}
ContractsService.$inject = ['$resource', 'ApiConstants'];

module.exports = ContractsService;
