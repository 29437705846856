'use strict';

/**
 * @ngInject
 */
function ModalFilteringDduEvciDetailInstanceController($scope, $modalInstance, ApiConstants, $http, $modal, filteredEvci) {

  $scope.filteredEvci = filteredEvci;

  $scope.ok = function () {
    $modalInstance.close();
  };

}
ModalFilteringDduEvciDetailInstanceController.$inject = ['$scope', '$modalInstance', 'ApiConstants', '$http', '$modal', 'filteredEvci'];

module.exports = ModalFilteringDduEvciDetailInstanceController;
