'use strict';

/**
 * @ngInject
 */
function OperatorCreateController($scope,ApiConstants, $http, Operators, Partners, ActivityAreas, $modal, $location, Identity, QueueMgt) {
  $scope.operator = new Operators();
  $scope.partners = Partners.getPartnerListArray();
  $scope.activityAreas = ActivityAreas.query();
  $scope.rebuilddatepicker = true;

    /* Important pour les composants génériques */
  $scope.obj = $scope.operator;

  $scope.roles = [{name: 'CPO'},{name: 'eMSP'},{name: 'Agg'}];
  $scope.authExchangeModes = [{name: 'SyncFirst'},{name: 'SyncOnly'},{name: 'AsyncFirst'},{name: 'AsyncOnly'}];
  $scope.CDRExchangeModes = [{name: 'Sync'},{name: 'Async'}];
  $scope.cdrAsIsMode = [{name: 'Always', code : 'ALWAYS'}, {name: 'Never', code : 'NEVER'}, {name: 'IfDocumented', code : 'IF_DOCUMENTED'}];
  $scope.cdrImprovedMode = [{name: 'Never', code : 'NEVER'}, {name: 'Always', code : 'ALWAYS'}, {name: 'ExceptIfDocumented', code :'EXCEPT_IF_DOCUMENTED'}];
  $scope.operator.chargeneedauthorised = false;
  $scope.operator.multicastAuthorised = false;
  $scope.operator.needSignedInfo = false;
  $scope.operator.sendTokenToCPO = false;
  $scope.operator.acceptActionRequest = true;
  $scope.operator.acceptEventReport = true;
  $scope.operator.sessionInformationRoaming = true;
  $scope.operator.canDisputeCpo = false;
  $scope.operator.canDisputeEmsp = false;
  $scope.operator.canFlagCpo = false;
  $scope.operator.cpoTariffFlowActivation = 'NEVER';
  $scope.dduDisabledMode = [{name: 'DDU Filter - return KO', code :'FILTER_KO'}, {name: 'No Filter', code : 'NO_FILTER'}, {name: 'DDU Filter - return OK', code : 'FILTER_OK'}];
  $scope.dduQueues = QueueMgt.getDduQueue();
  $scope.dduQueues.$promise.then(function(dduQueues) {
    $scope.operator.queueDduId = dduQueues[Math.floor(Math.random() * dduQueues.length)].id;
  });

  $scope.operator.cdrExchangeMode = 'Sync';
  $scope.operator.authorizationExchangeMode = 'SyncFirst';
  $scope.operator.cdrAsIsMode = 'ALWAYS';
  $scope.operator.cdrImprovedMode = 'NEVER';
  $scope.operator.dduDisabledMode = 'FILTER_KO';
  //$scope.operator.queueDduId = $scope.dduQueues[Math.floor(Math.random() * $scope.dduQueues.length)];

  $scope.searchField = function (fields, groupName, fieldName) {
        var field;

      fields.forEach(function onEachKey(value) {
            if (value.name === groupName) {
                value.value.forEach(function onEachKey(subvalue) {
                    if (subvalue.value === fieldName) {
                        field = subvalue;
                    }
                });
            }
        });

        return field;
  };

  $scope.subelementsConstraintList = function (items, field) {
        if (
            (typeof field['constraint_list'] !== 'undefined') && (field['constraint_list'] !== null) &&
            (typeof field['constraint_value'] !== 'undefined') && (field['constraint_value'] !== null) &&
            (typeof field['subfields'] !== 'undefined') && (field['subfields'] !== null)
        ) {
            var constraintList = field['constraint_list'];
            var constraintValue = field['constraint_value'];
            var fieldList = field['subfields'];
            var constraintListToAdd = constraintList.slice();
            var newItems = [];

            // Filtrage des items existants
            if (typeof items !== 'undefined') {
                for (var i = 0; i < items.length; i++) {
                    if (constraintList.indexOf(items[i][constraintValue]) !== -1) {
                        newItems.push(items[i]);
                        constraintListToAdd.splice(constraintListToAdd.indexOf(items[i][constraintValue]), 1);
                    }
                }
            }

            // Ajout des items manquants
            var handleMissingItem = function (value) {
                if (value.value === constraintValue) {
                    newObject[value.value] = constraintListToAdd[j];
                } else if ((typeof value['default_value'] !== 'undefined') && (value['default_value'] !== null)) {
                    newObject[value.value] = value['default_value'];
                } else {
                    newObject[value.value] = '';
                }
            };

            for (var j = 0; j < constraintListToAdd.length; j++) {
                var newObject = {};
                newObject['id'] = '';
                fieldList.forEach(handleMissingItem);
                newItems.push(newObject);
            }

            newItems.sort(function (a, b) {
                if (a[constraintValue] < b[constraintValue]) {
                    return -1;
                } else if (a[constraintValue] > b[constraintValue]) {
                    return 1;
                } else {
                    return 0;
                }
            });

            return newItems;
        }
    };

    $scope.fields = [
        {
            'name': 'FIELDS_IDENTIFICATION',
            'value': [
                {
                    'value': 'emi3id',
                    'name': 'EMI3ID',
                    'tooltip': 'TOOLTIP_EMI3ID',
                    'type': 'text'
                },
                {
                    'value': 'name',
                    'name': 'NAME',
                    'tooltip': 'TOOLTIP_OPERATORNAME',
                    'type': 'text'
                },
                {
                    'value': 'comment',
                    'name': 'COMMENTS',
                    'tooltip': 'COMMENTS',
                    'type': 'text'
                },
                {
                    'value': 'roleNameList',
                    'name': 'ROLES',
                    'tooltip': 'ROLES',
                    'type': 'object_multi_autocomplete',
                    'options': {
                        'list': 'roles',
                        'id': 'name',
                        'label': 'name'
                    }
                },
                {
                    'value': 'partnerIdList',
                    'name': 'PARTNERS',
                    'tooltip': 'TOOLTIP_PARTNER',
                    'type': 'object_multi_autocomplete',
                    'options': {
                        'list': 'partners',
                        'id': 'id',
                        'label': 'name',
                        'sortable': true
                    }
                }
            ]
        },
        {
            'name': 'FIELDS_EMSP_ACTIVATION_FLOW',
            'value': [
                {
                    'value': 'authorizationExchangeMode',
                    'name': 'AUTH_EXCHANGE_MODE',
                    'tooltip': 'TOOLTIP_AUTHORIZATIONEXCHANGEMODE',
                    'type': 'object_autocomplete',
                    'required': true,
                    'options': {
                        'list': 'authExchangeModes',
                        'id': 'name',
                        'label': 'name'
                    }
                },
                {
                    'value': 'multicastAuthorised',
                    'name': 'MULTICAST_AUTHORISED',
                    'tooltip': 'TOOLTIP_AUTHORIZATIONMULTICAST',
                    'type': 'boolean',
                    'options': {
                        'truevalue': 'ACTIF_M',
                        'falsevalue': 'INACTIF_M',
                        'defaultvalue': 'INACTIF_M'
                    }
                },
                {
                    'value': 'acceptActionRequest',
                    'name': 'ACTION_REQUEST_ROAMING',
                    'tooltip': 'TOOLTIP_CPOORDERTRANSMISSION',
                    'type': 'boolean',
                    'options': {
                        'truevalue': 'ACTIF_M',
                        'falsevalue': 'INACTIF_M',
                        'defaultvalue': 'ACTIF_M'
                    }
                },
                {
                    'value': 'acceptEventReport',
                    'name': 'EVENT_REPORT_ROAMING',
                    'tooltip': 'TOOLTIP_EVENTRECEIVING',
                    'type': 'boolean',
                    'options': {
                        'truevalue': 'ACTIF_M',
                        'falsevalue': 'INACTIF_M',
                        'defaultvalue': 'ACTIF_M'
                    }
                },
                {
                    'value': 'sessionInformationRoaming',
                    'name': 'FIELDS_SESSION_INFORMATION_ROAMING',
                    'tooltip': 'TOOLTIP_SESSIONRECEIVING',
                    'type': 'boolean',
                    'options': {
                        'truevalue': 'ACTIF_M',
                        'falsevalue': 'INACTIF_M',
                        'defaultvalue': 'ACTIF_M'
                    }
                },
                {
                    'value': 'needSignedInfo',
                    'name': 'NEED_SIGNED_INFO',
                    'tooltip': 'NEED_SIGNED_INFO',
                    'type': 'boolean',
                    'options': {
                        'truevalue': 'ACTIF_M',
                        'falsevalue': 'INACTIF_M',
                        'defaultvalue': 'INACTIF_M'
                    }
                }
            ]
        },
        {
            'name': 'FIELDS_EMSP_CDR_FLOW',
            'value': [
                {
                    'value': 'cdrExchangeMode',
                    'name': 'CDR_EXCHANGE_MODE',
                    'tooltip': 'TOOLTIP_CDREXCHANGEMODE',
                    'type': 'object_autocomplete',
                    'required': true,
                    'options': {
                        'list': 'CDRExchangeModes',
                        'id': 'name',
                        'label': 'name'
                    }
                },
                {
                    'value': 'cdrAsIsMode',
                    'name': 'CDR_AS_IS_MODE',
                    'tooltip': 'TOOLTIP_ASISCDRFLOW',
                    'type': 'object_autocomplete',
                    'required': true,
                    'options': {
                        'list': 'cdrAsIsMode',
                        'id': 'code',
                        'label': 'name'
                    }
                },
                {
                    'value': 'cdrImprovedMode',
                    'name': 'CDR_IMPROVED_MODE',
                    'tooltip': 'TOOLTIP_IMPROVEDCDR',
                    'type': 'object_autocomplete',
                    'required': true,
                    'options': {
                        'list': 'cdrImprovedMode',
                        'id': 'code',
                        'label': 'name'
                    }
                }
            ]
        },
        {
            'name': 'FIELDS_EVCI_DATA_FLOW',
            'value': [
                {
                    'value': 'dduDisabledMode',
                    'name': 'DDU_DISABLED_MODE',
                    'tooltip': 'TOOLTIP_XDUDESACTIVATION',
                    'type': 'object_autocomplete',
                    'required': true,
                    'options': {
                        'list': 'dduDisabledMode',
                        'id': 'code',
                        'label': 'name'
                    }
                },
                {
                    'value': 'queueDduId',
                    'name': 'QUEUE_DDU_FIELD',
                    'tooltip': 'TOOLTIP_DDUQUEUE',
                    'type': 'object_autocomplete',
                    'options': {
                        'list': 'dduQueues',
                        'id': 'id',
                        'label': 'name'
                    }
                },
                {
                    'value': 'sendTokenToCPO',
                    'name': 'SEND_TOKEN_TO_CPO',
                    'tooltip': 'SEND_TOKEN_TO_CPO',
                    'type': 'boolean',
                    'options': {
                        'truevalue': 'ACTIF_M',
                        'falsevalue': 'INACTIF_M',
                        'defaultvalue': 'INACTIF_M'
                    }
                }
            ]
        }
    ];


    $scope.fieldsRight = [
        {
            'name': 'FIELDS_RIGHTS',
            'value': [
                {
                    'value': 'checkServiceSubscription',
                    'name': 'CHECK_SERVICE_SUBSCRIPTION',
                    'tooltip': 'TOOLTIP_CHECK',
                    'type': 'boolean',
                    'options': {
                        'truevalue': 'ACTIF_M',
                        'falsevalue': 'INACTIF_M',
                        'defaultvalue': 'INACTIF_M'
                    }
                },
                {
                    'value': 'billServiceSubscription',
                    'name': 'BILL_SERVICE_SUBSCRIPTION',
                    'tooltip': 'TOOLTIP_BILL',
                    'type': 'boolean',
                    'options': {
                        'truevalue': 'ACTIF_M',
                        'falsevalue': 'INACTIF_M',
                        'defaultvalue': 'INACTIF_M'
                    }
                },
                {
                    'value': 'canDisputeEmsp',
                    'name': 'DISPUTE_EMSP',
                    'tooltip': 'TOOLTIP_EMSPDISPUTESERVICE',
                    'type': 'boolean',
                    'options': {
                        'truevalue': 'ACTIF_M',
                        'falsevalue': 'INACTIF_M',
                        'defaultvalue': 'INACTIF_M'
                    }
                },
                {
                    'value': 'canDisputeCpo',
                    'name': 'DISPUTE_CPO',
                    'tooltip': 'TOOLTIP_CPODISPUTESERVICE',
                    'type': 'boolean',
                    'options': {
                        'truevalue': 'ACTIF_M',
                        'falsevalue': 'INACTIF_M',
                        'defaultvalue': 'INACTIF_M'
                    }
                },
                {
                    'value': 'canFlagCpo',
                    'name': 'FLAG_CPO',
                    'tooltip': 'TOOLTIP_INVOICINGINTENTIONSERVICE',
                    'type': 'boolean',
                    'options': {
                        'truevalue': 'ACTIF_M',
                        'falsevalue': 'INACTIF_M',
                        'defaultvalue': 'INACTIF_M'
                    }
                }
            ]
        },
        {
            'name': 'FIELDS_PULLING_DATA',
            'value': [{
                'value': 'pullingDataList',
                'name': 'method',
                'type': 'subelements_constraint',
                'has_categories': false,
                'constraint_list': ['Locations', 'Cdrs', 'Tokens'],
                'constraint_value': 'method',
                'subfields': [
                    {
                        'value': 'method',
                        'name': 'METHOD',
                        'type': 'hidden'
                    },
                    {
                        'value': 'lastSuccessResponseTimestamp',
                        'name': 'PULLING_LAST_SUCCESS_TIMESTAMP',
                        'type': 'datetime'
                    },
                    {
                        'value': 'pullingEnabled',
                        'name': 'PULLING_ENABLED',
                        'type': 'boolean',
                        'options': {
                            'truevalue': 'ACTIF_M',
                            'falsevalue': 'INACTIF_M',
                            'defaultvalue': 'INACTIF_M'
                        }
                    }
                ]
            }]
        },
        {
            'name': 'FIELDS_OTHER_SERVICES',
            'value': [
                {
                    'value': 'activityAreaIdList',
                    'name': 'ACTIVITY_AREAS',
                    'tooltip': 'TOOLTIP_ACTIVITYAREAS',
                    'type': 'object_multi_autocomplete',
                    'options': {
                        'list': 'activityAreas',
                        'id': 'id',
                        'label': 'name'
                    }
                },
                {
                    'value': 'chargeneedauthorised',
                    'name': 'CHARGE_NEED_AUTHORISED',
                    'tooltip': 'TOOLTIP_CHARGENEED',
                    'type': 'boolean',
                    'options': {
                        'truevalue': 'AUTHORISED_M',
                        'falsevalue': 'UNAUTHORISED_M',
                        'defaultvalue': 'UNAUTHORISED_M'
                    }
                }
            ]
        }
    ];

    var pullingDataList = $scope.searchField($scope.fieldsRight, 'FIELDS_PULLING_DATA', 'pullingDataList');
    $scope.operator.pullingDataList = $scope.subelementsConstraintList($scope.operator.pullingDataList, pullingDataList);

    $scope.createOperator = function() {
      $scope.operator.createdby = Identity.getUsername();
      $scope.operator.checkServiceSubscription = $scope.operator.checkServiceSubscription ? 1 : 0;
      $scope.operator.billServiceSubscription = $scope.operator.billServiceSubscription ? 1 : 0;
      $scope.operator.pullingDataList = JSON.stringify($scope.operator.pullingDataList);

      $scope.operator.$create(
          function onCreateSuccess() {
              $location.path('/admin/operators');
          },
          function onCreateError(error) {
              openModalError(error);
          }
      );
  };

  function openModalError(error) {
      $modal.open({
          templateUrl: 'modalError.html',
          controller: 'ModalErrorInstanceController',
          resolve: {
              errorData: function () {
                  return error;
              }
          }
      });
  }

  $scope.create = function (size) {
      if (!$scope.iopForm.$valid) {
          $scope.submittedForm = true;
          return;
      }

      var modalInstance = $modal.open({
          templateUrl: 'modalConfirmCreate.html',
          controller: 'ModalConfirmInstanceController',
          size: size
      });

      modalInstance.result.then(
          function onModalConfirm() {
              $scope.createOperator();
          },
          function onModalCancel() {
          }
      );
  };
}
OperatorCreateController.$inject = ['$scope', 'ApiConstants', '$http', 'Operators', 'Partners', 'ActivityAreas', '$modal', '$location', 'Identity', 'QueueMgt'];

module.exports = OperatorCreateController;
