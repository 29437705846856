'use strict';

/**
 * @ngInject
 */
function QueueService($resource, ApiConstants, TransformJson) {
  return $resource(ApiConstants.backendApiUrl + '/queuemgt/', { }, {

    skipNextMessage: {
      url: ApiConstants.backendApiUrl + '/queuemgt/skip_next_messages',
      method: 'POST',
      transformRequest: function (data) {
        return TransformJson.transformRequest(data);
      }
    },
    getQueuesForList: {
      url: ApiConstants.backendApiUrl + '/queuemgt',
      method: 'GET',
      transformResponse: function(data){
        return JSON.parse(data);
      },
      isArray: true
    },
    resetDdu: {
      url: ApiConstants.backendApiUrl + '/queuemgt/reset_ddu',
      method: 'GET'
    },
    getDduQueue:{
      url: ApiConstants.backendApiUrl + '/queuemgt/ddu_list',
      method: 'GET',
      isArray:true,
      transformResponse:function(data){
        return JSON.parse(data);
      }
    }
  });
}
QueueService.$inject = ['$resource', 'ApiConstants', 'TransformJson'];

module.exports = QueueService;
