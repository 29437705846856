'use strict';

/**
 * @ngInject
 */
function FilteringDduEvciCreateController($scope, FilteringDduEvcis, Operators, Identity, $modal, $timeout, $location, $http, ApiConstants) {

  $scope.filteringevci = new FilteringDduEvcis();
  $scope.obj = $scope.filteringevci;

  $scope.rebuilddatepicker = true;
  $scope.$on('$localeChangeSuccess', function () {
    $scope.rebuilddatepicker = false;
    $timeout(function(){
      $scope.rebuilddatepicker = true;
    },0);
  });

  $scope.evciIdTypes = [{name: 'eMI3'},{name: 'gireve'},{name: 'external'}];
  $scope.evciTypes = [{name: 'POOL'},{name: 'STATION'},{name: 'EVSE'},{name: 'CONNECTOR'}];
  $scope.responseModes = [{name: 'FILTER_OK'},{name: 'FILTER_KO'}];
  $scope.operatorsCPO = Operators.queryByRoleSimple({id: 'CPO'});

  $scope.fields = [{
    'name': 'FIELDS_IDENTIFICATION',
    'value': [
      {
        'value': 'evciId',
        'name': 'EVCIID',
        'type': 'text',
        'required': true
      },
      {
        'value': 'evciIdType',
        'name': 'EVCIIDTYPE',
        'type': 'object_autocomplete',
        'required': true,
        'options': {
          'list': 'evciIdTypes',
          'id': 'name',
          'label': 'name'
        }
      },
      {
        'value': 'evciType',
        'name': 'EVCITYPE',
        'type': 'object_autocomplete',
        'required': true,
        'options': {
          'list': 'evciTypes',
          'id': 'name',
          'label': 'name'
        }
      },
      {
        'value': 'cpoId',
        'name': 'CPOID',
        'type': 'object_autocomplete',
        'required': true,
        'options': {
          'list': 'operatorsCPO',
          'id': 'id',
          'label': 'name'
        }
      },
      {
        'value': 'responseMode',
        'name': 'RESPONSEMODE',
        'type': 'object_autocomplete',
        'required': true,
        'options': {
          'list': 'responseModes',
          'id': 'name',
          'label': 'name'
        }
      },
      {
        'value': 'filterEndDate',
        'name': 'FILTER_DATE_UNTIL',
        'type': 'datetime',
        'required': true,
        'options': {
          'placement': 'top'
        }
      }
    ]
  }];

  // Create
  $scope.createFilteredEvci = function() {
    var body = {
      evciId: $scope.filteringevci.evciId,
      evciIdType: $scope.filteringevci.evciIdType,
      evciType: $scope.filteringevci.evciType,
      cpoId: $scope.filteringevci.cpoId,
      responseMode: $scope.filteringevci.responseMode,
      filterEndDate: $scope.filteringevci.filterEndDate,
      createdBy: Identity.getUsername()
    };
    $http.post(ApiConstants.backendApiUrl + '/filtering_ddu_evci', body)
      .then(
        function onCreateSuccess() {
          $location.path('/admin/filteringddusevci');
        },
        function onCreateError(error) {
          openModalError(error);
        }
      );
  };

  function openModalError(error) {
    $modal.open({
      templateUrl: 'modalError.html',
      controller: 'ModalErrorInstanceController',
      resolve: {
        errorData: function () {
          return error;
        }
      }
    });
  }

  $scope.create = function (size) {
    if (!$scope.iopForm.$valid) {
      $scope.submittedForm = true;
      return;
    }

    var modalInstance = $modal.open({
      templateUrl: 'modalConfirmCreate.html',
      controller: 'ModalConfirmInstanceController',
      size: size
    });

    modalInstance.result.then(
      function onModalConfirm() { $scope.createFilteredEvci(); },
      function onModalCancel() { }
    );
  };

}
FilteringDduEvciCreateController.$inject = ['$scope', 'FilteringDduEvcis', 'Operators', 'Identity', '$modal', '$timeout', '$location', '$http', 'ApiConstants'];

module.exports = FilteringDduEvciCreateController;

