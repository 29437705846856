'use strict';

/**
 * @ngInject
 */
function UserCachesService($resource, ApiConstants, TransformJson) {
  return $resource(ApiConstants.backendApiUrl + '/usercache', {}, {
    query: {
      method: 'GET',
      transformResponse: function (data) {return data;},
      data: false,
      headers: { 'Content-Type': 'application/json' }
    },
    search: {
      method: 'POST'
    },
    delete: {
      url: ApiConstants.backendApiUrl + '/usercache/:id',
      method: 'DELETE'
    }
  });
}
UserCachesService.$inject = ['$resource', 'ApiConstants', 'TransformJson'];

module.exports = UserCachesService;
