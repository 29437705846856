'use strict';

/**
 * @ngInject
 */
function ModalWhiteListDetailInstanceController($scope, $modalInstance, ApiConstants, $http, $modal, whiteList) {
  
  $scope.whiteList = whiteList;

  $scope.ocpiTokenValid = whiteList.ocpiTokenValid;

  $scope.ok = function () {
    $modalInstance.close();
  };

  
}
ModalWhiteListDetailInstanceController.$inject = ['$scope', '$modalInstance', 'ApiConstants', '$http', '$modal', 'whiteList'];

module.exports = ModalWhiteListDetailInstanceController;
