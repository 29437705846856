'use strict';

/**
 * @ngInject
 */
function ApiAuthenticate($http, $q, $location, ApiConstants, Identity) {
  // Interface

  return {
    login: login
  };

  // Implementation

  /**
   * @param credentials Object
   * @return Promise
   */
  function login(credentials) {
    var config = {
      headers:  {
        'Accept': '*/*',
        'X-IOP-REMOTEADDRESS': 'localhost',
        'X-IOP-PASSWORD': credentials.password,
        'X-IOP-USERNAME': credentials.username
      }
    };

    Identity.setUsername(credentials.username);

    var payload = {};

    return $q(function async(resolve, reject) {
      $http
        .post(ApiConstants.backendApiUrl + '/login', payload, config)
        .then(function onAuthenticateWithSuccess(response) {
          var data = response.data;
          if (data.JSESSIONID) {
            // Store token
            Identity.setToken(data.JSESSIONID);
            Identity.setUsername(credentials.username);
            // Resolve promise
            resolve(response.data);
          } else {
            reject(new Error('Missing JSESSIONID'));
          }
        }, reject)
      ;
    });
  }
}
ApiAuthenticate.$inject = ['$http', '$q', '$location', 'ApiConstants', 'Identity'];

module.exports = ApiAuthenticate;
