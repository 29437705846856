'use strict';

/**
 * @ngInject
 */
function OperatorViewController($scope, $http, $state, $stateParams, Operators, $modal, $location, ApiConstants) {
    $scope.operator = Operators.get({id: $stateParams.id}, function () {
        $scope.hasRoleCPO = $scope.operator.roleNameList.indexOf('CPO') !== -1;
    });
    $scope.operator.checkServiceSubscription = !!$scope.operator.checkServiceSubscription;
    $scope.operator.billServiceSubscription = !!$scope.operator.billServiceSubscription;
    $scope.obj = $scope.operator;
    $scope.cptIds = null;
    $scope.isLoading = false;

    $scope.fieldsId = [
        {
            'name': 'FIELDS_IDENTIFICATION',
            'value': [
                {
                    'value': 'gireveId',
                    'name': 'GIREVE_ID',
                    'type': 'text'
                },
                {
                    'value': 'emi3Id',
                    'name': 'EMI3ID',
                    'type': 'text'
                },
                {
                    'value': 'name',
                    'name': 'OPERATION_NAME',
                    'type': 'text'
                },
                {
                    'value': 'comment',
                    'name': 'COMMENTS',
                    'type': 'text'
                },
                {
                    'value': 'roleNameList',
                    'name': 'ROLES',
                    'type': 'list'
                },
                {
                    'value': 'partnerIdList',
                    'name': 'PARTNERS',
                    'type': 'linklist',
                    'options': {
                        'ids': 'partnerNameEmi3idList',
                        'view': 'admin.partnerView'
                    }
                }
            ]
        }
    ];

    $scope.fieldsPullingData = [
        {
            'name': 'FIELDS_PULLING_DATA',
            'value': [{
                'value': 'pullingDataList',
                'name': 'method',
                'type': 'subelements_constraint',
                'has_categories': false,
                'subfields': [
                    {
                        'value': 'lastSuccessResponseTimestamp',
                        'name': 'PULLING_LAST_SUCCESS_TIMESTAMP',
                        'type': 'datetime'
                    },
                    {
                        'value': 'pullingEnabled',
                        'name': 'PULLING_ENABLED',
                        'type': 'boolean',
                        'options': {
                            'truevalue': 'ACTIF_M',
                            'falsevalue': 'INACTIF_M',
                            'defaultvalue': 'INACTIF_M'
                        }
                    },
                    {
                        'value': 'lastSuccessCptId',
                        'name': 'PULLING_LAST_SUCCESS_CPT_ID',
                        'type': 'url',
                        'target': '_blank',
                        'callback': function (value) {
                            return $state.href('admin.diagnose', { 'cptId': value }); }
                    }
                ]
            }]
        }
    ];

    $scope.fieldsEmspFlow = [
        {
            'name': 'FIELDS_EMSP_ACTIVATION_FLOW',
            'value': [
                {
                    'value': 'authorizationExchangeMode',
                    'name': 'AUTH_EXCHANGE_MODE',
                    'type': 'text'
                },
                {
                    'value': 'multicastAuthorized',
                    'name': 'MULTICAST_AUTHORISED',
                    'type': 'boolean',
                    'options': {
                        'truevalue': 'ACTIF_M',
                        'falsevalue': 'INACTIF_M',
                        'defaultvalue': 'ACTIF_M'
                    }
                },
                {
                    'value': 'acceptActionRequest',
                    'name': 'ACTION_REQUEST_ROAMING',
                    'type': 'boolean',
                    'options': {
                        'truevalue': 'ACTIF_M',
                        'falsevalue': 'INACTIF_M',
                        'defaultvalue': 'ACTIF_M'
                    }
                },
                {
                    'value': 'acceptEventReport',
                    'name': 'EVENT_REPORT_ROAMING',
                    'type': 'boolean',
                    'options': {
                        'truevalue': 'ACTIF_M',
                        'falsevalue': 'INACTIF_M',
                        'defaultvalue': 'INACTIF_M'
                    }
                },
                {
                    'value': 'sessionInformationRoaming',
                    'name': 'FIELDS_SESSION_INFORMATION_ROAMING',
                    'type': 'boolean',
                    'options': {
                        'truevalue': 'ACTIF_M',
                        'falsevalue': 'INACTIF_M',
                        'defaultvalue': 'ACTIF_M'
                    }
                },
                {
                    'value': 'needSignedInfo',
                    'name': 'NEED_SIGNED_INFO',
                    'type': 'boolean',
                    'options': {
                        'truevalue': 'ACTIF_M',
                        'falsevalue': 'INACTIF_M',
                        'defaultvalue': 'INACTIF_M'
                    }
                }
            ]
        }
    ];

    $scope.fieldsCdrEmspFlow = [
        {
            'name': 'FIELDS_EMSP_CDR_FLOW',
            'value': [
                {
                    'value': 'cdrExchangeMode',
                    'name': 'CDR_EXCHANGE_MODE',
                    'type': 'text'
                },
                {
                    'value': 'cdrAsIsMode',
                    'name': 'CDR_AS_IS_MODE',
                    'type': 'text'
                },
                {
                    'value': 'cdrImprovedMode',
                    'name': 'CDR_IMPROVED_MODE',
                    'type': 'text'
                }
            ]
        }
    ];

    $scope.fieldsRight = [
        {
            'name': 'FIELDS_RIGHTS',
            'value': [
                {
                    'value': 'checkServiceSubscription',
                    'name': 'CHECK_SERVICE_SUBSCRIPTION',
                    'type': 'boolean',
                    'options': {
                        'truevalue': 'ACTIF_M',
                        'falsevalue': 'INACTIF_M',
                        'defaultvalue': 'INACTIF_M'
                    }
                },
                {
                    'value': 'billServiceSubscription',
                    'name': 'BILL_SERVICE_SUBSCRIPTION',
                    'type': 'boolean',
                    'options': {
                        'truevalue': 'ACTIF_M',
                        'falsevalue': 'INACTIF_M',
                        'defaultvalue': 'INACTIF_M'
                    }
                },
                {
                    'value': 'canDisputeEmsp',
                    'name': 'DISPUTE_EMSP',
                    'type': 'boolean',
                    'options': {
                        'truevalue': 'ACTIF_M',
                        'falsevalue': 'INACTIF_M',
                        'defaultvalue': 'INACTIF_M'
                    }
                },
                {
                    'value': 'canDisputeCpo',
                    'name': 'DISPUTE_CPO',
                    'type': 'boolean',
                    'options': {
                        'truevalue': 'ACTIF_M',
                        'falsevalue': 'INACTIF_M',
                        'defaultvalue': 'INACTIF_M'
                    }
                },
                {
                    'value': 'canFlagCpo',
                    'name': 'FLAG_CPO',
                    'type': 'boolean',
                    'options': {
                        'truevalue': 'ACTIF_M',
                        'falsevalue': 'INACTIF_M',
                        'defaultvalue': 'INACTIF_M'
                    }
                }
            ]
        }
    ];

    $scope.fieldsOther = [
        {
            'name': 'FIELDS_OTHER_SERVICES',
            'value': [
                {
                    'value': 'activityAreaNameList',
                    'name': 'ACTIVITY_AREAS',
                    'type': 'list'
                },
                {
                    'value': 'chargeNeedAuthorized',
                    'name': 'CHARGE_NEED_AUTHORISED',
                    'type': 'boolean',
                    'options': {
                        'truevalue': 'AUTHORISED_M',
                        'falsevalue': 'UNAUTHORISED_M',
                        'defaultvalue': 'UNAUTHORISED_M'
                    }
                }
            ]
        }
    ];

    $scope.fieldsHist = [
        {
            'name': 'FIELDS_HISTORY',
            'value': [
                {
                    'value': 'createdBy',
                    'name': 'CREATED_BY',
                    'type': 'text'
                },
                {
                    'value': 'createdOn',
                    'name': 'CREATED_ON',
                    'type': 'datetime'
                },
                {
                    'value': 'updatedBy',
                    'name': 'UPDATED_BY',
                    'type': 'text'
                },
                {
                    'value': 'updatedOn',
                    'name': 'UPDATED_ON',
                    'type': 'datetime'
                }
            ]
        }
    ];

    $scope.deleteOperator = function () {
        $scope.operator.$delete(
            function onDeleteSuccess() {
                $location.path('/admin/operators');
            },
            function onDeleteError(data) {
                $modal.open({
                    templateUrl: 'modalError.html',
                    controller: 'ModalErrorInstanceController',
                    resolve: {
                        errorData: function () {
                            return data;
                        }
                    }
                });
            }
        );
    };

    $scope.delete = function () {
        var modalInstance = $modal.open({
            templateUrl: 'modalConfirmDelete.html',
            controller: 'ModalConfirmInstanceController'
        });

        modalInstance.result.then(
            function onModalConfirm() {
                $scope.deleteOperator();
            },
            function onModalCancel() {
            }
        );
    };

    $scope.pullLocationsByOperator = function () {
        var modalInstance = $modal.open({
            templateUrl: 'modalRefreshOcpiLocationOperator.html',
            controller: 'ModalRefreshOcpiLocationOperatorController',
            resolve: {
                inputData: function () {
                    return {
                        operatorId: $scope.operator.id,
                        operatorName: $scope.operator.name
                    };
                }
            }
        });
        modalInstance.result.then(
            function onModalConfirm() {
                $modal.open({
                    templateUrl: 'modalWaitingForCptid.html',
                    controller: 'ModalWaitingForCptidController',
                    resolve: {
                        inputData: function () {
                            return {
                                request: $http.post(ApiConstants.backendApiUrl + '/pull_ocpi/locations/operator', {operatorTechId: $scope.operator.id})
                            };
                        }
                    }
                });

            }
        );
    };


    $scope.pullTariffsByOperator = function () {
        var modalInstance = $modal.open({
            templateUrl: 'modalRefreshOcpiTariffOperator.html',
            controller: 'ModalRefreshOcpiTariffOperatorController',
            resolve: {
                inputData: function () {
                    return {
                        operatorId: $scope.operator.id,
                        operatorName: $scope.operator.name
                    };
                }
            }
        });
        modalInstance.result.then(
            function onModalConfirm() {
                $modal.open({
                    templateUrl: 'modalWaitingForCptid.html',
                    controller: 'ModalWaitingForCptidController',
                    resolve: {
                        inputData: function () {
                            return {
                                request: $http.post(ApiConstants.backendApiUrl + '/pull_ocpi/tariffs/operator', {operatorTechId: $scope.operator.id})
                            };
                        }
                    }
                });

            }
        );
    };

    $scope.flowActivationMapper = function (value) {
        switch (value) {
            case 'NEVER' :
                return 'INACTIF_M';
            case 'ALWAYS' :
                return 'ACTIF_M';
            default :
                return 'INACTIF_M';
        }
    };

    $scope.sendTokenToCpoMapper = function (value) {
        switch (value) {
            case false :
                return 'INACTIF_M';
            case true :
                return 'ACTIF_M';
            default :
                return 'INACTIF_M';
        }
    };

}
OperatorViewController.$inject = ['$scope', '$http', '$state', '$stateParams', 'Operators', '$modal', '$location', 'ApiConstants'];

module.exports = OperatorViewController;
