'use strict';

module.exports = angular
  .module('bo-web.common', [])
  .config(require('./config/http'))
  .factory('AuthentificationInterceptor', require('./services/authentification-interceptor'))
  .factory('AppStorage', require('./services/app-storage'))
  .factory('Identity', require('./services/identity'))
  .factory('Tracer', require('./services/tracer'))
  .factory('UUID', require('./services/uuid'))
;
