'use strict';

/**
 * @ngInject
 */
function ModalInactiveContractInstanceController($scope, $modalInstance, ApiConstants, $http, $modal, contract) {
  $scope.contract = contract;

  $scope.ok = function () {
    $modalInstance.close();
  };
}
ModalInactiveContractInstanceController.$inject = ['$scope', '$modalInstance', 'ApiConstants', '$http', '$modal', 'contract'];

module.exports = ModalInactiveContractInstanceController;
