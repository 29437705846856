'use strict';

/**
 * @ngInject
 */
function ModalRefreshOcpiLocationOperatorController($scope, $modalInstance, $modal, $http, ApiConstants, inputData) {

    $scope.input = inputData;
    $scope.loading = true;
    $scope.isValid = false;

    $http.post(ApiConstants.backendApiUrl + '/pull_ocpi/locations/operator/validation', {operatorTechId: inputData.operatorId})
        .then(
            function onGetSuccess(response) {
                $scope.isValid = response.data.valid;
                $scope.error = response.data.error;
                $scope.loading = false;
            },
            function onGetError(error) {
                $scope.isValid = false;
                $scope.isLoading = false;
                $scope.error = error;
            }
        );

    $scope.ok = function () {
        if($scope.isValid){
            $modalInstance.close();
        }
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };
}
ModalRefreshOcpiLocationOperatorController.$inject = ['$scope', '$modalInstance', '$modal', '$http', 'ApiConstants', 'inputData'];

module.exports = ModalRefreshOcpiLocationOperatorController;
