'use strict';

/**
 * @ngInject
 */
function DataContractsService($resource, ApiConstants) {
  var servicesMap = {
    'Data': {'fieldId' : 'endUserServiceDataIdList', 'fieldName' : 'endUserServiceDataNameList'}, 
    'DDD': {'fieldId' : 'endUserServiceDDDIdList', 'fieldName' : 'endUserServiceDDDNameList'}, 
    'SDD': {'fieldId' : 'endUserServiceSDDIdList', 'fieldName' : 'endUserServiceSDDNameList'}
  };

  function transformRequest(data) {
    var requestData = Object.assign({}, data);

    if (!requestData.services) {
      requestData.services = [];
    }

    for (var platform in servicesMap) {
      var service = null;
      for (var i = 0; i < requestData.services.length; i++) {
        if (requestData.services[i].servicePlatform === platform) {
          service = requestData.services[i];
        }
      }

      if (service === null) {
        service = {
          servicePlatform: platform,
          serviceIds: []
        };

        requestData.services.push(service);
      }

      service.serviceIds = requestData[servicesMap[platform].fieldId] || [];
    }

    return JSON.stringify(requestData);
  }

  function transformResponse(data) {
    var responseData = JSON.parse(data);
    for (var platform in servicesMap) {
      responseData[servicesMap[platform].fieldId] = []; 
      responseData[servicesMap[platform].fieldName] = []; 
    }

    if (responseData.services) {
      for (var i = 0; i < responseData.services.length; i++) {
        if (responseData.services[i].servicePlatform in servicesMap) {
          var fieldId = servicesMap[responseData.services[i].servicePlatform].fieldId; 
          var fieldName = servicesMap[responseData.services[i].servicePlatform].fieldName; 
          responseData[fieldId] = responseData.services[i].serviceIds.map(function (elt) {
            return parseInt(elt, 10);
          }); 
          responseData[fieldName] = responseData.services[i].serviceNames; 
        }
      }
    }

    return responseData;
  }

  return $resource(ApiConstants.backendApiUrl + '/dataContracts', { id: '@id' }, {
    query: {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      transformResponse: function (list) { return list.map(transformResponse); },
    },
    get: {
      url: ApiConstants.backendApiUrl + '/dataContracts/:id',
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      transformResponse: transformResponse,
    },
    update: {
      url: ApiConstants.backendApiUrl + '/dataContracts/:id',
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      transformRequest: transformRequest,
      transformResponse: transformResponse,
    },
    create: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      transformRequest: transformRequest,
      transformResponse: transformResponse,
    },
    delete: {
      url: ApiConstants.backendApiUrl + '/dataContracts/:id',
      method: 'DELETE'
    }
  });
}
DataContractsService.$inject = ['$resource', 'ApiConstants'];

module.exports = DataContractsService;
