'use strict';

/**
 * @ngInject
 */
function ImportInvoicingDataController($scope, ApiConstants, $http, Identity, $modal) {
    $scope.isLoading = false;

    $scope.downloadSampleFile = function () {
        var url = ApiConstants.baseHref + ApiConstants.backendApiUrl + '/scd_invoicing_data/download_sample_file';

        if (Identity.hasToken() && Identity.getUsername()) {
            url += '?JSESSIONID=' + Identity.getToken();
        }
        window.open(url);
    };

    $scope.uploadFile = function () {
        var self = this;
        var modalInstance = $modal.open({
            templateUrl: 'modalConfirmImportInvoicingData.html',
            controller: 'ModalConfirmImportInvoicingDataInstanceController'
        });
        modalInstance.result.then(
            function onModalConfirm() {
                self.isLoading = true;
                var formdata = new FormData();
                formdata.append('file', $scope.file);

                var request = {
                    method: 'POST',
                    url: ApiConstants.backendApiUrl + '/scd_invoicing_data/upload_file',
                    data: formdata,
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    }
                };

                $http(request).then(
                    function onGetSuccess(success) {
                        self.isLoading = false;
                        $scope.downloadCsv(success.data);
                        $scope.refresh();
                    },
                    function onGetError(error) {
                        self.isLoading = false;
                        $modal.open({
                            templateUrl: 'modalError.html',
                            controller: 'ModalErrorInstanceController',
                            resolve: {
                                errorData: function () {
                                    return error;
                                }
                            }
                        });
                    }
                );
            },
            function onModalCancel() {
                self.isLoading = false;
                $scope.refresh();
            }
        );
    };

    $scope.refresh = function () {
        window.location.reload(true);
    };

    $scope.downloadCsv = function (csvString) {
        var blob = new Blob([csvString]);
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, 'import_result.csv');
        } else {
            var a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(blob, {
                type: 'text/csv'
            });
            a.download = 'import_result.csv';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    };
}
ImportInvoicingDataController.$inject = ['$scope', 'ApiConstants', '$http', 'Identity', '$modal'];

module.exports = ImportInvoicingDataController;
