'use strict';

/**
 * @ngInject
 */
function iopVersion() {
  return {
    template: 'Version : {{iopHttpVersion}}',
    link: function(scope) {
      scope.getVersion();
    },
    controller: ['$scope', '$http', function($scope, $http) {
      $scope.iopHttpVersion = null;
      $scope.getVersion = function() {
        $http.get('statics/VERSION', {
          headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': 0
          }
        }).then(
          function onGetSuccess(success) { $scope.iopHttpVersion = success.data; },
          function onGetError(error) { }
        );
      };
    }]
  };
}

module.exports = iopVersion;
