'use strict';

/**
 * @ngInject
 */
function HttpConfig($httpProvider) {
  // Injection de l'intercepteur authentification
  $httpProvider.interceptors.push('AuthentificationInterceptor');

  //initialize get if not there
  if (!$httpProvider.defaults.headers.get) {
    $httpProvider.defaults.headers.get = {};
  }

  //disable IE ajax request caching
  $httpProvider.defaults.headers.get['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';

  // extra
  $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
  $httpProvider.defaults.headers.get['Pragma'] = 'no-cache';
}
HttpConfig.$inject = ['$httpProvider'];

module.exports = HttpConfig;
