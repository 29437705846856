'use strict';

/**
 * @ngInject
 */
function EndUserServicesService($resource, ApiConstants, TransformJson) {
  return $resource( ApiConstants.authUrl + ApiConstants.endpointUrl + '/enduserservice/', { id: '@id' }, {
    query: {
      method: 'GET',
      transformResponse: function (data) { return TransformJson.transformResponse(data,'enduserservice'); },
      isArray: true
    },
    queryByServiceplatform: {
      url: ApiConstants.authUrl + ApiConstants.endpointUrl + '/enduserservicebyserviceplatform/:id',
      method: 'GET',
      transformResponse: function (data) { return TransformJson.transformResponse(data,'enduserservice'); },
      isArray: true
    },
    get: {
      url: ApiConstants.authUrl + ApiConstants.endpointUrl + '/enduserservice/:id',
      method: 'GET',
      transformResponse: function (data) { return TransformJson.transformResponse(data,'enduserservice'); },
      isArray: false
    },
  });
}
EndUserServicesService.$inject = ['$resource', 'ApiConstants', 'TransformJson'];

module.exports = EndUserServicesService;
