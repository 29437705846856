'use strict';

/**
 * @ngInject
 */
function RouteConfig($stateProvider, $urlRouterProvider, $locationProvider) {
    // Declare routes
    $stateProvider
        .state('login', {
            url: '/login',
            templateUrl: 'views/login.html',
            controller: 'LoginController as vm'
        })
        .state('admin', {
            abstract: true,
            url: '/admin',
            templateUrl: 'views/admin.html',
        })
        .state('admin.index', {
            url: '/home',
            templateUrl: 'views/index.html',
            controller: 'IndexController as vm'
        })
        .state('admin.partners', {
            url: '/partners',
            templateUrl: 'views/partials/partner_list.html',
            controller: 'PartnerListController as vm'
        })
        .state('admin.partnerView', {
            url: '/partners/:id/view',
            templateUrl: 'views/partials/partner_view.html',
            controller: 'PartnerViewController as vm'
        })
        .state('admin.partnerEdit', {
            url: '/partners/:id/edit',
            templateUrl: 'views/partials/partner_edit.html',
            controller: 'PartnerEditController as vm'
        })
        .state('admin.partnerCreate', {
            url: '/partners/new',
            templateUrl: 'views/partials/partner_create.html',
            controller: 'PartnerCreateController as vm'
        })
        .state('admin.partnerListMonitor', {
            url: '/partnermonitors',
            templateUrl: 'views/partials/partner_list_monitor.html',
            controller: 'PartnerListMonitorController as vm'
        })
        .state('admin.partnerMonitor', {
            url: '/partnermonitors/:id/view',
            templateUrl: 'views/partials/partner_monitor.html',
            controller: 'PartnerMonitorController as vm'
        })
        .state('admin.operators', {
            url: '/operators',
            templateUrl: 'views/partials/operator_list.html',
            controller: 'OperatorListController as vm'
        })
        .state('admin.operatorView', {
            url: '/operators/:id/view',
            templateUrl: 'views/partials/operator_view.html',
            controller: 'OperatorViewController as vm'
        })
        .state('admin.operatorEdit', {
            url: '/operators/:id/edit',
            templateUrl: 'views/partials/operator_edit.html',
            controller: 'OperatorEditController as vm'
        })
        .state('admin.operatorCreate', {
            url: '/operators/new',
            templateUrl: 'views/partials/operator_create.html',
            controller: 'OperatorCreateController as vm'
        })
        .state('admin.contracts', {
            url: '/contracts',
            templateUrl: 'views/partials/contract_list.html',
            controller: 'ContractListController as vm'
        })
        .state('admin.datacontracts', {
            url: '/dataContracts',
            templateUrl: 'views/partials/datacontract_list.html',
            controller: 'DataContractListController as vm'
        })
        .state('admin.roamingmatrix', {
            url: '/roamingmatrix',
            templateUrl: 'views/partials/roaming_matrix.html',
            controller: 'RoamingMatrixController as vm'
        })
        .state('admin.contractView', {
            url: '/contracts/:id/view',
            templateUrl: 'views/partials/contract_view.html',
            controller: 'ContractViewController as vm'
        })
        .state('admin.contractEdit', {
            url: '/contracts/:id/edit',
            templateUrl: 'views/partials/contract_edit.html',
            controller: 'ContractEditController as vm'
        })
        .state('admin.contractCreate', {
            url: '/contracts/new',
            templateUrl: 'views/partials/contract_create.html',
            controller: 'ContractCreateController as vm'
        })
        .state('admin.contractImport', {
            url: '/contracts/import',
            templateUrl: 'views/partials/contract_import.html',
            controller: 'ContractImportController as vm'
        })
        .state('admin.datacontractView', {
            url: '/dataContracts/:id/view',
            templateUrl: 'views/partials/datacontract_view.html',
            controller: 'DataContractViewController as vm'
        })
        .state('admin.datacontractEdit', {
            url: '/dataContracts/:id/edit',
            templateUrl: 'views/partials/datacontract_edit.html',
            controller: 'DataContractEditController as vm'
        })
        .state('admin.datacontractCreate', {
            url: '/dataContracts/new',
            templateUrl: 'views/partials/datacontract_create.html',
            controller: 'DataContractCreateController as vm'
        })
        .state('admin.events', {
            url: '/events',
            templateUrl: 'views/partials/event_list.html',
            controller: 'EventListController as vm'
        })
        .state('admin.alarms', {
            url: '/alarms',
            templateUrl: 'views/partials/alarm_list.html',
            controller: 'AlarmListController as vm'
        })
        .state('admin.records', {
            url: '/records',
            templateUrl: 'views/partials/record_list.html',
            controller: 'RecordListController as vm'
        })
        .state('admin.diagnose', {
            url: '/diagnose?cptId&transactionId&partnerId&operatorId&calleePartner&targetOperatorId&ipSource&ipTarget&evseId&sessionId&funcflow&startDate&endDate&userId&hideProbe',
            templateUrl: 'views/partials/diagnose.html',
            controller: 'DiagnoseController as vm'
        })
        .state('admin.exportbooking', {
            url: '/exportbooking',
            templateUrl: 'views/partials/exportbooking.html',
            controller: 'ExportBookingController as vm'
        })
        .state('admin.exportevseusercachewhitelist', {
            url: '/exportevseusercachewhitelist',
            templateUrl: 'views/partials/exportevseusercachewhitelist.html',
            controller: 'ExportEvseUserCacheWhiteListController as vm'
        })
        .state('admin.exportactionevent', {
            url: '/exportactionevent',
            templateUrl: 'views/partials/exportactionevent.html',
            controller: 'ExportActionEventController as vm'
        })
        .state('admin.evsecache', {
            url: '/evsecache',
            templateUrl: 'views/partials/evsecache_list.html',
            controller: 'EvseCacheListController as vm'
        })
        .state('admin.chargingpoolcache', {
            url: '/chargingpoolcache',
            templateUrl: 'views/partials/chargingpoolcache_list.html',
            controller: 'ChargingPoolCacheListController as vm'
        })
        .state('admin.usercache', {
            url: '/usercache',
            templateUrl: 'views/partials/usercache_list.html',
            controller: 'UserCacheListController as vm'
        })
        .state('admin.whitelist', {
            url: '/whitelist',
            templateUrl: 'views/partials/whitelist_list.html',
            controller: 'WhiteListListController as vm'
        })
        .state('admin.whitelistsyntheticboard', {
            url: '/whitelistsyntheticboard',
            templateUrl: 'views/partials/whitelist_synthetic_board.html',
            controller: 'WhiteListSyntheticBoardController as vm'
        })
        .state('admin.solicitations', {
            url: '/solicitations',
            templateUrl: 'views/partials/exportsolicitation.html',
            controller: 'ExportSolicitationController as vm'
        })
        .state('admin.unknownelements', {
            url: '/unknownelements',
            templateUrl: 'views/partials/exportunknownelements.html',
            controller: 'ExportUnknownElementsController as vm'
        })
        .state('admin.requestgenerator', {
            url: '/requestgenerator',
            templateUrl: 'views/partials/request_generator.html',
            controller: 'RequestGeneratorController as vm'
        })
        .state('admin.retrycheckandbill', {
            url: '/retrycheckandbill',
            templateUrl: 'views/partials/retry_checkandbill.html',
            controller: 'RetryCheckAndBillController as vm'
        })
        .state('admin.filteringddusevci', {
            url: '/filteringddusevci',
            templateUrl: 'views/partials/filteringdduevci.html',
            controller: 'FilteringDduEvciController as vm'
        })
        .state('admin.filteringddusevciCreate', {
            url: '/filteringddusevci/new',
            templateUrl: 'views/partials/filteringdduevci_create.html',
            controller: 'FilteringDduEvciCreateController as vm'
        })
        .state('admin.forwardcdr', {
            url: '/forwardcdr',
            templateUrl: 'views/partials/forwardcdr.html',
            controller: 'ForwardCdrController as vm'
        })
        .state('admin.importinvoicingdata', {
            url: '/importinvoicingdata',
            templateUrl: 'views/partials/importinvoicingdata.html',
            controller: 'ImportInvoicingDataController as vm'
        })
        .state('admin.queuemgt', {
            url: '/queuemgt',
            templateUrl: 'views/partials/queue_management.html',
            controller: 'QueueManagementController as vm'
        })
        .state('admin.rejeusolicitations', {
            url: '/rejeusolicitations',
            templateUrl: 'views/partials/solicitations_rejeu.html',
            controller: 'RejeuSolicitationController as vm'
        })
    ;

    // Default route
    $urlRouterProvider.otherwise('/login');

    // Configure html5
    $locationProvider.html5Mode(true);
}
RouteConfig.$inject = ['$stateProvider', '$urlRouterProvider', '$locationProvider'];

module.exports = RouteConfig;
