'use strict';

/**
 * @ngInject
 */
function ContractViewController($scope, $http, $stateParams, Contracts, $modal, $location) {
    $scope.contract = Contracts.get({id: $stateParams.id});
    $scope.obj = $scope.contract;

    $scope.fields = [
        {
            'name': 'FIELDS_IDENTIFICATION',
            'value': [
                {
                    'value': 'contractId',
                    'name': 'CONTRACT_ID',
                    'type': 'text'
                },
                {
                    'value': 'contractName',
                    'name': 'NAME',
                    'type': 'text'
                },
                {
                    'value': 'description',
                    'name': 'DESCRIPTION',
                    'type': 'text'
                },
                {
                    'value': 'emspName',
                    'name': 'EMSP',
                    'type': 'text'
                },
                {
                    'value': 'cpoName',
                    'name': 'CPO',
                    'type': 'text'
                },
                {
                    'value': 'administrativeStatus',
                    'name': 'STATUS',
                    'type': 'text'
                },
                {
                    'value': 'validFrom',
                    'name': 'VALIDITY_FROM',
                    'type': 'datetime'
                },
                {
                    'value': 'validUntil',
                    'name': 'VALIDITY_UNTIL',
                    'type': 'datetime'
                }
            ]
        },
        {
            'name': 'FIELDS_AUTHORIZED_SERVICES',
            'value': [
                {
                    'value': 'endUserServiceDataNameList',
                    'name': 'AUTHORIZED_SERVICES_DATA',
                    'type': 'list'
                },
                {
                    'value': 'endUserServiceRoamingNameList',
                    'name': 'AUTHORIZED_SERVICES_ROAMING',
                    'type': 'list'
                },
                {
                    'value': 'endUserServiceAuthDataNameList',
                    'name': 'AUTHORIZED_SERVICES_AUTHDATA',
                    'type': 'list'
                },
                {
                    'value': 'endUserServiceDDDNameList',
                    'name': 'AUTHORIZED_SERVICES_DDD',
                    'type': 'list'
                },
                {
                    'value': 'endUserServiceSDDNameList',
                    'name': 'AUTHORIZED_SERVICES_SDD',
                    'type': 'list'
                },
                {
                    'value': 'endUserServiceDuplicateCDRNameList',
                    'name': 'AUTHORIZED_SERVICES_DUPLICATE_CDR',
                    'type': 'list'
                },
                {
                    'value': 'endUserServiceBookingNameList',
                    'name': 'AUTHORIZED_SERVICES_BOOKING',
                    'type': 'list'
                },
                {
                    'value': 'endUserServiceTariffNameList',
                    'name': 'AUTHORIZED_SERVICES_TARIFF',
                    'type': 'list'
                },
                {
                    'value': 'billMode',
                    'name': 'CDR_BILL_EXCLUSION',
                    'type': 'text',
                },
                {
                    'value': 'checkMode',
                    'name': 'CDR_CHECK_EXCLUSION',
                    'type': 'text'

                }
            ]
        },
        {
            'name': 'FIELDS_HISTORY',
            'value': [
                {
                    'value': 'createdBy',
                    'name': 'CREATED_BY',
                    'type': 'text'
                },
                {
                    'value': 'createdOn',
                    'name': 'CREATED_ON',
                    'type': 'datetime'
                },
                {
                    'value': 'updatedBy',
                    'name': 'UPDATED_BY',
                    'type': 'text'
                },
                {
                    'value': 'updatedOn',
                    'name': 'UPDATED_ON',
                    'type': 'datetime'
                }
            ]
        }
    ];

    $scope.deleteContract = function () {
        $scope.contract.$delete(
            function onDeleteSuccess() {
                $location.path('/admin/contracts');
            },
            function onDeleteError(error) {
                $modal.open({
                    templateUrl: 'modalError.html',
                    controller: 'ModalErrorInstanceController',
                    resolve: {
                        errorData: function () {
                            return error;
                        }
                    }
                });
            }
        );
    };

    $scope.delete = function () {
        var modalInstance = $modal.open({
            templateUrl: 'modalConfirmDelete.html',
            controller: 'ModalConfirmInstanceController'
        });

        modalInstance.result.then(
            function onModalConfirm() {
                $scope.deleteContract();
            },
            function onModalCancel() {
            }
        );
    };

}
ContractViewController.$inject = ['$scope', '$http', '$stateParams', 'Contracts', '$modal', '$location'];

module.exports = ContractViewController;
