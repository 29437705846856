'use strict';

/**
 * @ngInject
 */
function ModalConfirmRetryCheckAndBillController($scope, $modalInstance, dataConfirmation, $sce) {

  $scope.dataConfirmation = dataConfirmation;

  $scope.retryParameters = syntaxHighlight(JSON.stringify(dataConfirmation.params, undefined, 4));


  $scope.trustAsHtml = function (string) {
    return $sce.trustAsHtml(string);
  };

  /* https://stackoverflow.com/questions/4810841/how-can-i-pretty-print-json-using-javascript */
  function syntaxHighlight(json) {
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
      var color = 'darkorange';
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          color = 'red';
        } else {
          color = 'green';
        }
      } else if (/true|false/.test(match)) {
        color = 'blue';
      } else if (/null/.test(match)) {
        color = 'magenta';
      }
      return '<span style="color: ' + color + '">' + match + '</span>';
    });
  }

  $scope.ok = function () {
    $modalInstance.close();
  };

  $scope.cancel = function () {
    $modalInstance.dismiss('cancel');
  };
}
ModalConfirmRetryCheckAndBillController.$inject = ['$scope', '$modalInstance', 'dataConfirmation', '$sce'];

module.exports = ModalConfirmRetryCheckAndBillController;
