'use strict';

/**
 * @ngInject
 */
function ModalWaitingForCptidController($scope, $modalInstance, inputData) {
    var input = inputData;

    $scope.isLoading = true;

    input.request.then(
        function onGetSuccess(response) {
            $scope.isLoading = false;
            $scope.onError = false;
            $scope.responseData = response.data;
        },
        function onGetError(error) {
            $scope.isLoading = false;
            $scope.onError = true;
            if (error.status !== 504) {
                $scope.responseData = error;
            } else {
                $scope.responseData = 'CPO took too much time.';
            }
        }
    );

    $scope.ok = function () {
        $modalInstance.close();
    };
}
ModalWaitingForCptidController.$inject = ['$scope', '$modalInstance', 'inputData'];

module.exports = ModalWaitingForCptidController;
