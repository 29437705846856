'use strict';

/**
 * @ngInject
 */
function IndexController() {
  var vm = this;
  vm.welcomeMessage = 'Message de bienvenue affiché par Angular JS';
}

module.exports = IndexController;
