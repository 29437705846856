'use strict';

/**
 * @ngInject
 */
function WhiteListSyntheticBoardController($scope, $location, WhiteLists, $http, ApiConstants, $modal, Identity) {

  $scope.searchData = {
    'page': 1,
    'page_size': {
      'value': 1000
    },
    'order': {
      'field': 'identity',
      'type': 'asc'
    }
  };

  $scope.fields = [
    {
      'value': 'identity',
      'name': 'IDENTITY'
    },
    {
      'value': 'idUserType',
      'name': 'USERIDTYPE'
    },
    {
      'value': 'count',
      'name': 'COUNT'
    },
    {
      'value': 'validity',
      'name': 'VALIDITY'
    }
  ];

  $scope.doSearch = function () {
    //When a request starts, isSomethingLoading is incremented
    $scope.isLoading++;

    $http.get(ApiConstants.backendApiUrl + '/whitelistsyntheticboard').then(
      function onGetSuccess(success) {
        var data = success.data;

        //When a request is done, isSomethingLoading is decremented
        $scope.isLoading--;

        var validWhiteListSyntheticBoard = [];
        var expiredWhiteListSyntheticBoard = [];
        for (var i = 0; i < data.whitelistsyntheticboard.length; i++){
          var line = data.whitelistsyntheticboard[i];

          // checks if the operatorid starts with a '$'. In this case add a space else the line will not be displayed
          if (line['identity'].startsWith('$')){
            var oldIdentity = line['identity'];
            line['identity'] = ' ' + oldIdentity;
          }
          // separate expired whitelists and validate whitelists to obtain independent columns (Mantis 32227)
          if (line['validity'] === true){
            validWhiteListSyntheticBoard.push(line);
          }
          else{
            expiredWhiteListSyntheticBoard.push(line);
          }
        }
        var validUsertypeids = groupBy(validWhiteListSyntheticBoard, 'idUserType');
        $scope.validIdUserTypes = Object.keys(validUsertypeids);
        $scope.validColspanLength = $scope.validIdUserTypes.length;
        var expiredUsertypeids = groupBy(expiredWhiteListSyntheticBoard, 'idUserType');
        $scope.expiredIdUserTypes = Object.keys(expiredUsertypeids);
        $scope.expiredColspanLength = $scope.expiredIdUserTypes.length;

        $scope.syntheticwhitelists = groupBy(data.whitelistsyntheticboard, 'identity');
      },
      function onGetError(error) {
        // called asynchronously if an error occurs
        // or server returns response with an error status.
        $scope.isLoading--;
        $modal.open({
          templateUrl: 'modalError.html',
          controller: 'ModalErrorInstanceController',
          resolve: {
            errorData: function () {
              return 'An error occurs. Please contact administrator.';
            }
          }
        });
      }
    );
  };

  $scope.doSearch($scope.searchData);

  $scope.search = function () {
    $scope.doSearch();
  };

  $scope.export = function(identity, idUserType, validity){

    var modalInstance = $modal.open({
      templateUrl: 'modalConfirmExport.html',
      controller: 'ModalConfirmInstanceController',
      resolve: {

      }
    });

    modalInstance.result.then(
      function onModalConfirm() {
        var url = ApiConstants.backendApiUrl + '/whitelistsyntheticboard/download';
        var firstParam = true;
        var sep;
        // user token
        if (Identity.hasToken() && Identity.getUsername()) {
          sep = firstParam ? '?':'&';
          firstParam = false;
          url += sep + 'JSESSIONID=' + Identity.getToken();
        }
        // validity (expiry date > current date => true) in request parameters
        if ((typeof validity !== 'undefined') && (validity !== null)) {
          sep = firstParam ? '?':'&';
          firstParam = false;
          url += sep + 'validity=' + validity;
        }
        // idUserType in request parameters
        if ((typeof idUserType !== 'undefined') && (idUserType !== null)) {
          sep = firstParam ? '?':'&';
          firstParam = false;
          url += sep + 'idUserType=' + idUserType;
        }
        // identity (emi3id + ' - ' + name)in request parameters
        if ((typeof identity !== 'undefined') && (identity !== null)) {
          sep = firstParam ? '?':'&';
          firstParam = false;
          var emi3id = identity.substr(0,identity.indexOf(' '));
          url += sep + 'identity=' + identity;
        }
        window.open(url);
      },
      function onModalCancel() {
      }
    );
  };

  function groupBy(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }
}
WhiteListSyntheticBoardController.$inject = ['$scope', '$location', 'WhiteLists', '$http', 'ApiConstants', '$modal', 'Identity'];

module.exports = WhiteListSyntheticBoardController;
