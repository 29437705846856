'use strict';

/**
 * @ngInject
 */
function ContractImportController($scope, $http, ApiConstants, Identity) {

  $scope.fields = [
    {
      'name': 'FIELDS_CONTRACTS_NUMBER_TITLE',
      'value': [
        {
          'value': 'createdcontractsnb',
          'name': 'CREATED_CONTRACTS',
          'type': 'integer'
        },
        {
          'value': 'updatedContractsnb',
          'name': 'UPDATED_CONTRACTS',
          'type': 'integer'
        }
      ]
    },
    {
      'name': 'FIELDS_ERRORS_LIST',
      'value': [
        {
          'value': 'linecell',
          'name': 'LINE_CELL',
          'type': 'text'
        },
        {
          'value': 'importresultdescription',
          'name': 'DESCRIPTION',
          'type': 'text'
        }
      ]
    },
    {
      'name': 'FIELDS_WARNING_LIST',
      'value': [
        {
          'value': 'linecell',
          'name': 'LINE_CELL',
          'type': 'text'
        },
        {
          'value': 'importresultdescription',
          'name': 'DESCRIPTION',
          'type': 'text'
        }
      ]
    }
  ];

  //While this variable > 0, we display the loading spinner
  $scope.isLoading = 0;

  $scope.isTested = false;
  $scope.isImported = false;

  $scope.uploadForTest = function () {
    var operation = 'T';
    upload(operation);
  };

  $scope.uploadForImport = function () {
    var operation = 'I';
    upload(operation);
  };


  function upload(operation) {
    $scope.isLoading++;

    var formdata = new FormData();
    formdata.append('file', $scope.file);
    formdata.append('operation', operation);
    formdata.append('userName',Identity.getUsername());

    var request = {
      method: 'POST',
      url: ApiConstants.baseHref + ApiConstants.backendApiUrl + '/contracts/import',
      data: formdata,
      transformRequest: angular.identity,
      headers: {
        'Content-Type': undefined
      }
    };

    $http(request)
      .success(function (response) {
        $scope.isLoading--;
        var jsonResponse = JSON.stringify(response);
        var data = JSON.parse(jsonResponse);
        $scope.createdcontractsnb = data.createdcontractsnb;
        $scope.updatedContractsnb = data.updatedContractsnb;
        $scope.errorsList = data.errorsList;
        $scope.warningList = data.warningList;
        if (operation === 'T') {
          $scope.isTested = true;
        }
        if (operation === 'I') {
          $scope.isImported = true;
        }
        var oldDiv = document.getElementById('inputFileDiv');
        var oldButton = document.getElementById('import_button');
        oldDiv.style.display = 'none';
        oldButton.style.display = 'none';
      })
      .error(function () {
        var errorDiv = document.getElementById('errorDiv');
        errorDiv.innerHTML += '<h3 class=\"inline importMsg\" translate>{{ !isImported? \'TEST_ERROR_MESSAGE\' : \'IMPORT_ERROR_MESSAGE\' | translate }}</h3>';
        $scope.isTested = false;
      });
  }

  $scope.refresh = function () {
    window.location.href = window.location + '#errorDiv';
    window.location.reload(true);
  };

  $scope.downloadFile = function(){
    var url = ApiConstants.baseHref + ApiConstants.backendApiUrl + '/contracts/import';

    if (Identity.hasToken() && Identity.getUsername()) {
      url += '?JSESSIONID=' + Identity.getToken();
    }

    window.open(url);
  };
}
ContractImportController.$inject = ['$scope', '$http', 'ApiConstants', 'Identity'];

module.exports = ContractImportController;
