'use strict';

/**
 * @ngInject
 */
function ExportUnknownElementsController($scope, $location, Extracts, $http, ApiConstants, Identity) {
  $scope.startDate = new moment().subtract(1, 'months');
  $scope.endDate = null;

  $scope.export = function(){
    if ($scope.startDate) {
      var url = ApiConstants.backendApiUrl + '/extracts/unknownelementsdownload';
      var firstParam = true;
      var sep;

      if (Identity.hasToken() && Identity.getUsername()) {
        sep = firstParam ? '?':'&';
        firstParam = false;
        url += sep + 'JSESSIONID=' + Identity.getToken();
      }

      window.open(url);
    }
  };
}
ExportUnknownElementsController.$inject = ['$scope', '$location', 'Extracts', '$http', 'ApiConstants', 'Identity'];

module.exports = ExportUnknownElementsController;
