'use strict';

/**
 * @ngInject
 */
function AuthentificationInterceptor($q, $rootScope, $log, Identity, $location) {
  // Interface

  var service = {
    request: request,
    responseError: responseError
  };

  return service;

  // Implementation

  function request(config) {
    config.headers = config.headers || {};

    if (Identity.hasToken() && Identity.getUsername()) {
        // Inject custom headers
      config.headers.JSESSIONID = Identity.getToken();
      config.headers.X_IOP_USERNAME = Identity.getUsername();
    }

    return config;
  }

  function responseError(error) {
    switch (error.status) {
      case 401:
      case 403:
          Identity.destroy();
          Identity.setPostLogInRoute($location.path());
          $location.path('/login');
        break;
    }

    return $q.reject(error);
  }
}
AuthentificationInterceptor.$inject = ['$q', '$rootScope', '$log', 'Identity', '$location'];

module.exports = AuthentificationInterceptor;
