'use strict';

/**
 * @ngInject
 */
function ModalUserCacheDetailInstanceController($scope, $modalInstance, ApiConstants, $http, $modal, userCache) {
  
  $scope.userCache = userCache;

  $scope.ok = function () {
    $modalInstance.close();
  };

  
}
ModalUserCacheDetailInstanceController.$inject = ['$scope', '$modalInstance', 'ApiConstants', '$http', '$modal', 'userCache'];

module.exports = ModalUserCacheDetailInstanceController;
