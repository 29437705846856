'use strict';

/**
 * @ngInject
 */
function ContractCreateController($scope, $http, ApiConstants, Contracts, Operators, EndUserServices, $modal, $location, Identity, $timeout) {
    $scope.contract = new Contracts();
    $scope.contract.billMode = 'EXCLUDED';
    $scope.contract.checkMode = 'EXCLUDED';
    $scope.contract.administrativeStatus = 'ACTIVE';
    $scope.contract.createdBy = Identity.getUsername();
    $scope.rebuilddatepicker = true;
    $scope.$on('$localeChangeSuccess', function () {
        $scope.rebuilddatepicker = false;
        $timeout(function () {
            $scope.rebuilddatepicker = true;
        }, 0);
    });

    $scope.operatorsEMSP = Operators.findAllByRole({id: 'eMSP'});
    $scope.operatorsCPO = Operators.findAllByRole({id: 'CPO'});
    $scope.endUserServicesData = EndUserServices.queryByServiceplatform({id: 'Data'});
    $scope.endUserServicesRoaming = EndUserServices.queryByServiceplatform({id: 'Roaming'});
    $scope.endUserServicesAuthData = EndUserServices.queryByServiceplatform({id: 'WhiteList'});
    $scope.endUserServicesDDD = EndUserServices.queryByServiceplatform({id: 'DDD'});
    $scope.endUserServicesSDD = EndUserServices.queryByServiceplatform({id: 'SDD'});
    $scope.endUserServicesDuplicateCDR = EndUserServices.queryByServiceplatform({id: 'DuplicationCDR'});
    $scope.endUserServicesBooking = EndUserServices.queryByServiceplatform({id: 'Booking'});
    $scope.endUserServicesTariff = EndUserServices.queryByServiceplatform({id: 'Tariff'});


    $scope.fields = [{
        'name': 'FIELDS_IDENTIFICATION',
        'value': [
            {
                'value': 'contractId',
                'name': 'CONTRACT_ID',
                'type': 'text'
            },
            {
                'value': 'contractName',
                'name': 'NAME',
                'type': 'text'
            },
            {
                'value': 'description',
                'name': 'DESCRIPTION',
                'type': 'text'
            },
            {
                'value': 'emspId',
                'name': 'ID_EMSP',
                'type': 'object_autocomplete',
                'required': true,
                'options': {
                    'list': 'operatorsEMSP',
                    'id': 'id',
                    'label': 'name'
                }
            },
            {
                'value': 'cpoId',
                'name': 'ID_CPO',
                'type': 'object_autocomplete',
                'required': true,
                'options': {
                    'list': 'operatorsCPO',
                    'id': 'id',
                    'label': 'name'
                }
            },
            {
                'value': 'administrativeStatus',
                'name': 'STATUS',
                'type': 'checkbox',
                'options': {
                    'truevalue': 'ACTIVE',
                    'falsevalue': 'INACTIVE',
                    'defaultvalue': 'INACTIVE'
                }
            },
            {
                'value': 'validFrom',
                'name': 'VALIDITY_FROM',
                'type': 'datetime',
                'required': false,
                'options': {
                    'placement': 'top'
                }
            },
            {
                'value': 'validUntil',
                'name': 'VALIDITY_UNTIL',
                'type': 'datetime',
                'required': false,
                'options': {
                    'placement': 'top'
                }
            }
        ]
    },
        {
            'name': 'FIELDS_AUTHORIZED_SERVICES',
            'value': [
                {
                    'value': 'endUserServiceDataIdList',
                    'name': 'AUTHORIZED_SERVICES_DATA',
                    'type': 'object_multi_autocomplete',
                    'options': {
                        'list': 'endUserServicesData',
                        'id': 'serviceid',
                        'label': 'name'
                    }
                },
                {
                    'value': 'endUserServiceRoamingIdList',
                    'name': 'AUTHORIZED_SERVICES_ROAMING',
                    'type': 'object_multi_autocomplete',
                    'options': {
                        'list': 'endUserServicesRoaming',
                        'id': 'serviceid',
                        'label': 'name'
                    }
                },
                {
                    'value': 'endUserServiceAuthDataIdList',
                    'name': 'AUTHORIZED_SERVICES_AUTHDATA',
                    'type': 'object_multi_autocomplete',
                    'options': {
                        'list': 'endUserServicesAuthData',
                        'id': 'serviceid',
                        'label': 'name'
                    }
                },
                {
                    'value': 'endUserServiceDDDIdList',
                    'name': 'AUTHORIZED_SERVICES_DDD',
                    'type': 'object_multi_autocomplete',
                    'options': {
                        'list': 'endUserServicesDDD',
                        'id': 'serviceid',
                        'label': 'name'
                    }
                },
                {
                    'value': 'endUserServiceSDDIdList',
                    'name': 'AUTHORIZED_SERVICES_SDD',
                    'type': 'object_multi_autocomplete',
                    'options': {
                        'list': 'endUserServicesSDD',
                        'id': 'serviceid',
                        'label': 'name'
                    }
                },
                {
                    'value': 'endUserServiceDuplicateCDRIdList',
                    'name': 'AUTHORIZED_SERVICES_DUPLICATE_CDR',
                    'type': 'object_multi_autocomplete',
                    'options': {
                        'list': 'endUserServicesDuplicateCDR',
                        'id': 'serviceid',
                        'label': 'name'
                    }
                },
                {
                    'value': 'endUserServiceBookingIdList',
                    'name': 'AUTHORIZED_SERVICES_BOOKING',
                    'type': 'object_multi_autocomplete',
                    'options': {
                        'list': 'endUserServicesBooking',
                        'id': 'serviceid',
                        'label': 'name'
                    }
                },
                {
                    'value': 'endUserServiceTariffIdList',
                    'name': 'AUTHORIZED_SERVICES_TARIFF',
                    'type': 'object_multi_autocomplete',
                    'options': {
                        'list': 'endUserServicesTariff',
                        'id': 'serviceid',
                        'label': 'name'
                    }
                },
                {
                    'value': 'billMode',
                    'name': 'CDR_BILL_EXCLUSION',
                    'type': 'checkbox',
                    'options': {
                        'truevalue': 'INCLUDED',
                        'falsevalue': 'EXCLUDED',
                        'defaultvalue': 'EXCLUDED'
                    }
                },
                {
                    'value': 'checkMode',
                    'name': 'CDR_CHECK_EXCLUSION',
                    'type': 'checkbox',
                    'options': {
                        'truevalue': 'INCLUDED',
                        'falsevalue': 'EXCLUDED',
                        'defaultvalue': 'EXCLUDED'
                    }
                }
            ]
        }];


    $scope.obj = $scope.contract;

    $scope.createContract = function () {
        $scope.contract.$create(
            function onCreateSuccess() {
                $location.path('/admin/contracts');
            },
            function onCreateError(error) {
                $modal.open({
                    templateUrl: 'modalError.html',
                    controller: 'ModalErrorInstanceController',
                    resolve: {
                        errorData: function () {
                            return error;
                        }
                    }
                });
            }
        );
    };

    $scope.create = function (size) {
        if (!$scope.iopForm.$valid) {
            $scope.submittedForm = true;
            return;
        }

        var modalInstance = $modal.open({
            templateUrl: 'modalConfirmCreate.html',
            controller: 'ModalConfirmInstanceController',
            size: size
        });

        modalInstance.result.then(
            function onModalConfirm() {
                $http.get(ApiConstants.backendApiUrl + '/contracts').then(
                    // Check if the two operators don't already have another contract
                    function onGetSuccess(success) {
                        var contracts = success.data.contracts;
                        var alreadyInContractIds = [];

                        for (var i = 0; i < contracts.length; i++) {
                            var contractId = contracts[i].id;
                            var contractCpo = contracts[i].cpoId;
                            var contractEmsp = contracts[i].emspId;

                            // Different contract ID but same cpo and emsp
                            if (
                                contractCpo === $scope.contract.cpoId &&
                                contractEmsp === $scope.contract.emspId
                            ) {
                                alreadyInContractIds.push(contracts[i].contractId);
                            }
                        }

                        if (alreadyInContractIds.length) {
                            var alreadyInContractModalInstance = $modal.open({
                                templateUrl: 'modalAlreadyInContract.html',
                                controller: 'ModalConfirmAlreadyInContractInstanceController',
                                size: size,
                                resolve: {
                                    contractIds: function () {
                                        return alreadyInContractIds;
                                    }
                                }
                            });

                            alreadyInContractModalInstance.result.then(
                                function onModalConfirm() {
                                    $scope.createContract();
                                },
                                function onModalCancel() {
                                }
                            );
                        } else {
                            $scope.createContract();
                        }
                    },
                    // Save the contract if we couldn't retrieve the contract list
                    function onGetError(error) {
                        $scope.createContract();
                    }
                );
            },
            function onModalCancel() {
            }
        );
    };
}
ContractCreateController.$inject = ['$scope', '$http', 'ApiConstants', 'Contracts', 'Operators', 'EndUserServices', '$modal', '$location', 'Identity', '$timeout'];

module.exports = ContractCreateController;
