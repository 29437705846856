'use strict';

/**
 * @ngInject
 */
function AlarmsService($resource, ApiConstants, TransformJson) {
  return $resource(ApiConstants.backendApiUrl + '/alarms', {}, {
    query: {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    },
    collapse: {
      url: ApiConstants.backendApiUrl + '/alarms/collapsed',
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    }
  });
}
AlarmsService.$inject = ['$resource', 'ApiConstants', 'TransformJson'];

module.exports = AlarmsService;
