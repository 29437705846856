'use strict';

/**
 * @ngInject
 */
function ForwardCdrController($scope, ApiConstants, $http, $modal) {

    $scope.searchServiceSessionId = null;

    $scope.sessionIdIsEmpty = function(){
        return !($scope.searchServiceSessionId);
    };

    $scope.refreshEvse = function () {
        var body = {serviceSessionId: $scope.searchServiceSessionId};

        var modalInstance = $modal.open({
            templateUrl: 'modalConfirmForwardCdr.html',
            controller: 'ModalConfirmForwardCdrInstanceController',
            resolve: {
                inputData: function () {
                    return {
                        message: 'REFRESH_EVSE_CONFIRMATION'
                    };
                }
            }
        });
        modalInstance.result.then(
            function onModalConfirm() {
                $modal.open({
                    templateUrl: 'modalForwardCdrResponse.html',
                    controller: 'ModalForwardCdrResponseController',
                    resolve: {
                        inputData: function () {
                            return {
                                modalTitle: 'REFRESH_EVSE_RESPONSE_TITLE',
                                request: $http.post(ApiConstants.backendApiUrl + '/refresh_scd_evse/refresh_with_sessionid', body)
                            };
                        }
                    }
                });
            }
        );
    };

    $scope.forwardCdr = function () {
        var body = {serviceSessionId: $scope.searchServiceSessionId};

        var modalInstance = $modal.open({
            templateUrl: 'modalConfirmForwardCdr.html',
            controller: 'ModalConfirmForwardCdrInstanceController',
            resolve: {
                inputData: function () {
                    return {
                        message: 'FORWARD_CDR_CONFIRMATION'
                    };
                }
            }
        });
        modalInstance.result.then(
            function onModalConfirm() {
                $modal.open({
                    templateUrl: 'modalForwardCdrResponse.html',
                    controller: 'ModalForwardCdrResponseController',
                    resolve: {
                        inputData: function () {
                            return {
                                modalTitle: 'FORWARD_CDR_RESPONSE_TITLE',
                                request: $http.post(ApiConstants.backendApiUrl + '/resend_cdr/by_isid', body)
                            };
                        }
                    }
                });
            }
        );
    };
}
ForwardCdrController.$inject = ['$scope', 'ApiConstants', '$http', '$modal'];

module.exports = ForwardCdrController;

