'use strict';

/**
 * @ngInject
 */
function ModalErrorInvalidFormInstanceController($scope, $modalInstance, errorData) {
  $scope.ok = function () {
    $modalInstance.close();
  };

  $scope.cancel = function () {
    $modalInstance.dismiss('cancel');
  };


  $scope.errorMessage = errorData.message;

}
ModalErrorInvalidFormInstanceController.$inject = ['$scope', '$modalInstance', 'errorData'];

module.exports = ModalErrorInvalidFormInstanceController;
