'use strict';

/**
 * @ngInject
 */
function EvseDataRequestsService($resource, ApiConstants, TransformJson) {
  return $resource(ApiConstants.backendApiUrl + '/evseDataRequests/', {}, {
    query: {
      method: 'GET',
      isArray: true,
      headers: { 'Content-Type': 'application/json' },
    },
    get: {
      url: ApiConstants.backendApiUrl + '/evseDataRequests/:id',
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
  });
}
EvseDataRequestsService.$inject = ['$resource', 'ApiConstants', 'TransformJson'];

module.exports = EvseDataRequestsService;
