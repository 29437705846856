'use strict';

/**
 * @ngInject
 */
function DiagnoseController($scope, $location, $state, Traces, $http, ApiConstants, Identity, $locale, $timeout, $modal) {

    const toIOP = 'ToIOP';
    const fromIOP = 'FromIOP';
    const prefixOCPI = 'OCPI_';

    $scope.pageSizeList = [
        {value: 50},
        {value: 100},
        {value: 500}
    ];

    $scope.tracequery = {};

    $scope.tracequery.startDate = new Date();
    $scope.tracequery.startDate.setHours(0);
    $scope.tracequery.startDate.setMinutes(0);

    $scope.tracequery.hideProbe = true;

    $scope.rebuilddatepicker = true;
    $scope.$on('$localeChangeSuccess', function () {
        $scope.rebuilddatepicker = false;
        $timeout(function () {
            $scope.rebuilddatepicker = true;
        }, 0);
    });

    $scope.token = Identity.getToken();

    //While this variable > 0, we display the loading spinner
    $scope.isLoading = 0;
    $scope.$locale = $locale;

    $scope.fields = [
        {
            'value': 'date',
            'name': 'DATE',
            'format': 'date',
            'size': ''
        },
        {
            'value': 'funcFlowId',
            'name': 'FUNCTIONAL_FLOW_ID',
            'format': 'text',
            'size': ''
        },
        {
            'value': 'cptId',
            'name': 'CPTID',
            'format': 'text',
            'size': 'small'
        },
        {
            'value': 'transactionId',
            'name': 'TRANSACTIONID',
            'format': 'text',
            'size': 'small'
        },
        {
            'value': 'partnerId',
            'name': 'BLANK',
            'format': 'text',
            'size': ''
        },
        {
            'value': 'operatorId',
            'name': 'BLANK',
            'format': 'text',
            'size': ''
        },
        {
            'value': 'exchangeType',
            'name': 'EMITTER_RECEIVER',
            'format': 'text',
            'size': ''
        },
        {
            'value': 'calleePartner',
            'name': 'BLANK',
            'format': 'text',
            'size': ''
        },
        {
            'value': 'targetOperatorId',
            'name': 'BLANK',
            'format': 'text',
            'size': ''
        },
        {
            'value': 'eventId',
            'name': 'EVENT_ID',
            'format': 'text'
        }
    ];

    $scope.fieldsDetails = [
        {
            'value': 'date',
            'name': 'DATE',
            'format': 'date',
            'size': ''
        },
        {
            'value': 'ipAddress',
            'name': 'IP_ADDRESS',
            'format': 'text',
            'size': ''
        },
        {
            'value': 'funcFlowId',
            'name': 'FUNCTIONAL_FLOW_ID',
            'format': 'text',
            'size': ''
        },
        {
            'value': 'cptId',
            'name': 'CPTID',
            'format': 'text',
            'size': 'small'
        },
        {
            'value': 'transactionId',
            'name': 'TRANSACTIONID',
            'format': 'text',
            'size': 'small'
        },
        {
            'value': 'seqId',
            'name': 'SEQUENCEID',
            'format': 'text',
            'size': 'small'
        },
        {
            'value': 'partnerId',
            'name': 'PARTNER_ID',
            'format': 'text',
            'size': ''
        },
        {
            'value': 'partnerIdType',
            'name': 'PARTNER_ID_TYPE',
            'format': 'text',
            'size': ''
        },
        {
            'value': 'operatorId',
            'name': 'OPERATOR_ID',
            'format': 'text',
            'size': ''
        },
        {
            'value': 'operatorIdType',
            'name': 'OPERATOR_ID_TYPE',
            'format': 'text',
            'size': ''
        },
        {
            'value': 'evseId',
            'name': 'EVSE_ID',
            'format': 'text'
        },
        {
            'value': 'sessionId',
            'name': 'SESSION_ID',
            'format': 'text'
        },
        {
            'value': 'endUserTokenId',
            'name': 'USER_ID',
            'format': 'text',
            'size': ''
        },
        {
            'value': 'endUserTokenIdType',
            'name': 'USER_ID_TYPE',
            'format': 'text',
            'size': ''
        },
        {
            'value': 'eventId',
            'name': 'EVENT_ID',
            'format': 'text'
        },
        {
            'value': 'ipSource',
            'name': 'IP_SOURCE',
            'format': 'text'
        },
        {
            'value': 'ipTarget',
            'name': 'IP_TARGET',
            'format': 'text'
        },
        {
            'value': 'calleePartner',
            'name': 'CALLEE_PARTNER',
            'format': 'text'
        },
        {
            'value': 'targetOperatorId',
            'name': 'TARGET_OPERATOR_ID',
            'format': 'text'
        },
        {
            'value': 'targetOperatorIdType',
            'name': 'TARGET_OPERATOR_ID_TYPE',
            'format': 'text'
        },
        {
            'value': 'responseTime',
            'name': 'RESPONSE_TIME',
            'format': 'time'
        },
        {
            'value': 'calledUri',
            'name': 'CALLED_URI',
            'format': 'text'
        },
        {
            'value': 'requestStatus',
            'name': 'REQUEST_STATUS',
            'format': 'text'
        },
        {
            'value': 'description',
            'name': 'TRACES_DESCRIPTION',
            'format': 'text'
        },
    ];

    $scope.detailFieldsFilter = function (item) {
        return item.name !== 'TRACES_DESCRIPTION';
    };

    $scope.isLarge = function (size) {
        return size === 'large';
    };

    $scope.prettyXml = function (content) {
        if ((typeof content !== 'undefined') && (content !== null)) {
            return vkbeautify.xml(content, 2);
        } else {
            return null;
        }
    };

    $scope.prettyColorXml = function (content, loaded) {
        if (loaded && (typeof content !== 'undefined') && (content !== null)) {
            return vkbeautify.xml(content, 2);
        }
    };

    var getXmlContentFromDescription = function (content) {
        if (content && (content.indexOf('<?xml') >= 0)) {
            var indexXml = content.indexOf('<?xml');
            var contentXmlStart = content.substring(indexXml);
            var contentXml = contentXmlStart.substring(0, contentXmlStart.lastIndexOf(']'));

            return contentXml;
        } else {
            return content;
        }
    };

    $scope.deeplinking = function () {
        var searchParams = [
            {param: 'cptId'},
            {param: 'transactionId'},
            {param: 'partnerId'},
            {param: 'operatorId'},
            {param: 'calleePartner'},
            {param: 'targetOperatorId'},
            {param: 'ipSource'},
            {param: 'ipTarget'},
            {param: 'evseId'},
            {param: 'sessionId'},
            {param: 'funcflow'},
            {
                param: 'startDate', transform: function (elt) {
                    return new Date(elt);
                }
            },
            {
                param: 'endDate', to: 'endDateOptional', transform: function (elt) {
                    return new Date(elt);
                }
            },
            {param: 'userId'},
            {param: 'requestStatus'},
            {
                param: 'hideProbe', transform: function (elt) {
                    return elt === 'true';
                }
            }
        ];

        // Keep track of whether or not there is at least one search param
        var hasSearchParams = false;

        // Retrieve search param values
        for (var i = 0; i < searchParams.length; i++) {
            var param = searchParams[i].param;
            var to = searchParams[i].to || param;

            // If the search param exists, save it
            if ((typeof $location.search()[param] !== 'undefined') && ($location.search()[param] !== null)) {
                var value = $location.search()[param];

                if (angular.isFunction(searchParams[i].transform)) {
                    value = searchParams[i].transform(value);
                }

                // Reset tracequery if needed
                if (!hasSearchParams) {
                    $scope.tracequery = {};
                    hasSearchParams = true;
                }

                $scope.tracequery[to] = value;
            }
        }

        // If at least one search parameter is defined, do the search
        if (hasSearchParams) {
            // Add page size
            $scope.tracequery['page_size'] = $scope.pageSizeList[0];

            // Launch search
            $scope.search($scope.tracequery);
        }
    };

    $scope.hasSearchUrl = function (field) {
        return [
            'funcFlowId',
            'cptId',
            'transactionId',
            'partnerId',
            'operatorId',
            'calleePartner',
            'targetOperatorId'
        ].indexOf(field.value) >= 0;
    };

    $scope.getSearchUrl = function (trace, field) {
        var mapping = {
            'funcFlowId': 'funcflow',
            'cptId': 'cptId',
            'transactionId': 'transactionId',
            'partnerId': 'partnerId',
            'operatorId': 'operatorId',
            'calleePartner': 'calleePartner',
            'targetOperatorId': 'targetOperatorId'
        };

        var searchParams = {};

        if (mapping[field.value]) {
            searchParams[mapping[field.value]] = trace[field.value];
        }

        return $state.href('admin.diagnose', searchParams, {inherit: false});
    };

    $scope.doSearch = function (query) {
        //When a request starts, isSomethingLoading is incremented
        $scope.isLoading++;

        $http.post(ApiConstants.baseHref + ApiConstants.backendApiUrl + '/traces', query).then(
            function onPostSuccess(success) {
                var data = success.data;

                //When a request is done, isSomethingLoading is decremented
                $scope.isLoading--;
                if ((typeof $scope.traces !== 'undefined') && ($scope.traces !== null)) {
                    $scope.traces = $scope.traces.concat(data);
                } else {
                    $scope.traces = data;
                }

                $scope.moreTraces = data.length > 0;
            },
            function onPostError(error) {
                // called asynchronously if an error occurs
                // or server returns response with an error status.
                $scope.isLoading--;
                $modal.open({
                    templateUrl: 'modalError.html',
                    controller: 'ModalErrorInstanceController',
                    resolve: {
                        errorData: function () {
                            return 'An error occurs. Please contact administrator.';
                        }
                    }
                });
            }
        );
    };

    $scope.search = function () {
        $scope.traces = null;
        $scope.tracequery.page = 1;
        $scope.tracequery.endDate = null;

        if ((typeof $scope.tracequery.endDateOptional === 'undefined') || ($scope.tracequery.endDateOptional === null)) {
            $scope.tracequery.endDate = new Date();
        } else {
            $scope.tracequery.endDate = $scope.tracequery.endDateOptional;
        }

        var query = angular.copy($scope.tracequery);
        delete query['endDateOptional'];
        $scope.doSearch(query);
    };

    $scope.more = function () {
        $scope.tracequery.page = $scope.tracequery.page + 1;
        var query = angular.copy($scope.tracequery);
        delete query['endDateOptional'];
        $scope.doSearch(query);
    };

    $scope.export = function () {
        var url = ApiConstants.baseHref + ApiConstants.backendApiUrl + '/traces/download?JSESSIONID=' + $scope.token;

        var exportParams = [
            {
                param: 'endDate', from: 'endDateOptional', transform: function (elt) {
                    return elt.toJSON();
                }
            },
            {
                param: 'startDate', transform: function (elt) {
                    return elt.toJSON();
                }
            },
            {param: 'transactionId'},
            {param: 'cptId'},
            {param: 'hideProbe'},
            {param: 'contains'},
            {param: 'partner', from: 'partnerId'},
            {param: 'operator', from: 'operatorId'},
            {param: 'calleePartner'},
            {param: 'targetOperator', from: 'targetOperatorId'},
            {param: 'ipSource'},
            {param: 'ipTarget'},
            {param: 'evseId'},
            {param: 'funcflow'},
            {param: 'sessionId'},
            {param: 'userId'}
        ];

        for (var i = 0; i < exportParams.length; i++) {
            var param = exportParams[i].param;
            var from = exportParams[i].from || param;

            if ((typeof $scope.tracequery[from] !== 'undefined') && ($scope.tracequery[from] !== null)) {
                var value = $scope.tracequery[from];

                if (angular.isFunction(exportParams[i].transform)) {
                    value = exportParams[i].transform(value);
                }

                url += '&' + param + '=' + value;
            }
        }

        window.open(url);
    };

    $scope.biglogdl = function (cptId, seqId, funcFlowId, date) {
        if (funcFlowId.startsWith(toIOP) || funcFlowId.startsWith(fromIOP)) {
            funcFlowId = prefixOCPI + funcFlowId;
        }
        var url = ApiConstants.baseHref + ApiConstants.backendApiUrl + '/bigpayload/download?JSESSIONID=' + $scope.token + '&cptId=' + cptId + '&seqId=' + seqId + '&funcFlowId=' + funcFlowId + '&date=' + date;
        window.open(url);
    };

    $scope.formatTime = function (time) {
        console.log(time);
    };

    $scope.isJson = function (str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    };

    $scope.goFindMessage = function (trace, isExpand) {
        if (isExpand && !trace.loaded) {
            $http.get(ApiConstants.backendApiUrl + '/traces?documentId=' + trace.documentId + '&index=' + trace.index).then(
                function onGetSuccess(success) {
                    var data = success.data;
                    trace.description = data.description;
                    trace.soapMessage = data.soapMessage;
                    trace.loaded = true;
                    if (trace.hasXml) {
                        if ($scope.isJson(trace.soapMessage)) {
                            trace.soapMessageFormatted = vkbeautify.json(trace.soapMessage, 2);
                        } else {
                            trace.soapMessageFormatted = vkbeautify.xml(trace.soapMessage, 2);
                        }
                    }
                    //$scope.refreshPrettyXml(trace);
                },
                function onGetError(error) {
                    // called asynchronously if an error occurs
                    // or server returns response with an error status.
                    $modal.open({
                        templateUrl: 'modalError.html',
                        controller: 'ModalErrorInstanceController',
                        resolve: {
                            errorData: function () {
                                return 'An error occurs. Please contact administrator.';
                            }
                        }
                    });
                }
            );
        }
    };
}
DiagnoseController.$inject = ['$scope', '$location', '$state', 'Traces', '$http', 'ApiConstants', 'Identity', '$locale', '$timeout', '$modal'];

module.exports = DiagnoseController;
